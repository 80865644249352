import { ISvgIcon } from "../../utils/interfaces";

export const AlarmsMenu = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.5V10.5H13V12.5H0ZM16.6 11.5L11.6 6.5L16.6 1.5L18 2.9L14.4 6.5L18 10.1L16.6 11.5ZM0 7.5V5.5H10V7.5H0ZM0 2.5V0.5H13V2.5H0Z"
      fill="#E66900"
    />
  </svg>
);
