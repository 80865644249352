import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";

import { ClearSearch } from "../../../../../assets/icons/ClearSearch";
import { ALARMS_TABS } from "../../../../../utils/constants";
import { IAlarmsProps } from "../../../../../utils/interfaces";
import classes from "./Alarms.module.scss";
import { AlarmsTabs } from "./components/AlarmsTabs/AlarmsTabs";
import { AlarmsList } from "./components/AlarmsList/AlarmsList";
import { GET_ALARMS_REQUEST } from "../../../../../redux/actions";

export const Alarms = ({ patientId, onToggleDrawer }: IAlarmsProps) => {
  const [tab, setTab] = useState(ALARMS_TABS.ACTIVE);
    const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_ALARMS_REQUEST,
      payload: { type: tab, patientId }
    });
  }, [tab, dispatch, patientId]);

  return (
    <div className={classes.alarmsContainer}>
      <div className={classes.alarmsHeaderContainer}>
        <h1>Alarms</h1>
        <span onClick={onToggleDrawer}><ClearSearch /></span>
      </div>
      <div className={classes.alarmsContent}>
        <AlarmsTabs tab={tab} handleChangeTab={setTab} />
        <AlarmsList selectedTab={tab} />
      </div>
      <div className={classes.alarmsFooter}>
        <Button
          color="secondary"
          onClick={onToggleDrawer}
          variant="outlined"
        >
          Close
        </Button>
      </div>
    </div>
  );
};
