import { CartesianGrid, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import classes from './PerformanceChart.module.scss';

const groupsStyle = [
  {
    backgroundColor: '#50C4E3',
    borderColor: '#0095CC',
    id: 2,
  },
  {
    backgroundColor: '#7897FF',
    borderColor: '#063EF9',
    id: 3,
  },
  {
    backgroundColor: '#C19CFC',
    borderColor: '#6308F7',
    id: 4,
  },
  {
    backgroundColor: '#DCC80F',
    borderColor: '#A37F00',
    id: 1,
  },
  {
    backgroundColor: '#FFAC66',
    borderColor: '#FF7500',
    id: 5,
  },
];

const CHART_HEIGHT = 450;
const BAR_WIDTH = 7.5;
const Y_GAP_AMOUNT = 12;
const MAX_MINUTES = 120;

const TimePeriodShape = (props) => {
  const { cx, cy, payload } = props;

  const groupId = payload.exerciseGroupId;
  const value = payload.value;

  const groupStyle = groupsStyle.find((groupStyle) => groupStyle.id === groupId);
  const { backgroundColor, borderColor } = groupStyle;

  // move item based on group id
  const adjustedX = cx + BAR_WIDTH * groupId;

  return (
    <rect
      x={adjustedX}
      y={cy - (CHART_HEIGHT / Y_GAP_AMOUNT / MAX_MINUTES) * value - 1}
      width={BAR_WIDTH}
      height={(CHART_HEIGHT / Y_GAP_AMOUNT / MAX_MINUTES) * value}
      fill={backgroundColor}
      stroke={borderColor}
      strokeWidth="0.5"
      rx="1.75"
    />
  );
};

const TotalTimeShape = (props) => {
  const { cx, cy, payload, dailyExercisePlan } = props;
  const { hours, totalTime, type } = payload;

  const planComplete = dailyExercisePlan <= totalTime;

  const backgroundColor = planComplete ? '#D0F9BC' : '#EFF0F6';
  const borderColor = planComplete ? '#339801' : '#A0A3BD';

  const onePeriodPx = CHART_HEIGHT / 8;

  // Calculate the height of the rectangle
  // if it is not complete, the height = dailyExercisePlan
  const rectHeight = planComplete
    ? onePeriodPx * hours + (totalTime % 60)
    : onePeriodPx * (dailyExercisePlan / 60);

  const heightByTime = onePeriodPx * hours + (totalTime % 60);

  // different colors based on % complete
  const over50 = dailyExercisePlan / 2 <= totalTime;
  const over80 = dailyExercisePlan * 0.8 < totalTime;

  // Adjust y to make sure rectangle starts from the bottom of the chart
  const adjustedY = cy - rectHeight - 1;

  // do not display this mock data
  if (type === 'forYaxis') {
    return null;
  }

  // this mock data is used to show user is offline, we have no data
  if (type === 'datesMock') {
    return (
      <rect
        x={cx}
        y={adjustedY}
        width={BAR_WIDTH}
        height={rectHeight}
        fill={backgroundColor}
        stroke={borderColor}
        strokeDasharray={4}
        strokeWidth="0.5"
        rx="1.75"
      />
    );
  }

  if (planComplete) {
    return (
      <rect
        x={cx}
        y={adjustedY}
        width={BAR_WIDTH}
        height={rectHeight}
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth="0.5"
        rx="1.75"
      />
    );
  } else {
    // plan is incomplete so we are shpwing two bars, expected time and completed time
    return (
      <>
        <rect
          x={cx}
          y={adjustedY}
          width={BAR_WIDTH}
          height={rectHeight}
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth="0.5"
          rx="1.75"
        />
        <rect
          x={cx}
          y={cy - heightByTime - 1}
          width={BAR_WIDTH}
          height={heightByTime}
          fill={over80 ? '#D0F9BC' : over50 ? '#FFC899' : '#FF9999'}
          stroke={over80 ? '#339801' : over50 ? '#994600' : '#990000'}
          strokeWidth="0.5"
          rx="1.75"
        />
      </>
    );
  }
};

const DateLabel = ({ x, y, payload }) => (
  <text x={x} y={y} dy={20} dx={30} className={classes.label} textAnchor="middle">
    {payload.value}
  </text>
);

const TimeRangeYLabel = ({ x, y, payload }) => (
  <text
    x={x}
    y={y}
    dy={0}
    dx={-20}
    className={`${classes.label} ${classes.labelY}`}
    textAnchor="middle">
    {/*values should be unique, but we have to display 12 pm two times, so we are replacing 00 am with 12 pm*/}
    {payload.value === '00 am' ? '12 pm' : payload.value}
  </text>
);

const TotalTimeYLabel = ({ x, y, payload }) => (
  <text
    x={x}
    y={y}
    dy={0}
    dx={-10}
    className={`${classes.label} ${classes.labelY}`}
    textAnchor="middle">
    {payload.value}
  </text>
);

const hoursTicks = Array.from({ length: 9 }, (_, i) => i + ' hrs');

function parseDate(str) {
  const [day, month, year] = str.split('/');
  // Adjust the year to the correct century, assuming all dates are 2000s
  const adjustedYear = parseInt(year, 10) + 2000;
  // Note: months are 0-indexed in JavaScript Dates
  return new Date(adjustedYear, month - 1, day);
}

export const PerformanceChart = ({ normalizedData, totalTimePerDateData, dailyExercisePlan }) => {
  const uniqueDates = [...new Set(totalTimePerDateData.map((item) => item.date))].sort(
    (a, b) => parseDate(a) - parseDate(b),
  );

  // custom ticks and domain are needed to display all the label on X-Y chart even if there is no data

  return (
    <ScatterChart width={1100} height={CHART_HEIGHT + 40}>
      <CartesianGrid vertical={false} stroke="#D9DBE9" />
      <XAxis
        dataKey="date"
        type="category"
        allowDuplicatedCategory={false}
        scale="point"
        ticks={uniqueDates}
        tick={<DateLabel />}
        padding={{ right: 60 }}
        tickLine={false}
        axisLine={{ stroke: '#D9DBE9' }}
        domain={uniqueDates}
      />

      <YAxis
        dataKey="timeKey"
        type="category"
        allowDuplicatedCategory={false}
        scale="point"
        tick={<TimeRangeYLabel />}
        tickLine={false}
        axisLine={false}
        orientation="left"
        yAxisId="1"
      />
      <YAxis
        ticks={hoursTicks}
        dataKey="hoursLabel"
        type="category"
        allowDuplicatedCategory={false}
        scale="point"
        tick={<TotalTimeYLabel />}
        tickLine={false}
        axisLine={false}
        position="insideLeft"
        orientation="left"
        yAxisId="2"
      />

      <Scatter yAxisId="1" data={normalizedData} shape={<TimePeriodShape />} />
      <Scatter
        yAxisId="2"
        data={totalTimePerDateData}
        shape={<TotalTimeShape dailyExercisePlan={dailyExercisePlan} />}
      />
    </ScatterChart>
  );
};
