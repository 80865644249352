import { IAlarm, IReduxAction, TABS } from '../../utils/interfaces';
import { updateOneEntity, updateState } from '../../utils/reduxUtils';
import {
  GET_ALARMS_SUCCESS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENT_SUCCESS,
  RESOLVE_ALARMS_SUCCESS,
  SET_PATIENTS_PAGINATION,
  CHANGE_EXERCISES_ACTIVE_STATUS_ERROR,
  CHANGE_EXERCISES_ACTIVE_STATUS_SUCCESS,
  GET_PATIENT_EXERCISES_SUCCESS,
  ASSIGN_EXERCISES_SUCCESS,
  ASSIGN_EXERCISES_HIDE_SUCCESS_MODAL,
  SET_CURRENT_PATIENT,
  ASSIGN_EXERCISES_ERROR,
  GET_EXERCISE_PERFORMANCE_DATA_SUCCESS,
  SET_TABLE_STATE,
  GET_TOTAL_PATIENTS_SUCCESS,
} from '../actions';

const initialState = {
  [TABS.all]: null,
  [TABS.active]: null,
  [TABS.finished]: null,
  current: null,
  alarms: [],
  pagination: {
    page: 1,
    size: 25,
  },
  tab: TABS.active,
  tableYScrollPosition: 0,
  exercisesActiveStatusError: false,
  assignExercisesSuccessShowModal: false,
  currentPatient: null,
  totalActive: 0,
  totalFinished: 0,
};

export const patientsReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case SET_PATIENTS_PAGINATION: {
      return updateOneEntity(state, payload);
    }
    case GET_PATIENTS_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    case GET_TOTAL_PATIENTS_SUCCESS: {
      return updateState(state, payload);
    }
    case GET_PATIENT_SUCCESS: {
      return updateOneEntity(state, payload);
    }
    case GET_ALARMS_SUCCESS: {
      return updateState(state, payload);
    }
    case RESOLVE_ALARMS_SUCCESS: {
      const updatedAlarms = payload.isDelete
        ? state.alarms.filter((alarm: IAlarm) => alarm.id !== payload.alarm.id)
        : state.alarms.map((alarm: IAlarm) =>
            alarm.id === payload.alarm.id ? { ...payload.alarm } : { ...alarm },
          );
      return updateState(state, { alarms: updatedAlarms });
    }
    case CHANGE_EXERCISES_ACTIVE_STATUS_SUCCESS: {
      return {
        ...JSON.parse(JSON.stringify(state)),
        exercisesActiveStatusError: false,
      };
    }
    case CHANGE_EXERCISES_ACTIVE_STATUS_ERROR: {
      return {
        ...JSON.parse(JSON.stringify(state)),
        exercisesActiveStatusError: payload.isError,
      };
    }
    case GET_PATIENT_EXERCISES_SUCCESS: {
      const prevState = JSON.parse(JSON.stringify(state));

      prevState.currentPatient.exercises = payload.data.map((exercise: any) => exercise.id);

      return prevState;
    }
    case ASSIGN_EXERCISES_SUCCESS: {
      const prevState = JSON.parse(JSON.stringify(state));

      prevState.assignExercisesSuccessShowModal = payload.assignExercisesSuccessShowModal;

      return prevState;
    }
    case ASSIGN_EXERCISES_ERROR: {
      const prevState = JSON.parse(JSON.stringify(state));

      prevState.assignExercisesErrorShowModal = payload.isError;

      return prevState;
    }
    case ASSIGN_EXERCISES_HIDE_SUCCESS_MODAL: {
      const prevState = JSON.parse(JSON.stringify(state));

      prevState.assignExercisesSuccessShowModal = false;

      return prevState;
    }
    case SET_CURRENT_PATIENT: {
      const prevState = JSON.parse(JSON.stringify(state));
      prevState.currentPatient = payload.currentPatient;

      if (prevState?.currentPatient?.exercises) {
        prevState.currentPatient.exercises = prevState.currentPatient.exercises.map(
          (exercise: any) => exercise.id,
        );
      }

      return prevState;
    }
    case GET_EXERCISE_PERFORMANCE_DATA_SUCCESS: {
      const prevState = JSON.parse(JSON.stringify(state));

      prevState.currentPatient.exercisePerformanceData = payload.data;

      return prevState;
    }
    case SET_TABLE_STATE: {
      const prevState = JSON.parse(JSON.stringify(state));
      return { ...prevState, ...payload };
    }
    default: {
      return state;
    }
  }
};
