import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import { ToastType } from "../../../utils/interfaces";
import {
    APPLY_TREATMENT_PLAN_ERROR,
    APPLY_TREATMENT_PLAN_REQUEST,
    APPLY_TREATMENT_PLAN_SUCCESS,
    GET_PATIENT_REQUEST,
    SET_TOAST,
} from "../../actions";

function* workerApplyTreatmentTemplate(action: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const { data, navigate } = action.payload;
    yield call(TreatmentPlanTemplatesAPI.applyTreatmentTemplate, data);
    yield put({
      type: APPLY_TREATMENT_PLAN_SUCCESS,
    });
    yield put({
      type: GET_PATIENT_REQUEST,
      payload: { id: data.patientId }
    });
    if (navigate) navigate(`/patients/view/${data.patientId}`);
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: "Treatment Plan was successfully applied",
        type: ToastType.SUCCESS,
      }
    });
  } catch (error: any) {
    yield put({
      type: APPLY_TREATMENT_PLAN_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherApplyTreatmentTemplate() {
  yield takeLatest(APPLY_TREATMENT_PLAN_REQUEST, workerApplyTreatmentTemplate);
}