import { call, takeLatest, put } from 'redux-saga/effects';
import { ToastType } from '../../../utils/interfaces';
import {
  GET_PATIENT_EXERCISES,
  GET_PATIENT_EXERCISES_ERROR,
  GET_PATIENT_EXERCISES_SUCCESS,
  SET_TOAST,
} from '../../actions';
import { PatientsAPI } from '../../../api/patientsAPI';

function* getPatientExercises({
  type,
  payload: { id },
}: {
  type: string;
  payload: {
    id: string;
  };
}): Generator<any> {
  try {
    const data = yield call(PatientsAPI.getPatientExercises, { id });

    const payload = {
      data,
      id,
    };

    yield put({
      type: GET_PATIENT_EXERCISES_SUCCESS,
      payload,
    });
  } catch (error: any) {
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: error.message,
        type: ToastType.ERROR,
      },
    });
    // yield put({
    //   type: GET_PATIENT_EXERCISES_ERROR,
    //   payload: { isError: true },
    // });
  }
}

export function* watcherGetPatientExercises() {
  yield takeLatest(GET_PATIENT_EXERCISES, getPatientExercises);
}
