import { ISvgIcon } from "../../utils/interfaces";

export const CheckMark = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.93337 14.6L15.9834 7.55L14.5834 6.15L8.93337 11.8L6.08337 8.95L4.68337 10.35L8.93337 14.6ZM10.3334 20C8.95004 20 7.65004 19.7375 6.43337 19.2125C5.21671 18.6875 4.15837 17.975 3.25837 17.075C2.35837 16.175 1.64587 15.1167 1.12087 13.9C0.595874 12.6833 0.333374 11.3833 0.333374 10C0.333374 8.61667 0.595874 7.31667 1.12087 6.1C1.64587 4.88333 2.35837 3.825 3.25837 2.925C4.15837 2.025 5.21671 1.3125 6.43337 0.7875C7.65004 0.2625 8.95004 0 10.3334 0C11.7167 0 13.0167 0.2625 14.2334 0.7875C15.45 1.3125 16.5084 2.025 17.4084 2.925C18.3084 3.825 19.0209 4.88333 19.5459 6.1C20.0709 7.31667 20.3334 8.61667 20.3334 10C20.3334 11.3833 20.0709 12.6833 19.5459 13.9C19.0209 15.1167 18.3084 16.175 17.4084 17.075C16.5084 17.975 15.45 18.6875 14.2334 19.2125C13.0167 19.7375 11.7167 20 10.3334 20ZM10.3334 18C12.5667 18 14.4584 17.225 16.0084 15.675C17.5584 14.125 18.3334 12.2333 18.3334 10C18.3334 7.76667 17.5584 5.875 16.0084 4.325C14.4584 2.775 12.5667 2 10.3334 2C8.10004 2 6.20837 2.775 4.65837 4.325C3.10837 5.875 2.33337 7.76667 2.33337 10C2.33337 12.2333 3.10837 14.125 4.65837 15.675C6.20837 17.225 8.10004 18 10.3334 18Z"
      fill="#A0A3BD"
    />
  </svg>
);
