import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import classes from "./TreatmentPlanTable.module.scss";
import { RightChevron } from "../../../../../assets/icons/RightChevron";
import { Delete } from "../../../../../assets/icons/Delete";
import {
  ITreatmentPlan,
  ITreatmentPlanData
} from "../../../../../utils/interfaces";
import { mmToCm } from "../../../../../utils/commonUtils";
import { bonesMap } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

export const TreatmentPlanTable = ({ data, deleteTemplate }: ITreatmentPlanData) => {
  const navigate = useNavigate();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left">Plan Name</TableCell>
            <TableCell align="left">Nail Type</TableCell>
            <TableCell align="left">Total Distraction</TableCell>
            <TableCell align="left">Duration</TableCell>
            <TableCell align="left">Total Amount of Clicks</TableCell>
            <TableCell align="left">Initial Gap</TableCell>
            <TableCell align="left">Bone type</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {data &&
            data.map(
              ({
                id,
                name,
                operationType,
                nailTypeMm,
                totalClicks,
                totalDistractionMm,
                duration,
                initialGapMm
              }: ITreatmentPlan) => {
                return (
                  <TableRow className={classes.tableRow} key={id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{mmToCm(nailTypeMm)} cm</TableCell>
                    <TableCell align="left">
                      {mmToCm(totalDistractionMm)} cm
                    </TableCell>
                    <TableCell align="left">{duration}</TableCell>
                    <TableCell align="left">{totalClicks}</TableCell>
                    <TableCell align="left">
                      {mmToCm(initialGapMm)} cm
                    </TableCell>
                    <TableCell align="left">
                      {bonesMap[operationType]}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton className={classes.deleteAction} onClick={() => deleteTemplate(id)}>
                        <Delete />
                      </IconButton>
                      <IconButton
                        onClick={() => navigate(`/treatment-plans/view/${id}`)}
                      >
                        <RightChevron />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
