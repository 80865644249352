import { IReduxAction } from "../../utils/interfaces";
import { updateOneEntity, updateState } from "../../utils/reduxUtils";
import {
  GET_TREATMENT_TEMPLATES_SUCCESS,
  GET_TREATMENT_TEMPLATE_SUCCESS,
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS,
  RECALCULATE_TREATMENT_TEMPLATE_SUCCESS
} from "../actions";

const initialState = {
  treatmentPlans: null,
  current: null,
  draft: null,
  patientPlan: null,
};

export const treatmentPlansReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case GET_TREATMENT_TEMPLATES_SUCCESS: {
      return updateState(state, payload);
    }
    case GET_TREATMENT_TEMPLATE_SUCCESS: {
      return updateState(state, payload);
    }
    case RECALCULATE_TREATMENT_TEMPLATE_SUCCESS: {
      return updateState(state, payload)
    }
    case RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS: {
      return updateOneEntity(state, payload)
    }
    default: {
      return state;
    }
  }
};
