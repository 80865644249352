import { IconButton } from "@mui/material";
import { IActionButton } from "../../../../../../utils/interfaces";
import classes from "./ActionButton.module.scss";

export const ActionButton = ({
  name,
  description,
  icon,
  action,
  buttonClassName,
  disabled,
}: IActionButton) => {
  return (
    <div className={classes.actionContainer}>
      <div className={classes.info}>
        <p>{name}</p>
        <p>{description}</p>
      </div>
      <IconButton
        disabled={!!disabled}
        className={`${classes.action} ${buttonClassName}`}
        onClick={() => action()}
      >
        {icon}
      </IconButton>
    </div>
  );
};
