import { call, takeLatest, CallEffect, put } from 'redux-saga/effects';
import { ToastType } from '../../../utils/interfaces';
import {
  CHANGE_EXERCISES_ACTIVE_STATUS,
  CHANGE_EXERCISES_ACTIVE_STATUS_ERROR,
  CHANGE_EXERCISES_ACTIVE_STATUS_SUCCESS,
  SET_TOAST,
} from '../../actions';
import { PatientsAPI } from '../../../api/patientsAPI';

function* changeExercisesActiveStatus({
  type,
  payload: { id, isActive },
}: {
  type: string;
  payload: {
    id: string;
    isActive: boolean;
  };
}): Generator<any> {
  try {
    const data = yield call(PatientsAPI.changeExercisesActiveStatus, { id, isActive });

    yield put({
      type: CHANGE_EXERCISES_ACTIVE_STATUS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error: any) {
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: error.message,
        type: ToastType.ERROR,
      },
    });
    yield put({
      type: CHANGE_EXERCISES_ACTIVE_STATUS_ERROR,
      payload: { isError: true },
    });
  }
}

export function* watcherChangeExercisesActiveStatus() {
  yield takeLatest(CHANGE_EXERCISES_ACTIVE_STATUS, changeExercisesActiveStatus);
}
