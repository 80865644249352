import { ISvgIcon } from "../../utils/interfaces";

export const InactiveAlarm = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.428467 19L11.4285 0L22.4285 19H0.428467ZM3.87847 17H18.9785L11.4285 4L3.87847 17ZM11.4285 16C11.7118 16 11.9495 15.904 12.1415 15.712C12.3328 15.5207 12.4285 15.2833 12.4285 15C12.4285 14.7167 12.3328 14.4793 12.1415 14.288C11.9495 14.096 11.7118 14 11.4285 14C11.1451 14 10.9078 14.096 10.7165 14.288C10.5245 14.4793 10.4285 14.7167 10.4285 15C10.4285 15.2833 10.5245 15.5207 10.7165 15.712C10.9078 15.904 11.1451 16 11.4285 16ZM10.4285 13H12.4285V8H10.4285V13Z"
      fill="#A0A3BD"
    />
  </svg>
);
