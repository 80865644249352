import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import classes from "./TreatmentPlanControls.module.scss";
import { Add } from "../../../../../assets/icons/Add";
import { IControls } from "../../../../../utils/interfaces";
import { SearchField } from "../../../../common/SearchField/SearchField";

export const TreatmentPlanControls = ({ updateSearchParams }: IControls) => {
  const navigate = useNavigate();
  return (
    <div className={classes.controlsContainer}>
      <h1>Treatment plan templates</h1>
      <div className={classes.controls}>
        <SearchField
          className={classes.searchInput}
          updateSearchParams={updateSearchParams}
          placeholder="Quick search for anything"
        />
        <Divider flexItem orientation="vertical" />
        <Button
          className={classes.createPlan}
          onClick={() => navigate("/treatment-plans/create")}
          startIcon={<Add />}
          variant="contained"
        >
          Create
        </Button>
      </div>
    </div>
  );
};
