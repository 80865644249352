import { ISvgIcon } from "../../utils/interfaces";

export const ExternalLink = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 10.5H5C3.61667 10.5 2.43767 10.0123 1.463 9.037C0.487667 8.06233 0 6.88333 0 5.5C0 4.11667 0.487667 2.93733 1.463 1.962C2.43767 0.987333 3.61667 0.5 5 0.5H9V2.5H5C4.16667 2.5 3.45833 2.79167 2.875 3.375C2.29167 3.95833 2 4.66667 2 5.5C2 6.33333 2.29167 7.04167 2.875 7.625C3.45833 8.20833 4.16667 8.5 5 8.5H9V10.5ZM6 6.5V4.5H14V6.5H6ZM11 10.5V8.5H15C15.8333 8.5 16.5417 8.20833 17.125 7.625C17.7083 7.04167 18 6.33333 18 5.5C18 4.66667 17.7083 3.95833 17.125 3.375C16.5417 2.79167 15.8333 2.5 15 2.5H11V0.5H15C16.3833 0.5 17.5627 0.987333 18.538 1.962C19.5127 2.93733 20 4.11667 20 5.5C20 6.88333 19.5127 8.06233 18.538 9.037C17.5627 10.0123 16.3833 10.5 15 10.5H11Z"
      fill="#0532C7"
    />
  </svg>
);
