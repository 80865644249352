import classes from "./FullPageContainer.module.scss";
import { NavigationBar } from "../NavigationBar/NavigationBar";
import { IFullPageContainer } from "../../../utils/interfaces";

export const FullPageContainer = ({ children, pageClassName }: IFullPageContainer) => {
  return (
    <div className={classes.fullPageContainer}>
      <NavigationBar />
      <div className={pageClassName ? pageClassName : classes.pageContainer}>{children}</div>
    </div>
  );
};
