import { format } from "date-fns";
import { getServiceURL } from "../utils/apiUtils";
import { apiInstance } from "../utils/axiosConfig";

export class ClickingDataAPI {
  static get modifyClickLogServiceName() {
    return "MODIFY_CLICK_LOG";
  }

  static async getClickingData(patientId: string, start: string, end: string) {
    const url = `/patients/${patientId}/clicks/data`;
    const params = {
      startDate: format(new Date(start), "yyyy-MM-dd"),
      endDate: format(new Date(end), "yyyy-MM-dd")
    };
    const response = await apiInstance().get(url, { params });
    return response?.data;
  }

  static async modifyClickingData(
    patientId: string,
    sides: string[],
    date: string,
    clicksToAdd: number,
    clicksToDelete: number,
    recalculateTreatmentPlan: boolean
  ) {
    const url = `${getServiceURL(
      ClickingDataAPI.modifyClickLogServiceName
    )}?patientId=${patientId}&sides=${sides}&date=${date}&clicksToAdd=${clicksToAdd}&clicksToDelete=${clicksToDelete}&recalculateTreatmentPlan=${recalculateTreatmentPlan}`;
    const response = await apiInstance().put(url);
    return response?.data;
  }
}
