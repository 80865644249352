import classes from './AssignExercises.module.scss';
import styles from '../../../../common/commonStyles.module.scss';
import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ExerciseGroup,
  ExerciseGroupIdByName,
  ExerciseItem as TExerciseItem,
  ExerciseItem,
} from '../../../../../types/exercises';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignExercisesSuccessShowModal,
  exercisesActiveStatusError,
  getCurrentPatient,
} from '../../../../../redux/selectors/patientsSelector';
import {
  ASSIGN_EXERCISES_HIDE_SUCCESS_MODAL,
  CHANGE_EXERCISES_ACTIVE_STATUS,
  CHANGE_EXERCISES_ACTIVE_STATUS_ERROR,
  GET_ALL_EXERCISES,
  GET_PATIENT_EXERCISES,
} from '../../../../../redux/actions';
import { Modal } from '../../../../common/Modal/Modal';
import { Success } from '../../../../../assets/icons/Success';
import { ExerciseDuration } from './components/ExerciseDuration/ExerciseDuration';
import { Group } from '../Group/Group';
import { allExercisesSelector } from '../../../../../redux/selectors/exercisesSelector';
import { ModelModal } from '../../../../common/ModelModal/ModelModal';

const groups: Array<ExerciseGroup & { status: string }> = [
  {
    name: 'Hip Flexors/Thighs Front',
    status: 'Assigned to client',
    exercises: [],
    backgroundColor: 'rgba(242, 252, 255, 1)',
    borderColor: 'rgba(229, 248, 255, 1)',
    id: ExerciseGroupIdByName.HIP_FLEXORS_AND_THIGHS_FRONT,
  },
  {
    name: 'Lateral Thighs/Buttocks',
    status: 'Assigned to client',
    exercises: [],
    backgroundColor: 'rgba(242, 245, 255, 1)',
    borderColor: 'rgba(229, 235, 254, 1)',
    id: ExerciseGroupIdByName.OUTER_THIGHS_AND_BUTTOCKS,
  },
  {
    name: 'Back of Thighs',
    status: 'Assigned to client',
    exercises: [],
    backgroundColor: 'rgba(238, 229, 254, 1)',
    borderColor: 'rgba(247, 242, 255, 1)',
    id: ExerciseGroupIdByName.BACK_OF_THIGHS,
  },
  {
    name: 'Pelvic movements',
    status: 'Assigned to client',
    exercises: [],
    backgroundColor: 'rgba(255, 255, 242, 1)',
    borderColor: 'rgba(243, 243, 178, 1)',
    id: ExerciseGroupIdByName.PELVIC_MOVEMENTS,
  },
  {
    name: 'Additionals',
    status: 'Assigned to client',
    exercises: [],
    backgroundColor: 'rgba(255, 248, 242, 1)',
    borderColor: 'rgba(255, 241, 229, 1)',
    id: ExerciseGroupIdByName.ADDITIONALS,
  },
].sort((a, b) => a.id - b.id);

type Props = {
  onManageGroupPress: (groupProps: ExerciseGroup) => void;
};

export const AssignExercises = ({ onManageGroupPress }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [currentModelULR, setCurrentModelULR] = useState({
    modelUrl: '',
    imageUrl: '',
  });

  const dispatch = useDispatch();

  const activeStatusError = useSelector(exercisesActiveStatusError);
  const showSuccessModal = useSelector(assignExercisesSuccessShowModal);
  const currentPatient = useSelector(getCurrentPatient);

  const allExercises = useSelector(allExercisesSelector);
  const patientExercises = currentPatient?.exercises.map((id: number) =>
    allExercises.find((ex) => ex.id === id),
  );

  const onExerciseClick = (id: TExerciseItem['id']) => {
    const exercise = allExercises.find((exercise) => exercise.id === id);

    if (!exercise) {
      return;
    }

    const modelUrl = currentPatient.gender === 'M' ? exercise.maleLink : exercise.femaleLink;
    const imageUrl =
      currentPatient.gender === 'M' ? exercise.malePreviewLink : exercise.femalePreviewLink;

    setCurrentModelULR({ modelUrl, imageUrl });
    setIsModelModalOpen(true);
  };

  useEffect(() => {
    currentPatient?.id &&
      dispatch({
        type: GET_PATIENT_EXERCISES,
        payload: {
          id: currentPatient?.id,
        },
      });

    dispatch({ type: GET_ALL_EXERCISES });
  }, [currentPatient?.id]);

  useEffect(() => {
    const isExerciseActive = currentPatient?.isExerciseActive;
    setIsActive(!!isExerciseActive);
  }, [currentPatient?.isExerciseActive]);

  useEffect(() => {
    if (activeStatusError) {
      setIsActive(!isActive);
    }
  }, [activeStatusError]);

  const handleSuccessModalCloseClick = () => {
    dispatch({
      type: ASSIGN_EXERCISES_HIDE_SUCCESS_MODAL,
    });
  };

  const handleToggleIsExercisesActive = () => {
    dispatch({
      type: CHANGE_EXERCISES_ACTIVE_STATUS_ERROR,
      payload: { isError: false },
    });
    dispatch({
      type: CHANGE_EXERCISES_ACTIVE_STATUS,
      payload: { id: currentPatient.id, isActive: !isActive },
    });

    setIsActive(!isActive);
  };

  if (!currentPatient) {
    return null;
  }

  return (
    <div className={`${classes.container} ${!isActive ? classes.disabled : ''}`}>
      <div className={classes.controls}>
        <div className={classes.controlsLeftPart}>
          <h2>Assign Exercises</h2>
          <div className={classes.exerciseActivation}>
            <Switch
              onChange={handleToggleIsExercisesActive}
              value={isActive}
              checked={isActive}
              disableRipple
            />
            <span className={styles.text}>Exercise activation</span>
          </div>
        </div>
        <div className={classes.controlsRightPart}>
          <div className={`${styles.text} ${classes.exerciseActivationLabel}`}>
            Duration per day
          </div>
          {currentPatient?.dailyExercisePlan != null && (
            <ExerciseDuration
              disabled={!isActive}
              initialDuration={currentPatient?.dailyExercisePlan}
            />
          )}
        </div>
      </div>
      <div className={classes.groups}>
        {groups.map((group, index) => (
          <Group
            key={group.id}
            onExerciseClick={onExerciseClick}
            gender={currentPatient.gender}
            index={index}
            onManageGroupPress={onManageGroupPress}
            isActive={isActive && !!allExercises.find(exercise => exercise.exerciseGroupDto.id === group.id)}
            group={group}
            exercises={patientExercises.filter(
              (exercise: ExerciseItem) => exercise?.exerciseGroupDto?.id === group.id,
            )}
          />
        ))}
      </div>
      <ModelModal
        isOpen={isModelModalOpen}
        onClose={() => setIsModelModalOpen(false)}
        modelURL={currentModelULR.modelUrl}
        imageURL={currentModelULR.imageUrl}
      />
      <Modal
        open={showSuccessModal}
        title={'Changes have been successfully saved'}
        message={'Exercises have been assigned.'}
        handleConfirm={handleSuccessModalCloseClick}
        handleCancel={handleSuccessModalCloseClick}
        showCancelButton={false}
        icon={Success}
        confirmButtonTitle="Close"
      />
    </div>
  );
};
