import { getServiceURL } from "../utils/apiUtils";
import { apiInstance } from "../utils/axiosConfig";

export class TreatmentPlanTemplatesAPI {
  static get treatmentTemplatesServiceName() {
    return "TREATMENT_TEMPLATES";
  }

  static get draftTreatmentTemplateServiceName() {
    return "DRAFT_TREATMENT_TEMPLATE";
  }

  static get applyTreatmentTemplateServiceName() {
    return "APPLY_TREATMENT_PLAN";
  }

  static async getTreatmentTemplates(data: any) {
    const { searchString, nailTypeMm, operationType, initialGapMm } = data;
    const url = getServiceURL(
      TreatmentPlanTemplatesAPI.treatmentTemplatesServiceName
    );
    const params = {
      ...searchString ? {searchString} : {},
      ...initialGapMm ? { initialGapMm } : {},
      ...operationType ? { operationType } : {},
      ...nailTypeMm ? { nailTypeMm } :  {}
    };
    const response = await apiInstance().get(url, { params });
    return response?.data;
  }

  static async getSingleTreatmentTemplate(id: string) {
    const url = `${getServiceURL(
      TreatmentPlanTemplatesAPI.treatmentTemplatesServiceName
    )}/${id}`;
    const response = await apiInstance().get(url);
    return response?.data;
  }

  static async createUpdateTreatmentTemplate(data: any) {
    const url = getServiceURL(
      TreatmentPlanTemplatesAPI.treatmentTemplatesServiceName
    );
    const response = await apiInstance().put(url, data);
    return response?.data;
  }

  static async deleteTreatmentTemplate(id: string) {
    const url = `${getServiceURL(
      TreatmentPlanTemplatesAPI.treatmentTemplatesServiceName
    )}/${id}`;
    await apiInstance().delete(url);
  }

  static async recalculateTreatmentTemplate(data: any) {
    const url = getServiceURL(
      TreatmentPlanTemplatesAPI.draftTreatmentTemplateServiceName
    );
    const response = await apiInstance().put(url, data);
    return response?.data;
  }

  static async applyTreatmentTemplate(data: any) {
    if (!data) return;
    const { startDate, templateId, patientId } = data;
    const url = `${getServiceURL(
      TreatmentPlanTemplatesAPI.applyTreatmentTemplateServiceName
    )}?startDate=${startDate}&templateId=${templateId}&patientId=${patientId}`;
    await apiInstance().post(url);
  }
}
