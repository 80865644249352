import { ALARMS_TABS, SORT, SORT_TYPE } from './constants';
import React from 'react';

export enum SORT_SVG {
  ASC = 'ASC',
  DESC = 'DESC',
  DEFAULT = 'DEFAULT',
}

export interface IReduxAction {
  type: string;
  payload: any;
}

export interface ISvgIcon {
  className?: string;
  onClick?: Function;
}

export interface ISortSvg {
  type?: SORT_SVG;
}

export interface IMenuItem {
  id: string;
  name: string;
  icon: JSX.Element;
  path: string;
  shouldResetPatientsPagination: boolean;
}

export interface INoDataPage {
  className?: string;
  message: string;
  description: string;
}

export interface IFullPageContainer {
  children: JSX.Element;
  pageClassName?: string;
}

export interface ITreatmentPlan {
  id: number;
  name: string;
  operationType: 'TIBIAS' | 'FEMURS' | 'RIGHT_TIBIA' | 'RIGHT_FEMUR' | 'LEFT_TIBIA' | 'LEFT_FEMUR';
  nailTypeMm: number;
  totalClicks: number;
  totalDistractionMm: number;
  duration: number;
  initialGapMm: number;
}

export interface ITreatmentPlanData {
  data: ITreatmentPlan[];
  deleteTemplate: Function;
}

export interface IControls {
  updateSearchParams: Function;
}

export interface IPatientsData {
  id: string;
  activeNotificationCount: number;
  firstName: string;
  lastName: string;
  notifications: any;
  bone: 'TIBIAS' | 'FEMURS' | 'RIGHT_TIBIA' | 'RIGHT_FEMUR' | 'LEFT_TIBIA' | 'LEFT_FEMUR';
  currentTreatmentDay: number;
  externalId: string;
  leftClicksToday: number;
  leftClicksYesterday: number;
  leftPainLevel: number;
  plannedLeftClicksToday: number;
  plannedLeftClicksYesterday: number;
  plannedRightClicksToday: number;
  plannedRightClicksYesterday: number;
  rightClicksToday: number;
  rightClicksYesterday: number;
  rightPainLevel: number;
  side: string;
  status: string;
  totalDistraction: number;
  treatmentDuration: number;
}

export interface IPatientsTable {
  data: IPatientsData[];
  handleChangeTab: Function;
  handlePagination: Function;
  tab: TABS;
  totalPages: number;
  currentPage: number;
  handleSorting: (value: SORT) => void;
  sort: { value: SORT; type: SORT_TYPE };
  totalActive: number;
  totalFinished: number;
}

export enum TABS {
  all = 'ALL',
  active = 'ACTIVE',
  finished = 'FINISHED',
}

export interface IPagination {
  count: number;
  currentPage: number;
  showNewPage: Function;
}

export interface ISearchField {
  className: string;
  updateSearchParams: Function;
  placeholder: string;
}

export interface IOperationType {
  bone?: string;
  side?: string;
  operationType?: string;
}

export interface ICreateEditPatientControls {
  navigateBack: Function;
  handleSave: Function;
  handleCancel: Function;
  submitDisabled: boolean;
}

export interface IViewPatientControls {
  deletePatient: Function;
  editPatient: Function;
  externalLink?: string;
  navigateBack: Function;
  patientStatus: string;
  sendPassword: Function;
  showAlarms: Function;
  patient?: IViewPatientInfo & {
    treatmentData: {
      initialGapMm: number;
      initialHeightMm: number;
      leftFemurLengthMm: number;
      leftTibiaLengthMm: number;
      nailTypeMm: number;
      operationType: string;
      rightFemurLengthMm: number;
      rightTibiaLengthMm: number;
      targetDistractionMm: number;
      wingSpanMm: number;
    };
  } & { treatmentPlan: any };
  activeNotificationCount: number;
}

export interface IDialog {
  deleteConfirmation?: boolean;
  open: boolean;
  title: string;
  message: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  icon?: () => JSX.Element;
  confirmButtonTitle?: string;
  showCancelButton?: boolean;
}

export interface ISelectField {
  id: string;
  label: string;
  options?: any;
  required?: boolean;
  selectRootClassName?: string;
  selectInputClassName?: string;
  labelClassName?: string;
  formClassName?: string;
  formik: any;
  type: string;
  helperClassName?: string;
  disabled?: boolean;
}

export interface ISelectOption {
  id?: string;
  label?: string;
  name?: string;
}

export interface ITextInput {
  id: string;
  label: string;
  required?: boolean;
  maxLength?: number;
  inputClassName?: string;
  helperClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  formClassName?: string;
  formik: any;
  type: string;
  disabled?: boolean;
}

export interface INumericInput {
  id: string;
  disabled?: boolean;
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
  handleChange: Function;
  helperClassName?: string;
  placeholder?: string;
  formClassName?: string;
  value: number | null | string;
  maxLength?: number;
  autoFocus?: boolean;
}

export interface IDateField {
  id: string;
  error: string;
  label: string;
  placeholder: string;
  required?: boolean;
  inputClassName?: string;
  helperClassName?: string;
  labelClassName?: string;
  formClassName?: string;
  formik: any;
  setError: Function;
}

export interface IActionButton {
  name: string;
  description: string;
  icon: JSX.Element;
  action: Function;
  buttonClassName: string;
  disabled?: boolean;
}

export interface IProgress {
  progress: number;
  overall: number;
}

export interface IStatus {
  status: string;
  className?: string;
}

export interface IAlarms {
  number: number;
  navigateToAlarms: Function;
}

export interface ITextData {
  custom?: boolean;
  name: string;
  value: any;
}

export interface ITemplateControls {
  deleteTemplate: Function;
  editTemplate: Function;
  navigateBack: Function;
}

export interface ITreatmentPlanPeriod {
  id: number;
  distractionMm: number | null;
  clicksPerDay: number | null;
  duration: number | null;
  totalClicks: number | null;
  sortOrder: number;
  endDate: string;
  side: SIDE;
  startDate: string;
  dayOfPeriod?: null | string;
}

export enum SIDE {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface ICreateEditView {
  mode: 'CREATE' | 'EDIT';
}

export interface IApplyTreatmentPlan {
  selectedPlan: any;
  setSelectedPlan: Function;
  startDate: Date;
  setStartDate: Function;
  operationType: string;
  nailTypeMm: number | null;
  initialGapMm: number | null;
  isViewMode?: boolean;
  applyTreatmentPlan?: Function;
}

export interface IViewPatientInfo {
  id: string;
  country: string;
  currentHeightMm: number;
  dateOfBirth: string;
  distractionMm: number;
  email: string;
  finishedClicks: number;
  firstName: string;
  gender: 'F' | 'M';
  lastName: string;
  phone: string;
  preferredName: string;
  surgeryDate: string;
  plannedClicks: number;
  operationType: string;
  initialGapMm: number;
  initialHeightMm: number;
  nailTypeMm: number;
  leftFemurLengthMm: number;
  rightFemurLengthMm: number;
  leftTibiaLengthMm: number;
  rightTibiaLengthMm: number;
  targetDistractionMm: number;
  wingSpanMm: number;
  status: string;
  activeNotificationCount: number;
}

export interface IAlarmsProps {
  patientId: string;
  onToggleDrawer: () => void;
}

export interface IAlarmsTabsProps {
  handleChangeTab: (newValue: ALARMS_TABS) => void;
  tab: ALARMS_TABS;
}

export interface IAlarmsListProps {
  selectedTab: ALARMS_TABS;
}

export interface IAlarmProps {
  alarm: IAlarm;
  onResolve: (id: number) => void;
}

export interface IAlarm {
  id: number;
  message: string;
  dateTime: string;
  isResolved: boolean;
}

export enum ToastType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum PatientDataUniqueType {
  Username = 'uc_appuser_name',
  ExternalID = 'uc_patient_externalid',
  Email = 'uc_sensitivedata_email',
  Phone = 'uc_sensitivedata_phone',
}

export interface IChangeTreatmentPlanPopup {
  anchorEl: null | HTMLElement;
  data: any;
  handleClose: Function;
  patientId: string;
  side: string;
  bone: 'TIBIA' | 'FEMUR';
  chartDates: { start: string; end: string };
}

export enum STORAGE_KEYS {
  sort = 'sort',
}
