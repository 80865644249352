import { Button, IconButton } from "@mui/material";
import { LeftChevron } from "../../../../../assets/icons/LeftChevron";
import { ICreateEditPatientControls } from "../../../../../utils/interfaces";
import classes from "./CreateEditPatientControls.module.scss";

export const CreateEditPatientControls = ({
  navigateBack,
  handleSave,
  handleCancel,
  submitDisabled
}: ICreateEditPatientControls) => {
  return (
    <div className={classes.controlsContainer}>
      <div className={classes.navigationControls}>
        <IconButton className={classes.back} onClick={() => navigateBack()}>
          <LeftChevron />
        </IconButton>
        <h2>All Patients</h2>
      </div>

      <div className={classes.controls}>
        <Button
          className={classes.control}
          color="secondary"
          onClick={() => handleCancel()}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={classes.control}
          disabled={submitDisabled}
          onClick={() => handleSave()}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </div>
  );
};
