import classes from './Modal.module.scss';
import { IDialog } from '../../../utils/interfaces';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from '../../../assets/icons/Close';

export const Modal = ({
  deleteConfirmation,
  handleCancel,
  handleConfirm,
  message,
  open,
  title,
  icon: Icon,
  confirmButtonTitle,
  showCancelButton = true,
}: IDialog) => {
  return (
    <Dialog classes={{ paper: classes.modalContainer }} open={open} onClose={handleCancel}>
      <DialogTitle className={classes.modalHeader}>
        <div className={classes.modalTitle}>
          {Icon && <Icon />}
          {title}
        </div>
        <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        <p>{message}</p>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {showCancelButton && (
          <Button
            className={classes.action}
            color="secondary"
            onClick={handleCancel}
            variant="outlined">
            Cancel
          </Button>
        )}
        {handleConfirm && (
          <Button
            className={deleteConfirmation ? classes.deleteAction : classes.action}
            color={deleteConfirmation ? 'error' : 'primary'}
            onClick={handleConfirm}
            variant="contained">
            {confirmButtonTitle || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
