import { Patients } from "../assets/icons/Patients";
import { TreatmentPlans } from "../assets/icons/TreatmentPlans";

export const menuList = [
  {
    id: "patients",
    path: "/patients",
    name: "Patients",
    icon: <Patients />,
    shouldResetPatientsPagination: true,
  },
  {
    id: "treatment-plans",
    path: "/treatment-plans",
    name: "Treatment Plan Templates",
    icon: <TreatmentPlans />,
    shouldResetPatientsPagination: false,
  }
]