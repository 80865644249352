import { call, put, takeLatest } from "redux-saga/effects";
import { PatientsAPI } from "../../api/patientsAPI";
import {
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS
} from "../actions";

function* workerGetCountries(): Generator<any> {
  try {
    const data: any = yield call(PatientsAPI.getCountries);
    yield put({
      type: GET_COUNTRIES_SUCCESS,
      payload: { countries: data }
    });
  } catch (error: any) {
    yield put({
      type: GET_COUNTRIES_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherGetCountries() {
  yield takeLatest(GET_COUNTRIES_REQUEST, workerGetCountries);
}
