import { mmToCm } from "../../../utils/commonUtils";
import { ITextData } from "../../../utils/interfaces";
import classes from "./TextData.module.scss";

export const TextData = ({ custom, name, value }: ITextData) => {
  const formattedValue = (val: any) => {
    let newVal;
    if (val === null || typeof val === 'undefined') {
      newVal = "-";
    } else if (typeof val === "string") {
      newVal = val;
    } else if (typeof val === "number") {
      newVal = `${mmToCm(val)} cm`;
    }

    return newVal;
  };

  return (
    <div className={classes.infoBlock}>
      <p>{name}</p>
      {custom ? value : <p>{formattedValue(value)}</p>}
    </div>
  );
};
