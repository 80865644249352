import classes from "./NoDataPage.module.scss";
import { NoResults } from "../../../assets/icons/NoResults";
import { INoDataPage } from "../../../utils/interfaces";

export const NoDataPage = ({ className, message, description }: INoDataPage) => {
  return <div className={`${classes.noDataMessageContainer} ${className}`}>
    <NoResults />
    <p className={classes.message}>{message}</p>
    <p className={classes.description}>{description}</p>
  </div>
}