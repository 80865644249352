import { call, put, takeLatest } from 'redux-saga/effects';
import { ClickingDataAPI } from '../../../api/clickingDataAPI';
import {
  GET_CLICKING_DATA_ERROR,
  GET_CLICKING_DATA_REQUEST,
  GET_CLICKING_DATA_SUCCESS,
} from '../../actions';

function* workerGetClickingData(action: {
  type: string;
  payload: { patientId: string; dates: any };
}): Generator<any> {
  try {
    const {
      patientId,
      dates: { start, end },
    } = action.payload;
    const data: any = yield call(ClickingDataAPI.getClickingData, patientId, start, end);
    yield put({
      type: GET_CLICKING_DATA_SUCCESS,
      payload: { clickingData: data },
    });
  } catch (error: any) {
    yield put({
      type: GET_CLICKING_DATA_ERROR,
      payload: { code: error.response.status, message: error.message },
    });
  }
}

export function* watcherGetClickingData() {
  yield takeLatest(GET_CLICKING_DATA_REQUEST, workerGetClickingData);
}
