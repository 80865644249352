import { Tab, Tabs } from "@mui/material";

import { ALARMS_TABS, alarmsTabs } from "../../../../../../../utils/constants";
import { IAlarmsTabsProps } from "../../../../../../../utils/interfaces";
import classes from "./AlarmsTabs.module.scss";
import { a11yProps } from "../../../../../../../utils/commonUtils";

export const AlarmsTabs = ({ handleChangeTab, tab }: IAlarmsTabsProps) => {
  const onClickTab = (_: React.SyntheticEvent, newValue: ALARMS_TABS) =>
    handleChangeTab(newValue)

  return (
    <Tabs
      aria-label="Alarms tabs"
      classes={{
        root: classes.tabs,
        flexContainer: classes.flexContainer,
        indicator: classes.indicator,
      }}
      onChange={onClickTab}
      value={tab}
    >
      {alarmsTabs.map(({ id, label }, index) => (
        <Tab
          classes={{ root: classes.tab }}
          key={id}
          label={label}
          {...a11yProps(index)}
          value={id}
        />
      ))}
    </Tabs>
  );
};
