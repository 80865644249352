import { ISvgIcon } from "../../utils/interfaces";

export const LeftChevron = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.6 0L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6L6.6 0Z" fill="#158AA4" />
  </svg>
);
