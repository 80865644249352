import { ISvgIcon } from "../../utils/interfaces";

export const CheckboxActive = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="16" height="16" rx="4" fill="#158AA4" />
    <path
      d="M4.9165 9.58325L7.24984 11.9166L13.0832 6.08325"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="4"
      stroke="#158AA4"
      strokeWidth="0.5"
    />
  </svg>
);
