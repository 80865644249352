import { IReduxAction, ToastType } from "../../utils/interfaces";
import { updateState } from "../../utils/reduxUtils";
import { SET_TOAST } from "../actions";

const initialState = {
  type: ToastType.ERROR,
  open: false,
  message: "",
  isCopy: false,
  copyText: '',
};

export const toastReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case SET_TOAST: {
      return updateState(state, payload);
    }
    default: {
      return state;
    }
  }
};
