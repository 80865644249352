import { format, secondsToMinutes } from "date-fns";
import { cmToMm, mmToCm } from "./commonUtils";
import { ITreatmentPlanPeriod } from "./interfaces";

export const formPatientObjectForCreation = (values: any) => {
  const {
    id,
    externalId,
    login,
    firstName,
    lastName,
    email,
    phone,
    gender,
    surgeryDate,
    country,
    preferredName,
    dateOfBirth,
    operationType,
    initialGapMm,
    nailTypeMm,
    initialHeightMm,
    targetDistractionMm,
    leftFemurLengthMm,
    rightFemurLengthMm,
    leftTibiaLengthMm,
    rightTibiaLengthMm,
    wingSpanMm
  } = values;
  return {
    ...(id ? { id } : {}),
    externalId,
    login,
    firstName,
    lastName,
    email,
    phone,
    ...(gender ? { gender } : {}),
    ...(country ? { country } : {}),
    ...(preferredName ? { preferredName } : {}),
    ...(dateOfBirth
      ? { dateOfBirth: format(new Date(dateOfBirth), "yyyy-MM-dd") }
      : {}),
    ...(surgeryDate
      ? { surgeryDate: format(new Date(surgeryDate), "yyyy-MM-dd") }
      : {}),
    treatmentData: {
      ...(operationType ? { operationType } : {}),
      ...(initialGapMm ? { initialGapMm: cmToMm(initialGapMm) } : {}),
      ...(nailTypeMm ? { nailTypeMm: cmToMm(nailTypeMm) } : {}),
      ...(initialHeightMm ? { initialHeightMm: cmToMm(initialHeightMm) } : {}),
      ...(targetDistractionMm
        ? { targetDistractionMm: cmToMm(targetDistractionMm) }
        : {}),
      ...(leftFemurLengthMm
        ? { leftFemurLengthMm: cmToMm(leftFemurLengthMm) }
        : {}),
      ...(rightFemurLengthMm
        ? { rightFemurLengthMm: cmToMm(rightFemurLengthMm) }
        : {}),
      ...(leftTibiaLengthMm
        ? { leftTibiaLengthMm: cmToMm(leftTibiaLengthMm) }
        : {}),
      ...(rightTibiaLengthMm
        ? { rightTibiaLengthMm: cmToMm(rightTibiaLengthMm) }
        : {}),
      ...(wingSpanMm ? { wingSpanMm: cmToMm(wingSpanMm) } : {})
    }
  };
};

export const transformCurrentPatientData = (patient: any) => {
  return {
    ...patient,
    dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth) : null,
    surgeryDate: patient.surgeryDate ? new Date(patient.surgeryDate) : null,
    nailTypeMm: mmToCm(patient.nailTypeMm),
    initialGapMm: mmToCm(patient.initialGapMm),
    initialHeightMm: mmToCm(patient.initialHeightMm),
    leftFemurLengthMm: mmToCm(patient.leftFemurLengthMm),
    rightFemurLengthMm: mmToCm(patient.rightFemurLengthMm),
    targetDistractionMm: mmToCm(patient.targetDistractionMm),
    leftTibiaLengthMm: mmToCm(patient.leftTibiaLengthMm),
    rightTibiaLengthMm: mmToCm(patient.rightTibiaLengthMm),
    wingSpanMm: mmToCm(patient.wingSpanMm)
  };
};

export const formTreatmentPlanForUpdate = (
  periods: Record<number, any[]>,
) => {
  const formattedPeriods: Record<string, any[]> = {}
  const entries = Object.entries(periods);
  entries.forEach(entry => {
    formattedPeriods[entry[0]] = entry[1].map(period => ({
      clicksPerDay: period?.clicksPerDay ? parseInt(period.clicksPerDay) : 0,
      distractionMm: cmToMm(period.distractionMm),
      id: period.id,
      sortOrder: period.sortOrder,
      side: period.side,
    }));
  });

  return formattedPeriods;
};


export const transformPatientTemplatePeriods = (items: Record<number, [ITreatmentPlanPeriod, ITreatmentPlanPeriod]>) => {
  const correctItems: any = {};
  for (let key in items) {
    correctItems[key] = items[key].map(item => ({ ...item, distractionMm: mmToCm(item.distractionMm) }));
  }
  return correctItems;
};

export const formTreatmentTemplateForCreation = (
  values: any,
  calculatedData: any,
  periods: ITreatmentPlanPeriod[],
  recalculate?: boolean
) => {
  const { id, name, operationType, nailTypeMm, initialGapMm } = values;

  const { totalClicks, duration, totalDistractionMm } = calculatedData;

  // eslint-disable-next-line
  const items = periods.map((item: any) => {
    const {
      clicksPerDay,
      distractionMm,
      duration,
      sortOrder,
      totalClicks
    } = item;
    if (item.distractionMm && item.clicksPerDay)
      return {
        clicksPerDay: parseInt(clicksPerDay),
        distractionMm: cmToMm(distractionMm),
        sortOrder,
        ...(totalClicks ? { totalClicks } : {}),
        ...(duration ? { duration } : {})
      };
  });

  const idToSend = id ? { id } : {};

  return {
    ...(recalculate ? {} : idToSend),
    ...(name ? { name } : { name: null }),
    ...(operationType ? { operationType } : { operationType: null }),
    nailTypeMm: cmToMm(nailTypeMm),
    initialGapMm: cmToMm(initialGapMm),
    ...(totalClicks ? { totalClicks } : {}),
    ...(duration ? { duration } : {}),
    ...(totalDistractionMm ? { totalDistractionMm } : {}),
    items
  };
};

export const transformCurrentTemplateData = (template: any) => {
  return {
    id: template.id,
    name: template.name,
    operationType: template.operationType,
    nailTypeMm: mmToCm(template.nailTypeMm),
    initialGapMm: mmToCm(template.initialGapMm)
  };
};

export const tansformCurrentTemplatePeriods = (items: any) => {
  return items.map((item: ITreatmentPlanPeriod) => {
    const {
      clicksPerDay,
      distractionMm,
      duration,
      id,
      sortOrder,
      totalClicks
    } = item;
    return {
      clicksPerDay,
      distractionMm: mmToCm(distractionMm),
      duration,
      id: id ? id : Math.random(),
      sortOrder,
      totalClicks
    };
  });
};

export const processClickingData = (data: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      date: format(new Date(item.date), "dd/MM/yyyy"),
      leftTimeSpent: secondsToMinutes(item.leftTimeSpent),
      rightTimeSpent: secondsToMinutes(item.rightTimeSpent)
    };
  });
};
