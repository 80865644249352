import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import "./App.module.scss";
import { TreatmentPlanPage } from "./views/pages/TreatmentPlanPage/TreatmentPlanPage";
import { PatientsPage } from "./views/pages/PatientsPage/PatientsPage";
import { theme } from "./theme";
import { CreateEditPatient } from "./views/pages/CreateEditPatient/CreateEditPatient";
import { ViewPatient } from "./views/pages/ViewPatient/ViewPatient";
import { RootNavigator } from "./views/pages/RootNavigator/RootNavigator";
import { AutoLogout } from "./views/common/AutoLogout/AutoLogout";
import { ViewTreatmentPlanTemplate } from "./views/pages/ViewTreatmentPlanTemplate/ViewTreatmentPlanTemplate";
import { CREATE, EDIT } from "./utils/constants";
import { CreateEditTreatmentPlanTemplate } from "./views/pages/CreateEditTreatmentPlanTemplate/CreateEditTreatmentPlanTemplate";
import { TermsOfUse } from "./views/pages/TermsOfUse/TermsOfUse";
import { PrivacyPolicy } from "./views/pages/PrivacyPolicy/PrivacyPolicy";
import { Toast } from "./views/common/Toast/Toast";
import { ErrorPage } from "./views/common/ErrorPage/ErrorPage";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Toast />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RootNavigator />} />
            <Route path="/patients" element={<PatientsPage />} />
            <Route
              path="/patients/create"
              element={<CreateEditPatient mode={CREATE} />}
            />
            <Route
              path="/patients/edit/:id"
              element={<CreateEditPatient mode={EDIT} />}
            />
            <Route path="/patients/view/:id" element={<ViewPatient />} />
            <Route path="/treatment-plans" element={<TreatmentPlanPage />} />
            <Route
              path="/treatment-plans/create"
              element={<CreateEditTreatmentPlanTemplate mode={CREATE} />}
            />
            <Route
              path="/treatment-plans/edit/:id"
              element={<CreateEditTreatmentPlanTemplate mode={EDIT} />}
            />
            <Route
              path="/treatment-plans/view/:id"
              element={<ViewTreatmentPlanTemplate />}
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <AutoLogout />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
