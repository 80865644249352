import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import classes from "./SelectField.module.scss";
import { DownChevron } from "../../../../assets/icons/DownChevron";
import { ISelectField, ISelectOption } from "../../../../utils/interfaces";

export const SelectField = ({
  id,
  label,
  options,
  required,
  selectRootClassName,
  selectInputClassName,
  formClassName,
  labelClassName,
  formik,
  type,
  helperClassName,
  disabled,
}: ISelectField) => (
  <FormControl className={formClassName}>
    <p className={labelClassName}>
      {label}
      {required && <span>*</span>}
    </p>
    <Select
      className={selectRootClassName}
      classes={{
        outlined: selectInputClassName
      }}
      displayEmpty={true}
      disabled={disabled}
      defaultValue={formik.values[id] ?? ""}
      id={id}
      IconComponent={DownChevron}
      onBlur={() => formik.setFieldTouched(id)}
      name={id}
      MenuProps={{
        MenuListProps: {
          classes: { root: classes.menuList }
        }
      }}
      onChange={formik.handleChange}
      placeholder={label}
      type={type}
      value={formik.values[id] ?? ""}
      error={formik.touched[id] && Boolean(formik.errors[id])}
    >
      <MenuItem key={"empty"} value={""}>{"-"}</MenuItem>
      {options &&
        options.map(({ id, label, name }: ISelectOption) => (
          <MenuItem key={id || name} value={id || name}>{label || name}</MenuItem>
        ))}
    </Select>
    <FormHelperText className={helperClassName}>{formik.touched[id] && formik.errors[id]}</FormHelperText>
  </FormControl>
);
