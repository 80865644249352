import React from 'react';
import classes from './Group.module.scss';
import styles from '../../../../common/commonStyles.module.scss';
import { ExerciseGroup, ExerciseItem as TExerciseItem } from '../../../../../types/exercises';
import { ExerciseItem } from './ExerciseItem';

type Props = {
  isActive: boolean;
  group: ExerciseGroup & { status: string };
  index: number;
  onManageGroupPress: (groupProps: ExerciseGroup) => void;
  exercises: TExerciseItem[];
  gender: string;
  onExerciseClick: (id: number) => void;
};

export const Group = ({
  isActive,
  group,
  index,
  onManageGroupPress,
  exercises,
  gender,
  onExerciseClick,
}: Props) => {
  return (
    <div
      className={classes.group}
      style={{
        backgroundColor: isActive ? group.backgroundColor : 'rgba(247, 247, 252, 1)',
        borderColor: isActive ? group.borderColor : 'rgba(217, 219, 233, 1)',
      }}
      key={group.name}>
      <div className={classes.groupDescription}>
        <span className={styles.text}>{`${index + 1}. ${group.name}`}</span>
        <span className={`${styles.text} ${classes.assignedLabel}`}>{group.status}</span>
      </div>
      <div className={classes.exerciseList}>
        {exercises.map((exercise) => (
          <ExerciseItem
            {...exercise}
            key={exercise.id}
            onClick={() => isActive && onExerciseClick(exercise.id)}
            previewSrc={gender === 'M' ? exercise.malePreviewLink : exercise.femalePreviewLink}
            isTextPreview={exercise.femaleLink || exercise.maleLink}
          />
        ))}
      </div>
      <button
        disabled={!isActive}
        onClick={() => onManageGroupPress(group)}
        className={classes.manageGroupButton}>
        <span style={{ color: isActive ? '#14142B' : '#858593' }} className={styles.text}>
          Manage group
        </span>
      </button>
    </div>
  );
};
