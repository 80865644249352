import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import {
  GET_TREATMENT_TEMPLATES_ERROR,
  GET_TREATMENT_TEMPLATES_REQUEST,
  GET_TREATMENT_TEMPLATES_SUCCESS
} from "../../actions";

function* workerGetTreatmentTemplates(action: {
  type: string;
  payload: {
    operationType: string;
    nailType: number;
    initialGap: number;
  };
}): Generator<any> {
  try {
    const data: any = yield call(
      TreatmentPlanTemplatesAPI.getTreatmentTemplates,
      action.payload
    );
    yield put({
      type: GET_TREATMENT_TEMPLATES_SUCCESS,
      payload: { treatmentPlans: data }
    });
  } catch (error: any) {
    yield put({
      type: GET_TREATMENT_TEMPLATES_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherGetTreatmentTemplates() {
  yield takeLatest(
    GET_TREATMENT_TEMPLATES_REQUEST,
    workerGetTreatmentTemplates
  );
}
