import { memo } from 'react';
import classes from './Legend.module.scss';

export const Legend = ({ legendData }) => {
  return (
    <div className={classes.legendContainer}>
      {legendData.map((legendColumn, index) => {
        return (
          <div className={classes.legendColumn} key={`legendColumn_${index}`}>
            {legendColumn.map((legendItem, index) => {
              return (
                <div className={classes.legendItem} key={`legendItem_${index}`}>
                  <span
                    style={{
                      background: legendItem.color,
                      ...legendItem.additionalStyles,
                    }}></span>
                  <p>{legendItem.label}</p>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
