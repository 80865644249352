import { call, put, takeLatest } from 'redux-saga/effects';
import { PatientsAPI } from '../../../api/patientsAPI';
import {
  GET_PATIENT_ERROR,
  GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  SET_CURRENT_PATIENT,
} from '../../actions';

function* workerGetSinglePatient(action: {
  type: string;
  payload: {
    id: string;
  };
}): Generator<any> {
  try {
    const data: any = yield call(PatientsAPI.getSinglePatient, action.payload.id);

    const patient = {
      ...data,
      ...data.treatmentData,
    };

    yield put({
      type: GET_PATIENT_SUCCESS,
      payload: {
        current: {
          [data.id]: patient,
        },
      },
    });

    yield put({
      type: SET_CURRENT_PATIENT,
      payload: {
        currentPatient: patient,
      },
    });
  } catch (error: any) {
    yield put({
      type: GET_PATIENT_ERROR,
      payload: { code: error.response.status, message: error.message },
    });
  }
}

export function* watcherGetSinglePatient() {
  yield takeLatest(GET_PATIENT_REQUEST, workerGetSinglePatient);
}
