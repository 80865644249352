import { combineReducers } from 'redux';
import { authReducer } from './reducers/authReducer';
import { clickingDataReducer } from './reducers/clickingDataReducer';
import { countriesReducer } from './reducers/countriesReducer';
import { errorReducer } from './reducers/errorReducer';
import { loadingReducer } from './reducers/loadingReducer';
import { patientsReducer } from './reducers/patientsReducer';
import { treatmentPlansReducer } from './reducers/treatmentPlansReducer';
import { toastReducer } from './reducers/toastReducer';
import { exercisesReducer } from './reducers/exercisesReducer';

export const rootReducer = combineReducers({
  authentication: authReducer,
  api: combineReducers({
    error: errorReducer,
    loading: loadingReducer,
  }),
  countries: countriesReducer,
  patients: patientsReducer,
  treatmentPlans: treatmentPlansReducer,
  clickingData: clickingDataReducer,
  toast: toastReducer,
  exercises: exercisesReducer,
});
