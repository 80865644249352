import { useState } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { ChangeTreatmentPlanPopup } from './components/ChangeTreatmentPlanPopup/ChangeTreatmentPlanPopup';
import { ClicksBar } from './components/CustomizedChartElements/ClicksBar';
import { CustomizedDot } from './components/CustomizedChartElements/CustomizedDot';
import { TimeBar } from './components/CustomizedChartElements/TimeBar';

export const Chart = ({ clickingData, chartDates, patientId, bone }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [popoverData, setPopoverData] = useState<any>(null);
  const [side, setSide] = useState<string>('');

  const handleClick = (data: any, index: number, event: any, side: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverData(data.payload);
    setSide(side);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverData(null);
  };

  return (
    <>
      {open && (
        <ChangeTreatmentPlanPopup
          anchorEl={anchorEl}
          data={popoverData}
          handleClose={handleClose}
          patientId={patientId}
          side={side}
          bone={bone}
          chartDates={chartDates}
        />
      )}
      <ResponsiveContainer width="100%" height="80%">
        <ComposedChart width={900} height={300} data={clickingData}>
          <CartesianGrid stroke="#D9DBE9" />
          <XAxis axisLine={false} dataKey="date" tickLine={false} type="category" tickMargin={15} />
          <YAxis
            allowDataOverflow
            axisLine={false}
            tickLine={false}
            type="number"
            yAxisId="timeLeft"
            orientation="left"
            domain={[0, 120]}
            width={0}
          />
          <YAxis
            allowDataOverflow
            axisLine={false}
            tickLine={false}
            type="number"
            yAxisId="timeRight"
            orientation="left"
            domain={[0, 120]}
            width={0}
          />
          <YAxis
            allowDataOverflow
            axisLine={false}
            ticks={[0, 4, 8, 12, 16, 20]}
            tickLine={false}
            type="number"
            yAxisId="clicks"
            orientation="left"
            scale="linear"
            label={{ value: 'C', position: 'bottom', offset: 20 }}
            width={25}
            domain={[0, 20]}
          />
          <YAxis
            allowDataOverflow
            axisLine={false}
            tickLine={false}
            type="number"
            yAxisId="pain"
            orientation="left"
            scale="linear"
            label={{ value: 'P', position: 'bottom', offset: 20 }}
            width={25}
            domain={[0, 10]}
          />
          <Bar
            barSize={15}
            dataKey="rightTimeSpent"
            fill="#E6F8FF"
            isAnimationActive={false}
            shape={(props: any) => <TimeBar {...props} rightClicks />}
            yAxisId="timeRight"
            stroke="#00A7E5"
          />
          <Bar
            barSize={15}
            dataKey="rightPlannedClicks"
            fill="#F7F2FF"
            isAnimationActive={false}
            shape={(props: any) => <ClicksBar {...props} rightClicks />}
            yAxisId="clicks"
            stroke="#C19CFC"
            onClick={(...rest) => handleClick(...rest, 'right')}
          />
          <Bar
            barSize={15}
            dataKey="leftPlannedClicks"
            fill="#E5EBFE"
            isAnimationActive={false}
            shape={<ClicksBar />}
            yAxisId="clicks"
            stroke="#9BB2FD"
            onClick={(...rest) => handleClick(...rest, 'left')}
          />
          <Bar
            barSize={15}
            dataKey="leftTimeSpent"
            fill="#E6F8FF"
            isAnimationActive={false}
            shape={<TimeBar />}
            yAxisId="timeLeft"
            stroke={'#00A7E5'}
          />
          <Line
            connectNulls
            dataKey="rightPainLevel"
            isAnimationActive={false}
            stroke="#107BFA"
            strokeWidth={3}
            type="monotone"
            yAxisId="pain"
            dot={<CustomizedDot />}
          />
          <Line
            connectNulls
            dataKey="leftPainLevel"
            isAnimationActive={false}
            stroke="#BD10FA"
            strokeWidth={3}
            type="monotone"
            yAxisId="pain"
            dot={<CustomizedDot />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};
