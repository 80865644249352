import { LeftFemur } from "../../../assets/icons/LeftFemur";
import { LeftTibia } from "../../../assets/icons/LeftTibia";
import { RightFemur } from "../../../assets/icons/RightFemur";
import { RightTibia } from "../../../assets/icons/RIghtTibia";
import {
  getOperationName,
  isLeftFemurSelected,
  isLeftTibiaSelected,
  isRightFemurSelected,
  isRightTibiaSelected
} from "../../../utils/commonUtils";
import { IOperationType } from "../../../utils/interfaces";
import classes from "./OperationType.module.scss";

export const OperationType = ({
  bone,
  side,
  operationType
}: IOperationType) => {
  const isLeftTibia = isLeftTibiaSelected(bone, side, operationType);
  const isRightTibia = isRightTibiaSelected(bone, side, operationType);
  const isLeftFemur = isLeftFemurSelected(bone, side, operationType);
  const isRightFemur = isRightFemurSelected(bone, side, operationType);

  return (
    <div className={classes.operationTypeContainer}>
      <div className={classes.bones}>
        <div className={classes.thighs}>
          <RightFemur {...(isRightFemur && { className: classes.selected })} />
          <LeftFemur {...(isLeftFemur && { className: classes.selected })} />
        </div>
        <div className={classes.shins}>
          <RightTibia {...(isRightTibia && { className: classes.selected })} />
          <LeftTibia {...(isLeftTibia && { className: classes.selected })} />
        </div>
      </div>
      <p className={classes.operationName}>{getOperationName(bone, side, operationType)}</p>
    </div>
  );
};
