import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classes from './AssignExercisesModal.module.scss';
import { Close } from '../../../../../assets/icons/Close';
import { EmptyState } from '../../../../../assets/icons/EmptyState';
import { Warning } from '../../../../../assets/icons/Warning';
import {
  DnDItemType,
  ExerciseGroup,
  ExerciseItem as TExerciseItem,
} from '../../../../../types/exercises';
import { ExerciseItem } from './ExerciseItem';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../../common/Modal/Modal';
import { ModelModal } from '../../../../common/ModelModal/ModelModal';
import { useDispatch, useSelector } from 'react-redux';
import { allExercisesSelector } from '../../../../../redux/selectors/exercisesSelector';
import {
  ASSIGN_EXERCISES,
  ASSIGN_EXERCISES_ERROR,
  GET_PATIENT_EXERCISES,
} from '../../../../../redux/actions';
import {
  assignExercisesErrorShowModal,
  assignExercisesSuccessShowModal,
  getCurrentPatient,
} from '../../../../../redux/selectors/patientsSelector';

const DropZone = ({
  onDrop,
  children,
  acceptType,
  className,
  deps,
}: {
  onDrop: ({ id }: { id: TExerciseItem['id'] }) => void;
  children?: React.ReactNode;
  acceptType: DnDItemType | DnDItemType[];
  className: string;
  deps: any[];
}) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: acceptType,
      drop: ({ id }: { id: TExerciseItem['id'] }, monitor) => {
        onDrop({ id });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    deps,
  );

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

type Props = {
  onClose: () => void;
} & ExerciseGroup;

export const AssignExercisesModal = ({
  onClose,
  borderColor,
  backgroundColor,
  name,
  id,
}: Props) => {
  const [assignedExercises, setAssignedExercises] = useState<TExerciseItem[]>([]);
  const [unassignedExercises, setUnassignedExercises] = useState<TExerciseItem[]>([]);

  const [isExerciseLimitModalOpen, setIsExerciseLimitModalOpen] = useState(false);
  const [isChangesNotSavedModalOpen, setIsChangesNotSavedModalOpen] = useState(false);
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [dropped, setDropped] = useState(false);

  const [currentModelULR, setCurrentModelULR] = useState({
    modelUrl: '',
    imageUrl: '',
  });

  const dispatch = useDispatch();

  const currentPatient = useSelector(getCurrentPatient);
  const errorShowModal = useSelector(assignExercisesErrorShowModal);
  const allExercises = useSelector(allExercisesSelector);
  const patientExercises = currentPatient?.exercises;
  const showSuccessModal = useSelector(assignExercisesSuccessShowModal);

  const groupExercises: TExerciseItem[] = useMemo(
    () =>
      allExercises
        .filter((exercise: any) => exercise.exerciseGroupDto.id === id)
        .map((exercise: any) => ({
          ...exercise,
          state: DnDItemType.DEFAULT_UNASSIGNED_EXERCISE,
        })),
    [allExercises],
  );

  useEffect(() => {
    if (groupExercises?.length == undefined || patientExercises?.length == undefined) {
      return;
    }

    setAssignedExercises(
      groupExercises
        .filter((exercise) => patientExercises.includes(exercise.id))
        .map((exercise) => {
          exercise.state = DnDItemType.ASSIGNED_EXERCISE;
          return exercise;
        }),
    );
    setUnassignedExercises(
      groupExercises
        .filter((exercise) => !patientExercises.includes(exercise.id))
        .map((exercise) => {
          exercise.state = DnDItemType.DEFAULT_UNASSIGNED_EXERCISE;
          return exercise;
        }),
    );
  }, [groupExercises, patientExercises]);

  useEffect(() => {
    dispatch({
      type: GET_PATIENT_EXERCISES,
      payload: {
        id: currentPatient.id,
      },
    });
  }, []);

  useEffect(() => {
    showSuccessModal && onClose();
  }, [showSuccessModal]);

  const deps = [assignedExercises, unassignedExercises];

  const handleDropToAssigned = ({ id }: { id: TExerciseItem['id'] }) => {
    setDropped(true);
    if (assignedExercises.length > 3) {
      setIsExerciseLimitModalOpen(true);
      return;
    }

    const newUnassignedExercises = [...unassignedExercises];

    const exerciseIndex = newUnassignedExercises.findIndex((item) => item.id === id);
    const exercise = newUnassignedExercises.splice(exerciseIndex, 1)[0];
    exercise.state = DnDItemType.ASSIGNED_EXERCISE;

    setAssignedExercises([...assignedExercises, exercise]);
    setUnassignedExercises(newUnassignedExercises);
  };

  const handleDropToUnassigned = ({ id }: { id: TExerciseItem['id'] }) => {
    setDropped(true);
    const newAssignedExercises = [...assignedExercises];

    const exerciseIndex = newAssignedExercises.findIndex((item) => item.id === id);
    const exercise = newAssignedExercises.splice(exerciseIndex, 1)[0];
    exercise.state = DnDItemType.UNASSIGNED_EXERCISE;

    setAssignedExercises(newAssignedExercises);
    setUnassignedExercises([...unassignedExercises, exercise]);
  };

  const handleClearAllPress = () => {
    setAssignedExercises([]);
    setUnassignedExercises(
      groupExercises.map((exercise) => {
        exercise.state = DnDItemType.UNASSIGNED_EXERCISE;
        return exercise;
      }),
    );
  };

  const handleCloseModal = () => {
    if (!dropped) {
      onClose();
    } else {
      setIsChangesNotSavedModalOpen(true);
    }
  };

  const handleChangesNotSavedModalCancelClick = () => {
    setIsChangesNotSavedModalOpen(false);
  };

  const handleChangesNotSavedModalProceedClick = () => {
    setIsChangesNotSavedModalOpen(false);
    onClose();
  };

  const onExerciseClick = (id: TExerciseItem['id']) => {
    const exercise = groupExercises.find((exercise) => exercise.id === id);

    if (!exercise) {
      return;
    }

    const modelUrl = currentPatient.gender === 'M' ? exercise.maleLink : exercise.femaleLink;
    const imageUrl =
      currentPatient.gender === 'M' ? exercise.malePreviewLink : exercise.femalePreviewLink;
    const url = modelUrl ?? imageUrl;

    setCurrentModelULR({ modelUrl, imageUrl });
    setIsModelModalOpen(true);
  };

  const handleSaveChanges = () => {
    const oldExerciseIds = currentPatient.exercises;
    const newExerciseIds = assignedExercises.map((item) => item.id);

    const allAssignedExerciseIds = oldExerciseIds.concat(newExerciseIds);

    const unassignedIds = unassignedExercises.map((exercise) => exercise.id);
    const resultIds = [];

    for (let id of allAssignedExerciseIds) {
      if (!unassignedIds.includes(id)) {
        resultIds.push(id);
      }
    }

    const allAssignedExerciseUniqueIds = Array.from(new Set(resultIds));

    dispatch({
      type: ASSIGN_EXERCISES,
      payload: {
        id: currentPatient.id,
        exerciseIds: allAssignedExerciseUniqueIds,
      },
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerInfo}>
            <span className={classes.exerciseName}>Assigning {name} exercises</span>
            <span className={classes.patientName}>
              {`${currentPatient.firstName} ${currentPatient.lastName} • ${currentPatient.externalId}`}{' '}
            </span>
          </div>
          <div onClick={handleCloseModal} className={classes.closeIcon}>
            <Close />
          </div>
        </div>
        <div className={classes.exercisesContainer}>
          <div className={classes.controlsInfo}>
            <span>
              To assign an exercise please Drag & Drop it from Unassigned to Assigned section.
            </span>
            <span>To preview the exercise click on it.</span>
          </div>
          <div className={classes.assigned} style={{ borderColor, backgroundColor }}>
            <span>Assigned</span>
            <DropZone
              deps={deps}
              onDrop={handleDropToAssigned}
              acceptType={[
                DnDItemType.DEFAULT_UNASSIGNED_EXERCISE,
                DnDItemType.UNASSIGNED_EXERCISE,
              ]}
              className={classes.assignedExerciseContainer}>
              {assignedExercises.length > 0 && (
                <div className={classes.exerciseList}>
                  {assignedExercises
                    .sort((a, b) => a.id - b.id)
                    .map((exercise, index) => (
                      <ExerciseItem
                        {...exercise}
                        isTextPreview={exercise.femaleLink || exercise.maleLink}
                        onRemove={handleDropToUnassigned}
                        key={exercise.id}
                        onClick={() => onExerciseClick(exercise.id)}
                        previewSrc={
                          currentPatient.gender === 'M'
                            ? exercise.malePreviewLink
                            : exercise.femalePreviewLink
                        }
                      />
                    ))}
                </div>
              )}
              {assignedExercises.length === 0 && (
                <div className={classes.noAssignedExercisesWarning}>
                  <EmptyState />
                  <div className={classes.warningText}>
                    <span>There is currently no assigned exercises.</span>
                    <span>Get started by Drag & Drop it from Unassigned to Assigned section.</span>
                  </div>
                </div>
              )}
            </DropZone>
            <button
              onClick={handleClearAllPress}
              className={`${classes.secondaryButton} ${classes.clearAllButton}`}>
              Clear all
            </button>
          </div>
          <div className={classes.unassigned}>
            <span>Unassigned</span>
            <DropZone
              deps={deps}
              onDrop={handleDropToUnassigned}
              acceptType={DnDItemType.ASSIGNED_EXERCISE}
              className={classes.exerciseList}>
              {unassignedExercises
                .sort((a, b) => a.id - b.id)
                .map((exercise, index) => (
                  <ExerciseItem
                    {...exercise}
                    isTextPreview={exercise.femaleLink || exercise.maleLink}
                    key={exercise.id}
                    onClick={() => onExerciseClick(exercise.id)}
                    previewSrc={
                      currentPatient.gender === 'M'
                        ? exercise.malePreviewLink
                        : exercise.femalePreviewLink
                    }
                  />
                ))}
            </DropZone>
          </div>
          <div className={classes.buttons}>
            <button onClick={handleCloseModal} className={classes.secondaryButton}>
              Cancel
            </button>
            <button onClick={handleSaveChanges} className={classes.primaryButton}>
              Save changes
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={errorShowModal}
        title={'Error Saving Changes'}
        message={"Sorry, we couldn't save your changes at this time.Please try again later."}
        handleCancel={() => {
          dispatch({
            type: ASSIGN_EXERCISES_ERROR,
            payload: { isError: false },
          });
        }}
        icon={Warning}
      />
      <Modal
        open={isExerciseLimitModalOpen}
        title={'Exercise Assignment Group Limit'}
        message={
          'The system enforces a limit on the group number of exercises that can be assigned to a patient. A maximum of 4 is allowed. Please review the assigned exercises to ensure they adhere to this restriction.'
        }
        handleCancel={() => setIsExerciseLimitModalOpen(false)}
        icon={Warning}
      />
      <Modal
        open={isChangesNotSavedModalOpen}
        title={'Changes are not saved'}
        message={
          "Are you sure you want to discard the changes you've made? Any unsaved progress will be lost."
        }
        handleCancel={handleChangesNotSavedModalCancelClick}
        icon={Warning}
        handleConfirm={handleChangesNotSavedModalProceedClick}
        deleteConfirmation
        confirmButtonTitle="Proceed without saving"
      />

      <ModelModal
        isOpen={isModelModalOpen}
        onClose={() => setIsModelModalOpen(false)}
        modelURL={currentModelULR.modelUrl}
        imageURL={currentModelULR.imageUrl}
      />
    </DndProvider>
  );
};
