import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import classes from "./MenuList.module.scss";
import { useCurrentPath } from "../../../../utils/router";
import { menuList } from "../../../../utils/navigation";
import { IMenuItem } from "../../../../utils/interfaces";
import { SET_PATIENTS_PAGINATION } from "../../../../redux/actions";

export const MenuList = () => {
  const activePath = useCurrentPath();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <List className={classes.menu}>
      {menuList.map(({ id, name, icon, path, shouldResetPatientsPagination }: IMenuItem) => {
        const isActive = activePath === path || activePath.includes(path);
        return (
          <ListItem
            className={`${classes.menuItem} ${
              isActive ? classes.activeMenuItem : ""
            }`}
            disablePadding
            key={id}
          >
            <ListItemButton
              className={isActive ? classes.activeMenuButton : ""}
              onClick={() => {
                if (shouldResetPatientsPagination && !pathname.startsWith("/patients")) {
                  dispatch({
                    type: SET_PATIENTS_PAGINATION,
                    payload: { pagination: { page: 1 } },
                  });
                }
                navigate(path);
              }}
            >
              <ListItemIcon
                className={`${classes.menuIcon} ${
                  isActive ? classes.activeMenuIcon : ""
                }`}
              >
                {icon}
              </ListItemIcon>
              <p
                className={`${classes.menuLabel} ${
                  isActive ? classes.activeMenuLabel : ""
                }`}
              >
                {name}
              </p>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
