import {
  BOTH,
  FEMUR,
  FEMURS,
  LEFT,
  LEFT_FEMUR,
  LEFT_TIBIA,
  RIGHT,
  RIGHT_FEMUR,
  RIGHT_TIBIA,
  TIBIA,
  TIBIAS
} from "./constants";
import { CORRECT_NUMBER_REGEX } from "./regexUtils";

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export function cmToMm(val: any) {
  if (!val) return null;
  return parseFloat((parseFloat(val) * 10).toPrecision(5));
}

export function mmToCm(val: any) {
  if (val === 0) return 0;
  if (!val) return "";
  return parseFloat((val / 10).toFixed(5));
}

export function isLeftTibiaSelected(
  bone?: string,
  side?: string,
  operationType?: string
) {
  if (operationType === TIBIAS || operationType === LEFT_TIBIA) return true;
  if (bone === TIBIA && (side === LEFT || side === BOTH)) return true;
  return false;
}

export function isRightTibiaSelected(
  bone?: string,
  side?: string,
  operationType?: string
) {
  if (operationType === TIBIAS || operationType === RIGHT_TIBIA) return true;
  if (bone === TIBIA && (side === RIGHT || side === BOTH)) return true;
  return false;
}

export function isLeftFemurSelected(
  bone?: string,
  side?: string,
  operationType?: string
) {
  if (operationType === FEMURS || operationType === LEFT_FEMUR) return true;
  if (bone === FEMUR && (side === LEFT || side === BOTH)) return true;
  return false;
}

export function isRightFemurSelected(
  bone?: string,
  side?: string,
  operationType?: string
) {
  if (operationType === FEMURS || operationType === RIGHT_FEMUR) return true;
  if (bone === FEMUR && (side === RIGHT || side === BOTH)) return true;
  return false;
}

export function getOperationName(
  bone?: string,
  side?: string,
  operationType?: string
) {
  if (bone && side) {
    let result = '';
    if (bone === TIBIA) result = "Tibia";
    if (bone === FEMUR) result = "Femur";

    if (side === BOTH) result += "s";
    if (side === LEFT) result = `Left ${result}`;
    if (side === RIGHT) result = `Right ${result}`;
    return result;
  } else if (operationType) {
    const result = operationType.split("_").map(word => capitalizeFirstLetter(word.toLowerCase()));
    return result.join(" ");
  }

  return "-";
}

export function operationTypeToBone(operationType: string): "FEMUR" | "TIBIA" {
  if (operationType.includes("TIBIA")) return "TIBIA";
  return "FEMUR";
}

export function uniqueId() {
  return Math.random();
}

export const isIncorrectNum = (value: any) => {
  return Boolean(value) && !CORRECT_NUMBER_REGEX.test(value?.toString() ?? '');
}

export const capitalizeFirstLetter = (string?: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
