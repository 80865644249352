import { ISvgIcon } from "../../utils/interfaces";

export const DoubleRightChevron = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0500031 14L5.05 7L0.0500031 0H2.5L7.5 7L2.5 14H0.0500031ZM6 14L11 7L6 0H8.45L13.45 7L8.45 14H6Z"
      fill="#158AA4"
    />
  </svg>
);
