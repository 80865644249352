import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";

export const getAuthHeader = () =>
  `Bearer ${sessionStorage.getItem("accessToken")}`;

export const apiInstance = () => {
  return axios.create({
    headers: {
      Authorization: getAuthHeader()
    }
  });
};
