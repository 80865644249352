export const actions = [
  {
    id: "report",
    label: "Report",
    description: "",
  },
  {}
];

export const formatClicksForReport = (clicks: any[]) => {
  return clicks.map(({
    date,
    leftAccidentalClicks,
    rightAccidentalClicks,
    leftActualClicks,
    rightActualClicks,
    leftPainLevel,
    rightPainLevel,
    leftPlannedClicks,
    rightPlannedClicks,
    leftTimeSpent,
    rightTimeSpent,
  }) => ({
    date: date ?? "",
    accidentalClicks: `${rightAccidentalClicks ?? 0} / ${leftAccidentalClicks ?? 0}`,
    actualClicks: `${rightActualClicks ?? 0} / ${leftActualClicks ?? 0}`,
    painLevel: `${rightPainLevel ?? 0} / ${leftPainLevel ?? 0}`,
    plannedClicks: `${rightPlannedClicks ?? 0} / ${leftPlannedClicks ?? 0}`,
    timeSpent: `${rightTimeSpent ?? 0} / ${leftTimeSpent ?? 0}`,
  }));
}
