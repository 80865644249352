import { ReactElement } from "react";
import { getActualBarHeight } from "./barUtils";

const barCount = 8;
const maxDuration = 120;
const timePerBar = maxDuration / barCount;
const margin = 4;
const oneBarHeight = 40.5;
const yStart = 6;
const timeLimit = 120;

const Rect = ({ actualTimeHeight, x, y, width, height, fill, stroke, exceededLimit }: any) => (
  <>
    <rect
      x={x}
      y={y}
      width={width}
      height={height > 0 ? height - margin : 0}
      rx="2.75"
      fill={fill}
      stroke={stroke}
      strokeWidth="0.5"
    />
    <rect
      x={x}
      y={y + (oneBarHeight - actualTimeHeight)}
      width={width}
      height={
        actualTimeHeight > 0 ? actualTimeHeight - margin : 0
      }
      rx="2.75"
      fill={exceededLimit ? "#E50000" : "#4CCFFF"}
      stroke={"#00A7E6"}
      strokeWidth="0.5"
    />
  </>
);

export const TimeBar = (props: any) => {
  const {
    fill,
    x,
    width,
    payload: { date, leftTimeSpent, rightTimeSpent },
    stroke,
    rightClicks
  } = props;

  let time = rightClicks ? rightTimeSpent : leftTimeSpent;

  const exceededLimit = rightTimeSpent + leftTimeSpent > timeLimit;

  const rects: ReactElement[] = [];

  let i = barCount;

  while (i >= 1) {
    const yCoord = yStart + ((i - 1) * margin) + ((i - 1) * oneBarHeight);
    const actualTime = (time && time >= timePerBar) ? timePerBar : time;
    const actualTimeHeight = getActualBarHeight(oneBarHeight, timePerBar, actualTime);
    rects.push(
      <Rect
        key={`${date}_${x}_${yCoord}_${i}`}
        actualTimeHeight={actualTimeHeight}
        x={x}
        y={yCoord}
        width={width}
        height={oneBarHeight}
        fill={fill}
        stroke={stroke}
        isLast={i === barCount - 1}
        exceededLimit={exceededLimit}
      />
    );
    time = time - timePerBar;
    --i;
  }

  return <>{rects}</>;
};
