import { Button } from "@mui/material";
import format from "date-fns/format";

import classes from "./Alarm.module.scss";
import { IAlarmProps } from "../../../../../../../utils/interfaces";
import { ActiveAlarm } from "../../../../../../../assets/icons/ActiveAlarm";

export const Alarm = ({ alarm, onResolve }: IAlarmProps) => {
  const currentDate = new Date(alarm.dateTime)

  return (
    <div className={classes.alarmContainer}>
      <div className={classes.iconContainer}>
        <span>
          <ActiveAlarm />
        </span>
      </div>
      <div className={classes.content}>
        <p>{alarm.message}</p>
        <p>{currentDate ? format(currentDate, "dd MMM 'at' HH:mm") : null}</p>
        {alarm.isResolved
          ? <h5>Alarm was resolved</h5>
          : <Button
            color="secondary"
            onClick={() => onResolve(alarm.id)}
            variant="outlined"
          >
            Resolve
          </Button>}
      </div>
    </div>
  );
};
