import { ISvgIcon } from "../../utils/interfaces";

export const LeftTibia = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="4"
    height="12"
    viewBox="0 0 4 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33514 1.68473C3.34467 0.956682 2.74209 0.369323 2.01452 0.397468C1.24802 0.427119 0.680718 1.12362 0.796943 1.88184C0.984982 3.10857 1.23819 4.88638 1.34946 6.22967C1.47851 7.78765 1.51565 9.9566 1.52627 11.151C1.53052 11.6288 1.9287 12.0105 2.40611 11.9908C2.85175 11.9725 3.20526 11.6089 3.2111 11.1629L3.33514 1.68473Z"
      fill="#D9D9D9"
    />
  </svg>
);
