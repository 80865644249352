import classes from './TermsOfUse.module.scss';

export const TermsOfUse = () => {
  return (
    <div className={classes.container}>
      <h1>Terms of use</h1>

      <h2>1. General</h2>
      <p>1.1. Welcome to BeckerBetz App to the Department for innovative orthopedics which is well-known and respected worldwide for its advanced approach to reconstructive surgery. With the mission statement to reach new heights, the Betz Institute offers first-class treatment to its patients from the first day on. With our application, you will be able to track the progress of your treatment plan.</p>
      <p>1.2. As you enter the App, and before performing any actions through it, or making any use of the Services or any information contained within the App and/or its features, you are hereby requested to carefully review these terms of use, which include the Privacy Policy (the "Terms of Use" and “Privacy Policy”, respectively). </p>
      <p>1.3. For convenience purposes only, reference herein to the masculine gender and the singular pronoun, shall also mean the feminine gender and the plural pronoun, and 	vice versa.</p>
      <p>1.4. The purpose of these Terms of Use is to regulate the relationship between BeckerBetz and any person, corporation or other entity that will use the App and/or information and/or service, of any kind, that is contained within the App and/or which may be obtained through the App or otherwise (the "User" or “Customer”).  Any use of the App is subject to the Terms of Use, including the Privacy Policy, as they shall be updated from time to time by BeckerBetz, both of which shall be available for review within the App and on BeckerBetz’s website at: <a target='_blank' href='https://www.betzinstitute.com/' rel="noreferrer">https://www.betzinstitute.com/</a></p>
      <p>1.5. It is clarified that entering the App and registering to the services agreement contained therein and/or using the Services, the App and/or the information presented therein constitutes and expresses your consent to the Terms of Use and Privacy Policy, and by doing so you declare that you have read, are aware of and agree to the Terms of Use, and agree to act in accordance with them without limitation or reservation. If you do not agree to the Terms of Use, including the Privacy Policy, you are thereby requested not to use the App. 	</p>
      <p>1.6. BeckerBetz reserves the right, at its sole discretion, to update and/or modify the Terms of Use and the Privacy Policy from time to time and without any prior notice to the User, and these conditions will be in effect from the moment they are made public within the App. Users will be notified in the event that the Terms of Use and/or the Privacy Policy will be updated and/or modified. The User shall not be required to re-approve the Terms of Use following such update for it to be effective, though the User can, at any time, disconnect from the App and Service, including deleting the User’s account and all information stored in it, by requesting via email.	</p>
      <p>1.7. Without derogating from anything to the contrary hereof, BeckerBetz reserves the right to terminate the App's activity at any time, for any period of time, for the purpose of maintenance or for any other purpose or reason. Such termination shall not require prior notice or reasoning whatsoever.</p>
      
      <h2>2. The App</h2>
      
      <h3>2.1. General</h3>
      <p>2.1.1. Any person who meets the requirements for registration to the App and receipt of Services thereunder as detailed in these Terms of Use, may use the App for the sole purpose of obtaining the Services, unless prohibited by law. Any use that exceeds the mere receipt of Services, may only be performed by those who meet the following cumulative conditions: (a) He is an adult (16+) legally capable of executing legally binding actions, and if you are a minor or you are not legally allowed to perform legal actions without your custodian or a guardian - your use of the App will be deemed to have been done after receiving approval based on an explicit consent of your custodian or a guardian and in any case as required by Law, and (b) the User is registered as a user of the App, as required </p>
      <p>2.1.2. BeckerBetz does not warrant that (a) the App will meet your expectations or requirements; (B) the Services and content offered on the App shall be provided without interruption or errors, and it reserves the right to terminate the availability of the App, at any time, in its sole discretion and without prior notice; and / or (c) subject to the provisions of the applicable law, the App shall be immune from unauthorized access to BeckerBetz computers or servers or from malfunctions, accidents or failures in the hardware, software and any other communication system of BeckerBetz and / or anyone acting on its behalf. It is clarified that BeckerBetz may change (including expand or reduce) the Service characteristics provided on the App, at its sole discretion and without giving any notice thereof.</p>
      
      <h3>2.2. App Registration and Usage</h3>
      <p>2.2.1. The User declares that he is competent to perform binding legal actions. In the event that the User is a minor (under the age of 16) or is not entitled to perform legal acts without the consent of a guardian, usage of the App shall be seen as though duly legally made, including consent to the Terms of Use and the Privacy Policy. The collection and the use of information about the User is subject to the Privacy Policy and the provisions of the Law.</p>
      <p>2.2.2. You warrant that: (a) the information provided by you will be correct and accurate and will not be used on the App using a false identity or fictitious identity or impersonating another; (b) the username you choose is not subject to the rights of another person; (c) do not use another person's username; and (d) In the event that you believe your data has been unauthorized use, you will report it immediately by contacting BeckerBetz Customer Service at the email address: <a href="mailto:freiburg@betzinstitute.com">freiburg@betzinstitute.com</a></p>
      <p>2.2.3. You are responsible for all actions taken or will be done within the App under your username, and you must keep your account information secure. BeckerBetz will not be liable for any damage or loss caused by any unauthorized use of your account.</p>
      <p>2.2.4. For the removal of any doubt, it is forbidden to make unauthorized use of the App. The App and its contents may not be used for any illegal purpose or for any purpose prohibited by these Terms of Use and/or the provisions of the Law. The App may not be used in a manner that could harm the App, the contents therein, or interfere with the use of the App by any other party.</p>
      <p>2.2.5. Furthermore, it is forbidden to attempt to gain unauthorized access to the App, Services, content, or computer systems or networks connected to the App and/or to obtain or attempt to obtain any services or information by means that are not intentionally made available to the user by the BeckerBetz.</p>
      <p>2.2.6. BeckerBetz may, in its sole discretion, suspend or block, either temporarily or permanently, the User's access to the content of the App in cases of violation of the 	provisions of the Law or the Terms of Use, or if there is interference of any kind with the proper operation of the App or of BeckerBetz’s computer systems or infringement of intellectual property rights of BeckerBetz’s or of any other party, and the User shall have no claim or demand in such case.</p>
      <p>2.2.7. It is clarified that BeckerBetz reserves the right to change, all at its sole discretion, the method of registration to the App at any time.</p>
      <p>2.2.8. It is emphasized that the App is for your personal use or any person under your legal care or custody only and you undertake not to do or allow others to do any of the following: (a) display content from the App in any way that changes the design of the App or omits any content, including advertisements and commercial content; (b) Use of the names, trademarks (whether registered or not) displayed on the App, copying, re-editing or publishing content appearing on the App, all without the express prior written consent of BeckerBetz and / or its rights holders, as the case may be, (c) the operation of any computer application or any other means, for the purpose of searching, scanning, copying, duplicating or automatically retrieving content; (d) use of the site for commercial purposes contrary to what is stated in the Terms of Use; (e) any transmission or transfer of content from the App; (f) the creation or distribution of viruses, hostile software and / or any other means that may harm, destroy, interfere with, or restrict the use of BeckerBetz or any third party computer systems, servers, hardware or software; (g) distributing "spam" or any use intended to 	track or harass another person in any way; and / or (h) any action that is contrary to the provisions of the law, or that has the effect of violating the rights of third parties.</p>
      <p>2.2.9. Without 	prejudice to the rights and remedies available to BeckerBetz under any law or agreement, BeckerBetz may block your use of the App or the Service, or any part thereof, including in the event that, in its sole discretion, if you or anyone on your behalf: (a) committed an illegal act or an act contrary to the provisions of any law; (b) breached of the Terms of Use; (c) provided incorrect identification information at the time of registration, or thereafter; or (d) you committed any act or omission that may harm BeckerBetz or impair the proper operation of the App.</p>
      
      <h3>2.3. Information to be provided by the User and other information related to the User</h3>
      <p>2.3.1. The User hereby declares and warrants that all details and information provided by him, to the extent he will be required to do so, as part of the App usage and/or for the purpose of receiving any Service are correct, complete and accurate and that he submitted the information in its own name and for himself only and not on behalf of and/or for any third party, except in cases where he has been expressly permitted to do so, according to the provisions of the Terms of Use and Law.</p>
      <p>2.3.2. The User acknowledges and agrees that in using the App, the App may collect personal information about the User, through voluntary feeding or automatic collection.</p>
      <p>2.3.3. The data you provide will be stored in BeckerBetz's registered database only for the time required and reasonable for the purposes for which it was collected, at BeckerBetz’s sole discretion, or until we receive an express request for deletion, and in any case - as required by law. You acknowledge that you are aware that there is no legal obligation for you to provide BeckerBetz the above data, and their delivery is subject to your free will and consent, and is subject to the App's Privacy Policy.</p>
      <p>2.3.4. It is hereby clarified that all such information, shall be kept by BeckerBetz in accordance with the provisions of the Terms of Use, Privacy Policy and Law.</p>
      
      <h2>3. The Service</h2>
      <p>3.1. The sample extraction and collection services are provided through (a) prior coordination of arrival at BeckerBetz laboratories or BeckerBetz's sampling points at various sites around the country, (b) prior coordination of arrival of a human sampler to the residence of the Customer, at the choice of the Customer - all through the App.</p>
      <p>3.2. The Customer of the Service will select on the App and / or through the service center time slot requested by him to perform the test according to the available time slots that will be displayed on the App [and / or offered to him by telephone by BeckerBetz representatives]  (the "Time Slot"). The Customer may change the Time Slot via the App and / or the service center, provided that the Time Slot is changed no later than fourteen (14) days from the date of ordering the Service and at least two working days in advance, before the Time Slot.</p>
      <p>3.3. It 	is the responsibility of the Customer to be at home and / or at the 	business, at the address provided by him when ordering the Service ("Customer Address") for the entire Time Slot. In the event that the Customer has ordered the Service for additional family members and / or for a group of employees and / or any other subject , it is the responsibility of the Customer to ensure the presence at the Customer Address during the Time Slot. In the event that a test ordered was not performed due to the 	absence of the User and / or any of the Test Subject during theTime Slot, the payment for the test that was not performed due to such absence will not be refunded.</p>
      <p>3.5. As a prerequisite for the provision of the Services, the Customer is required to follow legal procedures and protocols, including personal identification, consent to perform the Services, medical questionnaire, waiver of confidentiality etc.</p>
      <p>3.6. The sample extraction and collection are performed by authorized and highly experienced professional samplers, using the most advanced sampling kits, safeguards, and standards approved by the Ministry of Health.</p>
      <p>3.7. For the benefit of providing the aforesaid Services, BeckerBetz also operates via professional representatives, suppliers and subcontractors.</p>
      <p>3.13. It is clarified that BeckerBetz may make use of the information and share the information received by the Service Customer as part of the use of the App and / or receipt of the Service, including in relation to the results within the service, as required by law. For more information, please find Privacy Policy below.</p>
    
      <h2>4. Intellectual Property</h2>
      <p>4.1. All rights of any kind, including intellectual property rights, on the App, in content, designs, applications, tools and other components on the App, as well as in any invention, improvement, innovation, development, discovery, alteration, or creation related to the Site and subject to any intellectual property rights (including Patents, copyrights, designs, designs, trade secrets and trademarks, including applications for registration of these rights ("Intellectual 	Property Rights") are the property of BeckerBetz and / or third parties authorized by BeckerBetz to use them. "Content" means any information of any kind, including (but not limited to) any document, record, image, photograph, illustration, animation, chart, character, video, audio file, software, file, computer code, application, format, Protocol, database, user interfaces, as well as any character, sign, symbol, and icon, and all of them in any media or format whatsoever.</p>
      <p>4.2. BeckerBetz hereby grants you a limited, revocable license, which is not exclusive and is not transferable or assignable, to make personal use of the site and BeckerBetz content, solely for the purposes described on the App and the Terms of Use, and all subject to the other provisions of the Terms of Use. Beyond what is granted, BeckerBetz does not grant or waive any right or license, express or implied, in connection with the App, the Content and the intellectual Property rights.</p>
      <p>4.3. To the extent that the User sends to BeckerBetz recommendations or objections regarding the App Services or products offered on the App ("User Notices"), BeckerBetz shall own the exclusive proprietary rights to these User Notices, and may use them at its sole discretion without having to pay or compensate the user in any way in respect of such User Notices. In addition, it is clarified that the User Notices are not confidential, and BeckerBetz will not bear confidential duties regarding them.</p>

      <h2>5. Liability</h2>
      <p>5.1. The information published on the App is for general and informative purposes only and is provided as part of the App’s Services to the Users only. Use of the App is at the User's sole and complete responsibility. BeckerBetz does not guarantee and is not responsible for the accuracy, reliability or integrity of the content on the App. BeckerBetz or anyone acting on its behalf will not be held responsible, in any way, directly or indirectly, financially or otherwise, for any consequences arising from the use of the site or your reliance on the contents on it. You will not have any claim, demand and / or cause of action against BeckerBetz or anyone on its behalf in connection with the site, including the availability of its contents, malfunctions, results of its use, etc., and to the extent that you have or may have any such claim, whether known or to be known in the future, you waive it irrevocably.</p>
      <p>5.2. Unless provided otherwise herein, BeckerBetz may change any aspect relating to the App, without notice. Such changes will be made, taking into account the dynamic nature of the Internet and the technological and other changes that are taking place in it. By their nature, such changes may involve malfunctions or initially cause discomfort. You represent that you will not have any claim or claim against BeckerBetz in respect of any such changes or malfunctions that may occur in the course of their implementation.</p>
      <p>5.3. BeckerBetz takes various security measures in order to maintain the privacy of the Users and the information provided on the App. However, the App may be exposed to security breaches or intrusions into its servers. Use of the App releases BeckerBetz or anyone on its behalf from any liability for any damage caused to the user or anyone on his behalf due to misuse made on the App, including security breaches and intrusion into the App's servers in order to disclose information about Users. Hence, BeckerBetz recommends all Users to take the appropriate measures regarding data security, including but not limited to installing security software, detecting and reporting abnormalities etc.</p>
      <p>5.4. You hereby undertake to compensate and indemnify BeckerBetz and all workers on its behalf, immediately upon its first claim, for any damage, loss, liability or expense, including attorney's fees and legal expenses incurred by any of them as a result of using the App or Services by you. Including in respect of any of your acts or omissions that are contrary to the provisions of the Terms of Use, and including in respect of any claim, demand or cause of action of a third party arising from any of your acts or omissions. BeckerBetz reserves the right to assume the sole protection and control of the legal process in any such matter. Such BeckerBetz right will not relieve you of your indemnification obligations in any case, and you undertake to fully cooperate with BeckerBetz or anyone on its behalf for this purpose, including by providing your information and materials. You agree not to reach a settlement in any matter subject to your indemnification obligations, as set forth above, without the express prior written consent of BeckerBetz.</p>

      <h2>6. Privacy Policy</h2>
      <p>Your privacy is important to us. In order to improve the protection of your privacy, we provide you with the following information regarding our privacy policies and protection, as well as the alternatives available to you concerning the use of the App and the manner in which we handle the information collected by the App and Service.</p>

      <h3>6.1.Introduction</h3>
      <p>6.1.1. BeckerBetz . Lic. ("BeckerBetz") respects the privacy of the Users who enter the App and / or the Users of the various Services offered within its framework. Therefore, BeckerBetz implemented a privacy policy, as featured in the App, which is designed to present the manner in which it uses the information given to it by Users, and/or collected by it while using the App, and the manner in which the User uses the content contained on the App and / or to which the User is exposed through it.</p>
      <p>6.1.2.  The Privacy Policy set forth below constitute an integral part of the 	Terms of Use. All terms used in the Privacy Policy shall have the meaning attributed to them in the Terms of Use.</p>
      <p>6.1.3. BeckerBetz may from time to time, and in its sole discretion, change the provisions of its Privacy Policy to reflect technological, business, legal or regulatory changes. Once the updated and/or modified Privacy Policy are published, Users will be notified accordingly.</p>
      <p>6.1.4. Any information (as defined in the Protection of Privacy Law, 1981), which shall be delivered to BeckerBetz and/or collected by BeckerBetz and/or the App and which will be kept by it, shall be kept in a registered database of BeckerBetz and/or of anyone on its behalf, in accordance with the provisions of the Protection of Privacy Law, 1981.</p>
      <p>6.1.5. Users of the website can contact BeckerBetz to remove themselves from the database via the App or by contacting the BeckerBetz <a href="mailto:freiburg@betzinstitute.com">freiburg@betzinstitute.com</a></p>

      <h3>6.2. Collection and Use of Data</h3>
      <p>6.1.1. BeckerBetz . Lic. ("BeckerBetz") respects the privacy of the Users who enter the App and / or the Users of the various Services offered within its framework. Therefore, BeckerBetz implemented a privacy policy, as featured in the App, which is designed to present the manner in which it uses the information given to it by Users, and/or collected by it while using the App, and the manner in which the User uses the content contained on the App and / or to which the User is exposed through it.</p>
      <p>6.1.2.  The Privacy Policy set forth below constitute an integral part of the 	Terms of Use. All terms used in the Privacy Policy shall have the meaning attributed to them in the Terms of Use.</p>
      <p>6.1.3. BeckerBetz may from time to time, and in its sole discretion, change the provisions of its Privacy Policy to reflect technological, business, legal or regulatory changes. Once the updated and/or modified Privacy Policy are published, Users will be notified accordingly.</p>
      <p>6.1.4. Any information (as defined in the Protection of Privacy Law, 1981), which shall be delivered to BeckerBetz and/or collected by BeckerBetz and/or the App and which will be kept by it, shall be kept in a registered database of BeckerBetz and/or of anyone on its behalf, in accordance with the provisions of the Protection of Privacy Law, 1981.</p>
      <p>6.1.5. Users of the website can contact BeckerBetz to remove themselves from the database via the App or by contacting the BeckerBetz <a href="mailto:freiburg@betzinstitute.com">freiburg@betzinstitute.com</a></p>

      <h3>6.2. Collection and Use of Data</h3>
      <p>6.2.1. BeckerBetz may collect and use the information about the User, through voluntary feeding by the User or automatic collection performed by the App, (a) At the time of entering to the App; and/or (b) At the time of registration; and/or (c) At the time of purchasing and receiving the Service; and/or (d) At the time of using the App Service.</p>
      <p>6.2.2. All User’s information is being collected, used and stored only for and as sufficiently required by the crucial purposes of the App - the provision of the Service, including improving, enriching and updating the Service and/or the App, updating the Users regarding Services and promotions offered by BeckerBetz and/or anyone on its behalf.</p>
      <p>6.2.3. The information provided by the User (if provided) and/or being collected through the App (if collected) shall include - </p>
      <p>6.2.4. Personal and demographic information that the User himself provided for identification purposes: full name, date of birth.</p>
      <p>6.2.5. Additional information for the purpose of providing the Service:</p>
      <p>6.2.6. Additional data may be required to perform various Services, to the extent required for the provision of the Services, and in any event BeckerBetz shall not request any information which is not required for such purpose.</p>
      <p>6.2.7. It is emphasized that the collection and use of the above information about the User is necessary, to ensure the correct and accurate identification of each User enabling BeckerBetz to provide the Services.</p>
      <p>6.2.8. It is hereby clarified that the User has no obligation to provide such information, but failure to provide the required details may lead to the failure by BeckerBetz to provide the Services (in whole or in part). The User hereby waives and will be prevented from raising any claim against BeckerBetz in connection with the withholding or not-withholding of such information in the BeckerBetz's database.</p>
      <p>The App also automatically collects information in relation to the User and the use of the App performed by him. This information may include, but is not limited to:
        Usage 	patterns and User activity in the App;
        Device identifiers or other identifiers;
        Information relating to traffic on internet servers;
      </p>
      <p>6.2.10. It is hereby clarified and agreed that when using the application, BeckerBetz will be permitted to store on the User's device, files that enable the Company to identify the user while browsing. The use of such files and/or the information collected through them will be done in accordance with the terms of the Privacy Policy.</p>
      <p>6.2.11. The App uses software for its day-to-day and proper operation, including to collect statistics about the use of the App, to verify details, to adapt the App to the User's personal preferences and information security needs. BeckerBetz takes precautionary measures to ensure that only the company's computers can read and understand the information stored in them.</p>
      <p>6.2.12. It is clarified and agreed by the User that when using and / or registering for the App, BeckerBetz will be allowed to plant files on the User's device that allow BeckerBetz to identify the surfer while browsing on the App. The use of such files and / or the information collected in that way will be carried out in accordance with the terms of the Privacy Policy.</p>
      <p>6.2.13. In addition, BeckerBetz may use the information for marketing and statistical purposes, for its sole needs and/or through its transfer to third parties, subject to the provisions of the Terms of Use, Privacy Policy and Law.</p>
      <p>6.2.14. Notwithstanding the foregoing, it is hereby clarified that BeckerBetz will be entitled to provide information that was accumulated about the User to a third party for the purpose of performing Services on its behalf. The user details will not include credit details.</p>
      <p>6.2.15. Without derogating from the above, subject to the User providing his contact information to BeckerBetz and his consent to receive direct mail, BeckerBetz may send to the User, from time to time, by e-mail and/or other contact information provided by the User, information about its Services and marketing and advertising information of BeckerBetz and/or of third parties. The User will be entitled to revoke his consent at any time and cease to receive such information by email.</p>
      <p>6.2.16. For further clarifications regarding the Collection and Usage of Data, please feel free to contact us by sending an email.</p>
    
      <h2>7. Transfer of Information to third Parties</h2>
      <p>7.1. BeckerBetz will be entitled to provide information that was accumulated about the User to a third party for the purpose of performing Services on its behalf, as specified hereinafter.</p>
      <p>7.2. BeckerBetz hereby clarifies that it will ensure that such third parties to whom it will transfer or share with such information shall impose the same (or in any event no inferior) means of protection for such information.</p>
      <p>7.3. The user details will not include credit details.</p>
      <p>7.4. BeckerBetz will take reasonable measures to maintain and prevent the transfer of information identifying the User by name and/or identity information such as the name, to third parties, except in the following cases:
        Upon the User's own requirement or with his express consent        
        In any case where the User has violated the provisions of the Terms of Use, including the Privacy Policy and / or in cases where the User has performed or attempted to perform himself and / or anyone on his behalf, through the App and / or in connection with it, actions contrary to the Terms of Use or any law. In case of a court order directing BeckerBetz to disclose information to third parties.
        Due to any dispute, claim, lawsuit, demand or legal proceedings which may be conducted between the User or on his behalf and BeckerBetz and / or anyone on its behalf.         
        In any case where BeckerBetz deems that providing the information is necessary in order to prevent serious damage to the property and/ or bodily injury of BeckerBetz, the User and / or third parties.         
        In the event BeckerBetz transferred and/or assigned its activities and / or its rights and obligations towards the user to third parties, provided that such third parties undertake the provisions set out in this Privacy policy. 				
        The above does not derogate from BeckerBetz's right to transfer to third parties information which does not directly identify the User by his name and / or identity information.
      </p>

      <h2>8. Notice and Removal</h2>
      <p>BeckerBetz reserves the right to remove from the App content which, according to third parties, infringes their intellectual property rights or other rights. If you believe that any content appearing on the App violates your intellectual property rights, or that of a third party, or violates any other provision of the Terms of Use, please email: <a href="mailto:freiburg@betzinstitute.com">freiburg@betzinstitute.com</a> and indicate the following details: (a) Your full details and details how to get in contact with you (full name, telephone, e-mail); (b) an accurate identification of the content that you claim is infringing and a detailed explanation in what way it is infringing; and (c) your signed statement that to the best of your knowledge the information you provide is complete and correct.</p>
    
      <h2>9. Information Security</h2>
      <p>9.1. BeckerBetz embodies and implements information security systems and 	procedures, taking common precautions and using advanced security technologies, to protect the confidentiality of the data provided to it by the Users and /or collected automatically by the App as mentioned before.</p>
      <p>9.2. However, BeckerBetz hereby clarifies that it does not commit that the App will be operated properly, without any interruption, and / or that the App and / or data collected and / or provided as stated above will be completely immune from unauthorized access and / or intrusion into the BeckerBetz’s databases, and the User is aware that BeckerBetz will not be liable for any damage and / or loss, direct or indirect, caused as a result, including invasion of privacy.</p>

      <h2>10. Miscellaneous</h2>
      <p>10.1. BeckerBetz may terminate the activity of the App, in whole or in part, at any time, or change the structure, appearance and design of the site, the scope and availability of the services on the site and / or any other aspect involved in the App. Such changes may involve malfunctions and inconveniences and you will not have any claim, demand or cause of action against BeckerBetz or anyone on its behalf for stopping any such activity, for such changes or for malfunctions that occur because or during the taking of such measures.</p>
      <p>10.2. Unless otherwise specified herein, BeckerBetz may, in its sole discretion, change the provisions of the Terms of Use, in whole or in part, without the need to give prior notice although it will take acceptable commercial measures to do so, as far as possible. Any update to the Terms of Use will be binding on all users of the site from the date of its publication on the site. Once the updated and/or modified Terms of Use and/or the Privacy Policy made public within the App, an appropriate notification will be sent to the User.</p>
      <p>10.3. The use of the App, including any dispute, controversy or legal proceeding related to the Terms of Use shall be governed solely by the laws of the State of  . The exclusive place of jurisdiction for any matter relating to the use of the App or the Terms of Use, either directly or indirectly, shall be vested in the competent courts in Germany.</p>
      <p>10.4. The Terms of Use constitute the full legal contract between you and BeckerBetz in relation to your use of the App.</p>
      <p>10.5. BeckerBetz may transfer and / or assign and / or transfer its rights and / or obligations under the Terms of Use at any time to any third party without the consent of the user. Nevertheless, at any time, it is at the User discretion whether to disconnect from the App and Service, including deleting the User’s account and all information stored in it.</p>
      <p>10.6. If it is determined that any part of the Terms of Use is invalid, or unenforceable, then the terms whose validity has been revoked or unenforceable will be deemed to have been replaced by valid and enforceable terms whose content conforms to the original terms, while the other Terms of Use shall remain in full force and effect, as written.</p>
      <p>10.7. Any delay or abstention by BeckerBetz from exercising or enforcing its right under the Terms of Use shall never be deemed as a waiver or as a preclusion on its part from exercising its rights in the future, and BeckerBetz may exercise its rights, in whole or in part, whenever it deems proper to do so, unless otherwise agreed by BeckerBetz in writing.</p>
      <p>10.8. If you have any questions regarding the Terms of Use and / or Privacy Policy, please feel free to contact us by sending an email.</p>
      <p>10.9. BeckerBetz wishes you a pleasant and useful use of the App.</p>
    </div>
  )
}
