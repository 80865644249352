import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import { ToastType } from "../../../utils/interfaces";
import {
  CREATE_TREATMENT_TEMPLATE_ERROR,
  CREATE_TREATMENT_TEMPLATE_REQUEST,
  CREATE_TREATMENT_TEMPLATE_SUCCESS,
  SET_TOAST
} from "../../actions";

function* workerCreateTreatmentTemplate(action: {
  type: string;
  payload: { data: any; callback: Function, navigate: Function, isEdit: boolean };
}): Generator<any> {
  try {
    const { data, navigate, isEdit, callback } = action.payload;
    const response: any = yield call(
      TreatmentPlanTemplatesAPI.createUpdateTreatmentTemplate,
      data
    );
    yield put({
      type: CREATE_TREATMENT_TEMPLATE_SUCCESS
    });
    callback();
    navigate(`/treatment-plans/view/${response.id}`);
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: isEdit ? "Changes were successfully saved" : "New Treatment Plan Template is added successfully",
        type: ToastType.SUCCESS,
      }
    });
  } catch (error: any) {
    yield put({
      type: CREATE_TREATMENT_TEMPLATE_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherCreateTreatmentTemplate() {
  yield takeLatest(
    CREATE_TREATMENT_TEMPLATE_REQUEST,
    workerCreateTreatmentTemplate
  );
}
