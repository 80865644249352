import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { AddPeriod } from "../../../../../assets/icons/AddPeriod";
import { ITreatmentPlanPeriod, SIDE } from "../../../../../utils/interfaces";
import { Modal } from "../../../../common/Modal/Modal";
import { TextData } from "../../../../common/TextData/TextData";
import classes from "./EditPatientTreatmentPlan.module.scss"
import { TreatmentPlanRow } from "./TreatmentPlanRow/TreatmentPlanRow";

type PeriodType = [ITreatmentPlanPeriod, ITreatmentPlanPeriod]

export const EditPatientTreatmentPlan = ({
  formik,
  periods,
  setPeriods,
  recalculateTemplate,
  treatmentPlanData,
}: any) => {
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const { name, distraction, duration, clicks } = treatmentPlanData

  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  const setTimeoutToRecalculate = useCallback(
    (periods: any) => {
      if (timeout) clearTimeout(timeout);
      // eslint-disable-next-line
      timeout = setTimeout(() => {
        recalculateTemplate(periods);
      }, 2000);
    },
    [periods]
  );

  const openModal = useCallback((sortOrder: number) => {
    setOpen(true);
    setIdToDelete(sortOrder);
  }, []);

  const closeModal = () => {
    setOpen(false);
  };

  const deletePeriod = () => {
    closeModal();
    const entries = Object.entries<[ITreatmentPlanPeriod, ITreatmentPlanPeriod]>(periods);
    const newPeriods: any = {}
    entries
      .filter(entry => parseInt(entry[0]) !== idToDelete)
      .forEach((entry, index) => {
        newPeriods[index + 1] = entry[1].map(item => ({ ...item, sortOrder: index + 1 }));
      })
    setPeriods(newPeriods);
    recalculateTemplate(newPeriods);
  };

  const addPeriod = () => {
    const lastSortOrder = Object.keys(periods).at(-1);

    const newLastSortOrder = parseInt(lastSortOrder ?? "0") + 1
    const newPeriod = {
      id: null,
      distractionMm: null,
      clicksPerDay: null,
      duration: null,
      totalClicks: null,
      sortOrder: newLastSortOrder,
      endDate: "",
      startDate: ""
    }

    const updatedPeriods = {
      ...periods,
      [newLastSortOrder]: [{ ...newPeriod, side: SIDE.LEFT }, { ...newPeriod, side: SIDE.RIGHT }]
    }
     setPeriods(updatedPeriods);
  };

  const changeValue = useCallback((sortOrder: number, key: string, value: string, side?: SIDE) => {
    let shouldRecalculate = false;
    const newPeriods = {
      ...periods,
      [sortOrder]: periods[sortOrder].map((item: ITreatmentPlanPeriod) => {
        if (!side || item.side === side) {
          shouldRecalculate = true;
          return { ...item, [key]: value }
        }
        return { ...item }
      })
    }
    setPeriods(newPeriods);
    if (shouldRecalculate) {
      setTimeoutToRecalculate(newPeriods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods]);

  return (
    <div className={classes.treatmentTimelineContainer}>
      <h2>{name}</h2>
      <div className={classes.calculatedData}>
        <TextData
          name="Total Distraction"
          value={distraction}
        />
        <TextData
          custom
          name="Duration"
          value={<p>{`${duration} days` || "-"}</p>}
        />
        <TextData
          custom
          name="Total Amount of Clicks"
          value={<p>{clicks || "-"}</p>}
        />
      </div>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left">Subdivide legs</TableCell>
            <TableCell align="left">Period</TableCell>
            <TableCell align="left">R/L Cm for Period</TableCell>
            <TableCell align="left">R/L Clicks Per Day</TableCell>
            <TableCell align="left">Duration</TableCell>
            <TableCell align="left">R/L Amount of Clicks</TableCell>
            <TableCell align="left">Current day</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {Object.keys(periods).length &&
            Object.values<PeriodType>(periods).map((periodsTuple: PeriodType, index: number) => {
                return (
                  <TreatmentPlanRow
                    key={index}
                    period={periodsTuple}
                    openModal={openModal}
                    totalAmount={periods.length}
                    changeValue={changeValue} />
                )
              }
            )}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <Button
          className={classes.addPeriod}
          onClick={addPeriod}
          startIcon={<AddPeriod />}
          variant="text"
        >
          Add period
        </Button>
      </div>
      <Modal
        deleteConfirmation
        open={open}
        title={"Delete period"}
        message={"Are you sure that you want to delete period?"}
        handleConfirm={deletePeriod}
        handleCancel={closeModal}
      />
    </div>
  );
};
