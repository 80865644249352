import { IReduxAction } from '../../utils/interfaces';
import { GET_ALL_EXERCISES_SUCCESS } from '../actions';
import { Exercise } from '../../types/exercises';

export type ExercisesReducerState = {
  allExercises: Exercise[];
};

const initialState: ExercisesReducerState = {
  allExercises: [],
};

export const exercisesReducer = (state = initialState, { type, payload }: IReduxAction) => {
  switch (type) {
    case GET_ALL_EXERCISES_SUCCESS: {
      return {
        ...state,
        allExercises: payload.data,
      };
    }
    default: {
      return state;
    }
  }
};
