import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './PatientsPage.module.scss';
import {
  GET_PATIENTS_REQUEST,
  SET_PATIENTS_PAGINATION,
  SET_TABLE_STATE,
} from '../../../redux/actions';
import {
  getTableState,
  getTotalPatients,
  patientsPaginationSelector,
  patientsSelector,
} from '../../../redux/selectors/patientsSelector';
import { SORT, SORT_TYPE } from '../../../utils/constants';
import { FullPageContainer } from '../../common/FullPageContainer/FullPageContainer';
import { PatientsControls } from './components/PatientsControls/PatientsControls';
import { PatientsTable } from './components/PatientsTable/PatientsTable';
import { STORAGE_KEYS, TABS } from '../../../utils/interfaces';

export const PatientsPage = () => {
  const dispatch = useDispatch();
  const ref: any = useRef(null);
  const [searchText, setSearchText] = useState('');
  const { page } = useSelector(patientsPaginationSelector);
  const { tab } = useSelector(getTableState);

  const setTab = (tab: TABS) => {
    dispatch({
      type: SET_TABLE_STATE,
      payload: { tab },
    });
  };

  const handleChangeTab = (newTab: any) => {
    dispatch({
      type: SET_PATIENTS_PAGINATION,
      payload: { pagination: { page: 1 } },
    });
    setTab(newTab);
  };

  const size = 25;
  const patientsData = useSelector(patientsSelector(tab));
  const [totalActive, totalFinished] = useSelector(getTotalPatients);
  const { patients, totalPages } = patientsData || {};
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.sort) ?? 'null') || {
      value: '' as SORT,
      type: SORT_TYPE.ASC,
    },
  );

  const setSorting = (newValue: any) => {
    setSort(newValue);
    localStorage.setItem(STORAGE_KEYS.sort, JSON.stringify(newValue));
  };

  const handleSorting = (newValue: SORT) => {
    const { value, type } = sort;
    if (!value || value !== newValue) setSorting({ value: newValue, type: SORT_TYPE.ASC });
    else if (value === newValue)
      setSorting({
        value,
        type: type === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
  };

  const setPage = (newPage: number) => {
    dispatch({
      type: SET_PATIENTS_PAGINATION,
      payload: { pagination: { page: newPage } },
    });
  };

  const updateSearchParams = (text: string) => {
    setSearchText(text);
    if (text) setPage(1);
  };

  useEffect(() => {
    if (size) {
      dispatch({
        type: GET_PATIENTS_REQUEST,
        payload: { size, page, searchText, status: tab, sort },
      });
    }
  }, [dispatch, page, searchText, size, tab, sort]);

  return (
    <FullPageContainer>
      <>
        <PatientsControls updateSearchParams={updateSearchParams} />
        <div className={classes.patientsTableContainer} ref={ref}>
          <PatientsTable
            data={patients}
            handleChangeTab={handleChangeTab}
            handlePagination={setPage}
            tab={tab}
            sort={sort}
            totalPages={totalPages}
            currentPage={page}
            handleSorting={handleSorting}
            totalActive={totalActive}
            totalFinished={totalFinished}
          />
        </div>
      </>
    </FullPageContainer>
  );
};
