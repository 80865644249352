import { call, put, takeLatest } from "redux-saga/effects";
import { PatientsAPI } from "../../../api/patientsAPI";
import {
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_ERROR,
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_REQUEST,
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS,
} from "../../actions";

function* workerRecalculatePatientTemplate(action: {
  type: string;
  payload: {
    data: any;
  };
}): Generator<any> {
  try {
    const data: any = yield call(
      PatientsAPI.recalculateTemplate,
      { data: action.payload.data }
    );
    yield put({
      type: RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS,
      payload: {
        patientPlan: data
      }
    });
  } catch (error: any) {
    yield put({
      type: RECALCULATE_PATIENT_TREATMENT_TEMPLATE_ERROR,
      payload: { message: error.message }
    });
  }
}

export function* watcherRecalculatePatientTemplate() {
  yield takeLatest(RECALCULATE_PATIENT_TREATMENT_TEMPLATE_REQUEST, workerRecalculatePatientTemplate);
}
