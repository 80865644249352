import { IReduxAction } from "../../utils/interfaces";
import { updateState } from "../../utils/reduxUtils";
import { SET_AUTHENTICATED } from "../actions";

const initialState = {
  isAuthenticated: false,
  unauthorised: false
};

export const authReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {

  if (type === SET_AUTHENTICATED) {
    return updateState(state, payload);
  }

  const matches = /(.*)_(ERROR)/.exec(type);

  if (!matches) return state;

  const errorCode = payload.code;
  if (errorCode === 401) {
    return {
      ...state,
      unauthorised: true
    };
  }
  return initialState;
};
