import { Divider, IconButton } from "@mui/material";
import classes from "./NavigationBar.module.scss";
import { BBLogo } from "../../../assets/icons/BBLogo";
import { User } from "../../../assets/icons/User";
import { Logout } from "../../../assets/icons/Logout";
import { MenuList } from "./MenuList/MenuList";
import { LOGOUT_URL } from "../../../utils/authUtils";

export const NavigationBar = () => {
  const logout = () => {
    sessionStorage.clear();
    window.open(LOGOUT_URL, "_self");
  };
  
  return (
    <div className={classes.navBarContainer}>
      <div className={classes.menu}>
        <BBLogo className={classes.logo} />
        <Divider className={classes.divider} flexItem />
        <MenuList />
      </div>
      <div className={classes.footer}>
        <Divider flexItem />
        <div className={classes.userControls}>
          <div className={classes.user}>
            <span className={classes.userIcon}>
              <User />
            </span>
            <p>Dr.Becker</p>
          </div>
          <IconButton className={classes.logoutButton} onClick={() => logout()}>
            <Logout />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
