import { IReduxAction } from "../../utils/interfaces";
import { updateState } from "../../utils/reduxUtils";
import {
  GET_CLICKING_DATA_SUCCESS,
  MODIFY_CLICKING_DATA_SUCCESS
} from "../actions";

const initialState = {
  clickingData: null
};

export const clickingDataReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case GET_CLICKING_DATA_SUCCESS: {
      return updateState(state, payload);
    }
    case MODIFY_CLICKING_DATA_SUCCESS: {
      return updateState(state, payload);
    }
    default: {
      return state;
    }
  }
};
