import { ISvgIcon } from "../../utils/interfaces";

export const DoubleLeftChevron = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.95 0L8.95001 7L13.95 14H11.5L6.50001 7L11.5 0H13.95ZM8.00001 0L3.00001 7L8.00001 14L5.55001 14L0.550012 7L5.55001 0L8.00001 0Z"
      fill="#158AA4"
    />
  </svg>
);
