import { FormControl, TextField } from "@mui/material";
import { isIncorrectNum } from "../../../../utils/commonUtils";
import { INumericInput } from "../../../../utils/interfaces";

export const NumericInput = ({
  id,
  disabled,
  label,
  labelClassName,
  inputClassName,
  handleChange,
  helperClassName,
  formClassName,
  placeholder,
  value,
  maxLength,
  autoFocus
}: INumericInput) => {
  const isError = isIncorrectNum(value);
  return (
    <FormControl className={formClassName}>
      {label && <p className={labelClassName}>{label}</p>}
      <TextField
        id={id.toString()}
        disabled={disabled}
        autoFocus={autoFocus}
        InputProps={{
          classes: {
            root: inputClassName
          },
          autoComplete: "off",
          inputProps: {
            maxLength
          }
        }}
        FormHelperTextProps={{
          classes: {
            root: helperClassName
          }
        }}
        onBlur={() => {}}
        onChange={event => handleChange(event)}
        placeholder={placeholder}
        value={value || ""}
        error={isError}
        helperText={isError && "Only numeric characters allowed"}
      />
    </FormControl>
  );
};
