import { useState } from "react";
import { Button, Checkbox, FormControlLabel, Menu } from "@mui/material";
import classes from "./ChangeTreatmentPlanPopup.module.scss";
import { IChangeTreatmentPlanPopup } from "../../../../../../../../../utils/interfaces";
import { NumericInput } from "../../../../../../../../common/InputComponents/NumericInput/NumericInput";
import { CheckboxActive } from "../../../../../../../../../assets/icons/CheckboxActive";
import { CheckboxInactive } from "../../../../../../../../../assets/icons/CheckboxInactive";
import { useDispatch } from "react-redux";
import { LEFT, RIGHT } from "../../../../../../../../../utils/constants";
import { MODIFY_CLICKING_DATA_REQUEST } from "../../../../../../../../../redux/actions";
import { format, parse } from "date-fns";

export const ChangeTreatmentPlanPopup = ({
  anchorEl,
  data,
  handleClose,
  patientId,
  side,
  bone,
  chartDates
}: IChangeTreatmentPlanPopup) => {
  const dispatch = useDispatch();
  const [completedClicks, setCompletedClicks] = useState<string | null>(null);
  const [clicksToRemove, setClicksToRemove] = useState<string | null>(null);
  const [shouldApplyForBothLegs, setShouldApplyForBothLegs] = useState<boolean>(
    true
  );
  const [shouldRecalculate, setShouldARecalculate] = useState<boolean>(true);

  const handleSave = () => {
    const date = format(parse(data.date, 'dd/MM/yyyy', new Date()), "yyyy-MM-dd");

    dispatch({
      type: MODIFY_CLICKING_DATA_REQUEST,
      payload: {
        patientId,
        sides: shouldApplyForBothLegs ? [LEFT, RIGHT] : [side.toUpperCase()],
        date,
        clicksToAdd: parseInt(completedClicks || "0"),
        clicksToDelete: parseInt(clicksToRemove || "0"),
        recalculateTreatmentPlan: shouldRecalculate,
        chartDates
      }
    });
    handleClose();
  };

  return (
    <Menu
      id="modify-patients-progress"
      aria-labelledby="modify-patients-progress"
      anchorEl={anchorEl}
      open={true}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: -150,
        horizontal: "right"
      }}
      MenuListProps={{
        classes: { root: classes.menu }
      }}
      PaperProps={{
        classes: { root: classes.popover }
      }}
    >
      <h2>Modifying Patient's Progress</h2>
      <p>Change plan for {side} {bone.toLowerCase()} </p>
      <NumericInput
        id={"completed-clicks"}
        disabled={!!clicksToRemove}
        inputClassName={classes.input}
        label="Completed Clicks"
        labelClassName={classes.inputLabel}
        value={completedClicks}
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setCompletedClicks(event.target.value)
        }
        autoFocus={true}
      />
      <NumericInput
        id={"remove-clicks"}
        disabled={!!completedClicks}
        inputClassName={classes.input}
        label="Remove Сlicks"
        labelClassName={classes.inputLabel}
        value={clicksToRemove}
        handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setClicksToRemove(event.target.value)
        }
      />
      <FormControlLabel
        classes={{ label: classes.checkboxLabel }}
        control={
          <Checkbox
            checked={shouldApplyForBothLegs}
            classes={{ root: classes.checkbox }}
            icon={<CheckboxInactive />}
            checkedIcon={<CheckboxActive />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShouldApplyForBothLegs(event.target.checked)
            }
          />
        }
        label="Apply for both legs"
      />
      <FormControlLabel
        classes={{ label: classes.checkboxLabel }}
        control={
          <Checkbox
            checked={shouldRecalculate}
            classes={{ root: classes.checkbox }}
            icon={<CheckboxInactive />}
            checkedIcon={<CheckboxActive />}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShouldARecalculate(event.target.checked)
            }
          />
        }
        label="Recalculate treatment plan"
      />
      <div className={classes.actions}>
        <Button
          className={classes.action}
          color="secondary"
          onClick={() => handleClose()}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          className={classes.action}
          color={"primary"}
          onClick={() => handleSave()}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Menu>
  );
};
