import { ISvgIcon } from "../../utils/interfaces";

export const Report = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2 18L16 15.2V17.5H17V13.5H13V14.5H15.3L12.5 17.3L13.2 18ZM2 18.5C1.45 18.5 0.979167 18.3042 0.5875 17.9125C0.195833 17.5208 0 17.05 0 16.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H16C16.55 0.5 17.0208 0.695833 17.4125 1.0875C17.8042 1.47917 18 1.95 18 2.5V9.2C17.6833 9.05 17.3583 8.92083 17.025 8.8125C16.6917 8.70417 16.35 8.625 16 8.575V2.5H2V16.5H8.05C8.1 16.8667 8.17917 17.2167 8.2875 17.55C8.39583 17.8833 8.525 18.2 8.675 18.5H2ZM2 15.5V16.5V2.5V8.575V8.5V15.5ZM4 14.5H8.075C8.125 14.15 8.20417 13.8083 8.3125 13.475C8.42083 13.1417 8.54167 12.8167 8.675 12.5H4V14.5ZM4 10.5H10.1C10.6333 10 11.2292 9.58333 11.8875 9.25C12.5458 8.91667 13.25 8.69167 14 8.575V8.5H4V10.5ZM4 6.5H14V4.5H4V6.5ZM15 20.5C13.6167 20.5 12.4375 20.0125 11.4625 19.0375C10.4875 18.0625 10 16.8833 10 15.5C10 14.1167 10.4875 12.9375 11.4625 11.9625C12.4375 10.9875 13.6167 10.5 15 10.5C16.3833 10.5 17.5625 10.9875 18.5375 11.9625C19.5125 12.9375 20 14.1167 20 15.5C20 16.8833 19.5125 18.0625 18.5375 19.0375C17.5625 20.0125 16.3833 20.5 15 20.5Z"
      fill="#0095CC"
    />
  </svg>
);
