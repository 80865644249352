import { call, put, takeLatest } from "redux-saga/effects";
import { PatientsAPI } from "../../../api/patientsAPI";
import { ToastType } from "../../../utils/interfaces";
import {
  DELETE_PATIENT_ERROR,
  DELETE_PATIENT_REQUEST,
  SET_TOAST,
} from "../../actions";

function* workerDeletePatientData(action: {
  type: string;
  payload: {
    patientId: string;
    navigate: Function;
  };
}): Generator<any> {
  try {
    const { navigate } = action.payload;
    yield call(PatientsAPI.deletePatient, action.payload);
    if (navigate) {
      navigate("/patients");
    }
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: "Patient was successfully deleted",
        type: ToastType.SUCCESS,
      }
    });
  } catch (error: any) {
    yield put({
      type: DELETE_PATIENT_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherDeletePatientData() {
  yield takeLatest(DELETE_PATIENT_REQUEST, workerDeletePatientData);
}
