import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isUnauthorisedSelector } from "../../../redux/selectors/authSelector";
import { getIsExpired, LOGOUT_URL, TIMEOUT } from "../../../utils/authUtils";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress"
];

const pathsWhiteList = ["/terms-of-use", "/privacy-policy"];

export const AutoLogout = () => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const unauthorised = useSelector(isUnauthorisedSelector);

  let timer: any;

  const onEvent = () => {
    const isExpired = getIsExpired();

    if (isExpired) {
      logoutAction();
    }
    resetTimer();
    handleLogoutTimer();
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach(item => {
        window.removeEventListener(item, onEvent);
      });

      logoutAction();
    }, TIMEOUT);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (!pathsWhiteList.includes(currentPathname)) {
      Object.values(events).forEach(item => {
        window.addEventListener(item, onEvent);
      });
    }

    return () => {
      if (timer) clearTimeout(timer);
      Object.values(events).forEach(item => {
        window.removeEventListener(item, onEvent);
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!pathsWhiteList.includes(currentPathname)) {
      if (unauthorised) {
        logoutAction();
      } else {
        onEvent();
      }
    }
    // eslint-disable-next-line
  }, [unauthorised, location.pathname, location.search]);

  const logoutAction = () => {
    sessionStorage.clear();
    window.open(LOGOUT_URL, "_self");
  };

  return null;
};
