import { IReduxAction } from "../../utils/interfaces";
import { updateState } from "../../utils/reduxUtils";
import { GET_COUNTRIES_SUCCESS, } from "../actions";

const initialState = {
  countries: null
};

export const countriesReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case GET_COUNTRIES_SUCCESS: {
      return updateState(state, payload);
    }
    default: {
      return state;
    }
  }
};
