import { call, put, takeLatest } from "redux-saga/effects";
import { ClickingDataAPI } from "../../../api/clickingDataAPI";
import {
  GET_CLICKING_DATA_REQUEST,
  MODIFY_CLICKING_DATA_ERROR,
  MODIFY_CLICKING_DATA_REQUEST,
  MODIFY_CLICKING_DATA_SUCCESS
} from "../../actions";

function* workerModifyClickingData(action: {
  type: string;
  payload: {
    patientId: string;
    sides: string[];
    date: string;
    clicksToAdd: number;
    clicksToDelete: number;
    recalculateTreatmentPlan: boolean;
    chartDates: { start: string, end: string };
  };
}): Generator<any> {
  try {
    const {
      patientId,
      sides,
      date,
      clicksToAdd,
      clicksToDelete,
      recalculateTreatmentPlan,
      chartDates
    } = action.payload;

    const data: any = yield call(
      ClickingDataAPI.modifyClickingData,
      patientId,
      sides,
      date,
      clicksToAdd,
      clicksToDelete,
      recalculateTreatmentPlan
    );
    yield put({
      type: MODIFY_CLICKING_DATA_SUCCESS,
      payload: { clickingData: data }
    });
    yield put({
      type: GET_CLICKING_DATA_REQUEST,
      payload: { patientId, dates: chartDates }
    })
  } catch (error: any) {
    yield put({
      type: MODIFY_CLICKING_DATA_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherModifyClickingData() {
  yield takeLatest(MODIFY_CLICKING_DATA_REQUEST, workerModifyClickingData);
}
