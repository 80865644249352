import { getServiceURL, getStatus } from '../utils/apiUtils';
import { apiInstance } from '../utils/axiosConfig';
import {
  ACTIVE,
  ALARMS_TABS,
  SORT,
  SORT_MAPPING,
  SORT_TYPE,
  SORT_TYPE_MAPPING,
} from '../utils/constants';
import { Exercise } from '../types/exercises';
import { ExercisePerformanceRawDataItem } from '../types/exercisePerformance';

export class PatientsAPI {
  static get getAllPatientsServiceName() {
    return 'LIST_OF_PATIENTS';
  }

  static get createEditPatientServiceName() {
    return 'LIST_OF_PATIENTS';
  }

  static get viewPatientServiceName() {
    return 'LIST_OF_PATIENTS';
  }

  static get getCountriesServiceName() {
    return 'LIST_OF_COUNTRIES';
  }

  static get getDeletePatientServiceName() {
    return 'LIST_OF_PATIENTS';
  }

  static get getAlarmsServiceName() {
    return 'LIST_OF_NOTIFICATIONS';
  }

  static get getAlarmsResolveServiceName() {
    return 'RESOLVE_NOTIFICATION';
  }

  static get recalculateTemplateServiceName() {
    return 'APPLY_TREATMENT_PLAN';
  }

  static async saveTemplate({ data }: { data: any }) {
    const url = `${getServiceURL(PatientsAPI.recalculateTemplateServiceName)}`;
    const response = await apiInstance().put(url, data);
    return response?.data;
  }

  static async recalculateTemplate({ data }: { data: any }) {
    const url = `${getServiceURL(PatientsAPI.recalculateTemplateServiceName)}/draft`;
    const response = await apiInstance().put(url, data);
    return response?.data;
  }

  static async resolveAlarm({ alarmId }: { alarmId: number }) {
    const url = `${getServiceURL(PatientsAPI.getAlarmsResolveServiceName)}/${alarmId}`;
    const response = await apiInstance().put(url);
    return response?.data;
  }

  static async getAlarms({ type, patientId }: { type: ALARMS_TABS; patientId: string }) {
    const url = getServiceURL(PatientsAPI.getAlarmsServiceName);
    const params = {
      patientId,
      ...(type && type !== ALARMS_TABS.ALL ? { status: type } : {}),
    };
    const response = await apiInstance().get(url, { params });
    return response?.data;
  }

  static async getAllPatients({
    size = 8,
    searchText = '',
    page = 1,
    status = ACTIVE,
    sort = { value: '', type: SORT_TYPE.ASC },
  }) {
    const url = getServiceURL(PatientsAPI.getAllPatientsServiceName);
    const statuses = getStatus(status);
    const params = {
      statuses,
      ...(page ? { page: page - 1 } : {}),
      ...(size ? { size } : {}),
      ...(searchText ? { searchText } : {}),
      ...(sort.value
        ? {
            sort: `${SORT_MAPPING[sort.value as SORT]},${
              SORT_TYPE_MAPPING[sort.type as SORT_TYPE]
            }`,
          }
        : {}),
    };

    const response = await apiInstance().get(url, { params });
    return response?.data;
  }

  static async deletePatient(data: { patientId: string }) {
    const url = `${getServiceURL(PatientsAPI.getDeletePatientServiceName)}/${data.patientId}`;
    const response = await apiInstance().delete(url);
    return response?.data;
  }

  static async createPatient(data: any) {
    const url = getServiceURL(PatientsAPI.createEditPatientServiceName);
    const response = await apiInstance().post(url, data);
    return response?.data;
  }

  static async editPatient(data: any, id: string) {
    const url = getServiceURL(PatientsAPI.createEditPatientServiceName);
    const response = await apiInstance().put(url + `/${data.id}`, data);
    return response?.data;
  }

  static async getSinglePatient(id: string) {
    const url = `${getServiceURL(PatientsAPI.viewPatientServiceName)}/${id}`;
    const response = await apiInstance().get(url);
    return response?.data;
  }

  static async getCountries() {
    const url = getServiceURL(PatientsAPI.getCountriesServiceName);
    const response = await apiInstance().get(url);
    return response?.data;
  }

  static async changeExercisesActiveStatus({ id, isActive }: { id: string; isActive: boolean }) {
    const res = await apiInstance().put(`/patients/${id}/exercises/activate?active=${isActive}`);
    return res.data;
  }

  static async getPatientExercises({ id }: { id: string }): Promise<Exercise[]> {
    const res = await apiInstance().get(`/patients/${id}/exercises`);
    return res.data;
  }

  static async assignExercises({ id, exerciseIds }: { id: string; exerciseIds: number[] }) {
    await apiInstance().post(`/patients/${id}/exercises`, exerciseIds);
  }

  static async setExerciseDuration({ id, totalMinutes }: { id: string; totalMinutes: number }) {
    await apiInstance().put(`/patients/${id}/exercises/daily-time-plan?minutes=${totalMinutes}`);
  }

  static async getExercisePerformance({
    id,
    start,
    end,
  }: {
    id: string;
    start: string;
    end: string;
  }) {
    const res = await apiInstance().get(`/patients/${id}/exercises/log?from=${start}&to=${end}`);
    return res.data as ExercisePerformanceRawDataItem[];
  }
}
