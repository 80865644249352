import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { GET_EXERCISE_PERFORMANCE_DATA } from '../../../../../redux/actions';
import {
  getCurrentPatient,
  getExercisePerformanceData,
} from '../../../../../redux/selectors/patientsSelector';
import { PeriodToggle } from '../ClickingOverview/components/PeriodToggle/PeriodToggle';
import { ResponsiveContainer } from 'recharts';
import classes from '../ClickingOverview/ClickingOverview.module.scss';
import {
  getTotalTimePerDate,
  normalizeData,
} from '../../../../../utils/performanceDataNormalization';
import { PerformanceChart } from './PerformanceChart';
import { Legend } from '../ClickingOverview/components/Legend/Legend';
import { eachDayOfInterval, format } from 'date-fns';

const legendData = [
  [
    {
      label: 'Pelvic movements',
      color: '#E9E003',
    },
    {
      label: 'Hip Flexors and Thighs Front',
      color: '#30B9DA',
    },
  ],
  [
    {
      label: 'Lateral Thighs and Buttocks',
      color: '#5078FB',
    },
    {
      label: 'Back of Thighs',
      color: '#C19CFC',
    },
  ],
  [
    {
      label: 'Additionals',
      color: '#FFAC66',
    },
  ],
  [
    {
      label: 'No data',
      color: '#EFF0F6',
      border: '#A0A3BD',
    },
    {
      label: 'Offline',
      color: '#EFF0F6',
      additionalStyles: {
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: '#A0A3BD',
      },
    },
  ],
];

export const ExercisePerformanceChart = () => {
  const dispatch = useDispatch();

  const [dates, setDates] = useState<{
    start: string;
    end: string;
  }>();

  const exercisePerformanceRawData = useSelector(getExercisePerformanceData);
  const currentPatient = useSelector(getCurrentPatient);

  const [normalizedData, totalTimePerDateData] = useMemo(() => {
    if (!exercisePerformanceRawData?.length) {
      return [];
    }

    const nData = normalizeData(exercisePerformanceRawData);
    const totalTimeDate = getTotalTimePerDate(nData);

    // get all dates in selected interval
    const datesInRange =
      dates?.end && dates?.start
        ? eachDayOfInterval({
            start: new Date(dates.start),
            end: new Date(dates.end),
          })
        : [];

    // additional data to show all dates even if there is no info during this date
    const additionalChartData = datesInRange.map((date) => ({
      date: format(date, 'dd/MM/yy'),
      hours: 0,
      hoursLabel: '0 hrs',
      totalTime: 0,
      type: 'datesMock',
    }));

    // if there is no item with date in range, we add a mock item with these dates to show all the dates on chart
    for (let i = 0; i < additionalChartData.length; i++) {
      const additionalDataItem = additionalChartData[i];
      const dateExists = totalTimeDate.find((item) => additionalDataItem.date === item.date);

      if (!dateExists) {
        totalTimeDate.push(additionalDataItem);
      }
    }

    return [nData, totalTimeDate];
  }, [exercisePerformanceRawData]);

  useEffect(() => {
    if (dates && currentPatient?.id) {
      dispatch({
        type: GET_EXERCISE_PERFORMANCE_DATA,
        payload: { id: currentPatient.id, dates },
      });
    }
  }, [dates, currentPatient?.id]);

  return (
    <div>
      <div className={classes.clickingOverviewContainer}>
        <div className={classes.chartHeader}>
          <PeriodToggle setDates={setDates} />
          <Legend legendData={legendData} />
        </div>
        {normalizedData && totalTimePerDateData && (
          <ResponsiveContainer width="100%" height="80%">
            <PerformanceChart
              dailyExercisePlan={currentPatient?.dailyExercisePlan}
              normalizedData={normalizedData}
              totalTimePerDateData={totalTimePerDateData}
            />
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
