import { FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { sub } from "date-fns";
import { IDateField } from "../../../../utils/interfaces";

export const DateField = ({
    id,
    error,
    label,
    placeholder,
    required,
    inputClassName,
    helperClassName,
    formClassName,
    labelClassName,
    formik,
    setError
  }: IDateField) => {
  const minAllowedDate = sub(new Date(), { years: 81 });
  const maxAllowedDate = sub(new Date(), { years: 16 });

  return <FormControl className={formClassName}>
  <>
    <p className={labelClassName}>{label}</p>{required && <span>*</span>}
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className={inputClassName}
        format="dd.MM.yyyy"
        minDate={minAllowedDate}
        {...(id === 'dateOfBirth' ? {maxDate: maxAllowedDate} : {})}
        onError={(newError) => setError(newError)}
        onChange={(newValue) => formik.setFieldValue(id, Date.parse(newValue))}
        slotProps={{
          textField: {
            id,
            name: id,
            placeholder,
            variant: "outlined",
            error: Boolean(error),
            helperText: error && "Your date is not valid",
            FormHelperTextProps: {
              classes: {
                root: helperClassName
              }
            }
          }
        }}
        value={formik.values[id]}
      />
    </LocalizationProvider>
  </>
</FormControl>
}