import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import classes from "./CreateEditPatient.module.scss";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { CREATE, EDIT } from "../../../utils/constants";
import { CreateEditPatientControls } from "./components/CreateEditPatientControls/CreateEditPatientControls";
import { ApplyTreatmentPlan } from "../../common/ApplyTreatmentPlan/ApplyTreatmentPlan";
import { PatientInfo } from "./components/PatientInfo/PatientInfo";
import {
  CREATE_PATIENT_REQUEST,
  EDIT_PATIENT_REQUEST,
  GET_COUNTRIES_REQUEST,
  GET_PATIENT_REQUEST,
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_REQUEST,
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS
} from "../../../redux/actions";
import { createPatientErrorSelector, currentPatientSelector, editPatientErrorSelector } from "../../../redux/selectors/patientsSelector";
import { Modal } from "../../common/Modal/Modal";
import {
  requiredFieldsPatient,
  validationSchemaPatient,
  initialPatientValues
} from "../../../utils/inputUtils";
import {
  formPatientObjectForCreation,
  formTreatmentPlanForUpdate,
  transformCurrentPatientData,
  transformPatientTemplatePeriods
} from "../../../utils/dataHandlers";
import { countriesSelector } from "../../../redux/selectors/countriesSelector";
import { ICreateEditView, ITreatmentPlanPeriod, PatientDataUniqueType } from "../../../utils/interfaces";
import { cmToMm } from "../../../utils/commonUtils";
import { format } from "date-fns";
import { EditPatientTreatmentPlan } from "./components/EditTreatmentPlan/EditPatientTreatmentPlan";
import { patientTemplateSelector } from "../../../redux/selectors/treatmentPlansSelector";

export const CreateEditPatient = ({ mode }: ICreateEditView) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [errorDate, setErrorDate] = useState<string | null>(null);
  const [applyTreatPlan, setApplyTreatPlan] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const patientTreatmentPlan = useSelector(patientTemplateSelector);
  const hasChanged = useRef(false);
  const createPatientError = useSelector(createPatientErrorSelector);
  const editPatientError = useSelector(editPatientErrorSelector);
  const id = location.pathname.split("/").pop() || "";
  const currentPatient = useSelector(currentPatientSelector(id));

  const initialValues =
    mode === EDIT && currentPatient
      ? transformCurrentPatientData(currentPatient)
      : initialPatientValues;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchemaPatient,
    onSubmit: values => {
      const data = formPatientObjectForCreation(values);
      submitForm(data);
    }
  });

  useEffect(() => {
    const errorMapping: Record<PatientDataUniqueType, string> = {
      [PatientDataUniqueType.Username]: "login",
      [PatientDataUniqueType.ExternalID]: "externalId",
      [PatientDataUniqueType.Email]: "email",
      [PatientDataUniqueType.Phone]: "phone",
    }
    const errorField: PatientDataUniqueType = createPatientError.split(" ")[1];
    const editErrorField: PatientDataUniqueType = editPatientError.split(" ")[1];

    const errorFieldName: PatientDataUniqueType = errorField || editErrorField;
    if (errorFieldName) {
      formik.setFieldError(errorMapping[errorFieldName], "This field should be unique");
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPatientError, editPatientError]);

  const [periods, setPeriods] = useState({});
  const [calculatedData, setCalculatedData] = useState({
    totalClicks: null,
    duration: null,
    totalDistractionMm: null
  });

  const countries = useSelector(countriesSelector) || [];

  useEffect(() => {
    if (currentPatient?.treatmentPlan && !hasChanged.current) {
      dispatch({
        type: RECALCULATE_PATIENT_TREATMENT_TEMPLATE_SUCCESS,
        payload: {
          patientPlan: null,
        }
      })
      setCalculatedData({
        totalClicks: currentPatient.treatmentPlan.totalClicks,
        duration: currentPatient.treatmentPlan.duration,
        totalDistractionMm: currentPatient.treatmentPlan.totalDistractionMm
      });
      setPeriods(transformPatientTemplatePeriods(currentPatient.treatmentPlan.periods));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatient]);

  useEffect(() => {
    if (patientTreatmentPlan && hasChanged.current) {
      setPeriods(transformPatientTemplatePeriods(patientTreatmentPlan.periods));
      setCalculatedData({
        totalClicks: patientTreatmentPlan.totalClicks,
        duration: patientTreatmentPlan.duration,
        totalDistractionMm: patientTreatmentPlan.totalDistractionMm
      });
    }
  }, [patientTreatmentPlan]);

  useEffect(() => {
    if (mode === EDIT) {
      dispatch({
        type: GET_PATIENT_REQUEST,
        payload: { id }
      });
    }
    dispatch({
      type: GET_COUNTRIES_REQUEST
    });
  }, [dispatch, id, location.pathname, mode]);

  useEffect(() => {
    if (mode === CREATE) {
      setApplyTreatPlan(true);
    } else if (currentPatient) {
      const treatmentPlan = currentPatient.treatmentPlan;
      if (!treatmentPlan) setApplyTreatPlan(true);
    }
  }, [currentPatient, dispatch, mode]);

  const submitDisabled = useMemo(() => {
    if (!formik.values) return true;
    if (mode === EDIT) return false;
    const treatPlanSelected =
      Boolean(selectedPlan && startDate) ||
      Boolean(!selectedPlan && !startDate);
    const unfilledFelds = requiredFieldsPatient.filter(fieldId => {
      if (formik.values[fieldId]?.length) return false;

      return !formik.values[fieldId]?.length || !formik.touched[fieldId];
    });
    return (
      unfilledFelds.length > 0 ||
      Object.keys(formik.errors).length > 0 ||
      !treatPlanSelected ||
      !!errorDate
    );
  }, [
    errorDate,
    formik.errors,
    formik.touched,
    formik.values,
    mode,
    selectedPlan,
    startDate
  ]);

  const goToPatients = () => navigate("/patients");

  const closePopup = () => {
    setOpen(false);
  };

  const recalculateTemplate = useCallback((newPeriods: Record<number, [ITreatmentPlanPeriod, ITreatmentPlanPeriod]>) => {
    const newPeriodValues = Object.values(newPeriods);
    const hasEmptyValues = newPeriodValues.some(periodValues => {
      return periodValues.some(value => !value.clicksPerDay || !value.distractionMm);
    })
    if (!hasEmptyValues) {
      const data = formTreatmentPlanForUpdate(newPeriods);
      dispatch({
        type: RECALCULATE_PATIENT_TREATMENT_TEMPLATE_REQUEST,
        payload: { data: { id: currentPatient?.treatmentPlan.id, periods: data } }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, currentPatient?.treatmentPlan, id]);

  const handleBack = () => {
    if (mode === EDIT) {
      goToPatients();
      return;
    }
    handleBackAndCancelForCreate();
  };

  const handleCancel = () => {
    if (mode === EDIT) {
      navigate(`/patients/view/${id}`);
      return;
    }
    handleBackAndCancelForCreate();
  };

  const handleBackAndCancelForCreate = () => {
    if (JSON.stringify(initialValues) === JSON.stringify(formik.values)) {
      goToPatients();
    } else {
      setOpen(true);
    }
  };

  const submitForm = (values: any) => {
    const applyPlanData = selectedPlan &&
      startDate ? {
        startDate: format(startDate, "yyyy-MM-dd"),
        templateId: selectedPlan,
        patientId: id ? id : null
      } : null;

    if (mode === EDIT) {
      dispatch({
        type: EDIT_PATIENT_REQUEST,
        payload: {
          patientData: values,
          ...(applyPlanData ? { applyPlanData } : {}),
          navigate,
          ...(hasChanged.current ? {
            periods: formTreatmentPlanForUpdate(periods),
            id: currentPatient?.treatmentPlan?.id
          } : {})
        }
      });
    } else {
      dispatch({
        type: CREATE_PATIENT_REQUEST,
        payload: {
          patientData: values,
          ...(applyPlanData ? { applyPlanData } : {}),
          navigate
        }
      });
    }
  };

  const handleSetPeriods = (newPeriods: any) => {
    setPeriods(newPeriods);
    if (!hasChanged.current) hasChanged.current = true;
  }

  return (
    <FullPageContainer pageClassName={classes.createEditPatientContainer}>
      <>
        <CreateEditPatientControls
          navigateBack={handleBack}
          handleCancel={handleCancel}
          handleSave={formik.handleSubmit}
          submitDisabled={submitDisabled}
        />
        <PatientInfo
          errorDate={errorDate}
          countries={countries}
          formik={formik}
          setErrorDate={setErrorDate}
          mode={mode}
          hasTreatmentPlan={!!currentPatient?.treatmentPlan}
        />
        {!applyTreatPlan && currentPatient?.treatmentPlan
          ? <EditPatientTreatmentPlan
              periods={periods}
              setPeriods={handleSetPeriods}
              treatmentPlanData={{
                name: 'Treatment plan',
                distraction: calculatedData.totalDistractionMm,
                duration: calculatedData.duration,
                clicks: calculatedData.totalClicks,
              }}
              recalculateTemplate={recalculateTemplate} />
          : null}
        {applyTreatPlan && (
          <ApplyTreatmentPlan
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            startDate={startDate}
            setStartDate={setStartDate}
            operationType={formik.values.operationType}
            nailTypeMm={cmToMm(formik.values.nailTypeMm)}
            initialGapMm={cmToMm(formik.values.initialGapMm)}
          />
        )}
        <Modal
          open={open}
          title={"Cancel changes"}
          message={
            "Are you sure that you want to cancel creating the patient's account? The data you've entered won't be saved."
          }
          handleCancel={closePopup}
          handleConfirm={goToPatients}
        />
      </>
    </FullPageContainer>
  );
};
