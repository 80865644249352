import { ISvgIcon } from "../../utils/interfaces";

export const LeftFemur = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="5"
    height="12"
    viewBox="0 0 5 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.45052 1.85808C4.46423 0.810487 3.59927 -0.0358005 2.55223 0.000763145C1.38306 0.0415912 0.545949 1.15254 0.78543 2.29765C1.02151 3.42647 1.26972 4.78317 1.3589 5.85645C1.50646 7.63232 1.71914 10.1971 1.80347 11.2142C1.82391 11.4608 2.03429 11.6469 2.28154 11.6382C2.43407 11.6329 2.57421 11.5528 2.65625 11.4241L2.67223 11.3991C2.85733 11.1087 3.2751 11.0915 3.48339 11.3658L3.49237 11.3776C3.58397 11.4982 3.72845 11.5668 3.87979 11.5615C4.12835 11.5528 4.3263 11.3505 4.32955 11.1018L4.45052 1.85808Z"
      fill="#D9D9D9"
    />
  </svg>
);
