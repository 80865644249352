import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { mmToCm } from "../../../../../utils/commonUtils";
import { ITreatmentPlanPeriod } from "../../../../../utils/interfaces";
import classes from "./ViewTreatmentTimeline.module.scss";

export const ViewTreatmentTimeline = ({
  periods
}: {
  periods: ITreatmentPlanPeriod[];
}) => {
  return (
    <div className={classes.treatmentTimelineContainer}>
      <h2>Treatment Timeline</h2>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="left">Period</TableCell>
              <TableCell align="left">Cm for Period</TableCell>
              <TableCell align="left">Clicks Per Day</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Amount of Clicks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {periods &&
              periods.map(
                ({
                  id,
                  distractionMm,
                  clicksPerDay,
                  duration,
                  totalClicks,
                  sortOrder
                }: ITreatmentPlanPeriod) => {
                  return (
                    <TableRow className={classes.tableRow} key={id}>
                      <TableCell align="left">{`Period ${sortOrder}`}</TableCell>
                      <TableCell align="left">
                        {mmToCm(distractionMm)} cm
                      </TableCell>
                      <TableCell align="left">{clicksPerDay}</TableCell>
                      <TableCell align="left">{duration}</TableCell>
                      <TableCell align="left">{totalClicks}</TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
