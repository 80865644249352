import { useNavigate } from 'react-router-dom';
import {
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Box,
} from '@mui/material';
import classes from './PatientsTable.module.scss';
import { a11yProps, mmToCm } from '../../../../../utils/commonUtils';
import { RightChevron } from '../../../../../assets/icons/RightChevron';
import { UsePagination } from '../../../../common/Pagination/Pagination';
import { IPatientsData, IPatientsTable, SORT_SVG, TABS } from '../../../../../utils/interfaces';
import { Status } from '../../../../common/Status/Status';
import { OperationType } from '../../../../common/OperationType/OperationType';
import { Progress } from '../../../../common/Progress/Progress';
import { Alarms } from './Alarms/Alarms';
import { bonesMap, patientsTableTabs, SORT, SORT_TYPE } from '../../../../../utils/constants';
import { NoDataPage } from '../../../../common/NoDataPage/NoDataPage';
import { Sort } from '../../../../../assets/icons/Sort';
import { SET_TABLE_STATE } from '../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { getTableState } from '../../../../../redux/selectors/patientsSelector';

const getSortIconType = (sortValue: SORT, sortType: SORT_TYPE, currentValue: SORT) => {
  if (sortValue && sortValue === currentValue) {
    return sortType === SORT_TYPE.ASC ? SORT_SVG.ASC : SORT_SVG.DESC;
  }

  return SORT_SVG.DEFAULT;
};

interface ISortIcon {
  text: string;
  handler: (value: SORT) => void;
  currentSortValue: SORT;
  sortValue: SORT;
  sortType: SORT_TYPE;
}

const SortIcon: React.FC<ISortIcon> = ({
  text,
  handler,
  currentSortValue,
  sortType,
  sortValue,
}) => {
  const handleClick = () => {
    handler(currentSortValue);
  };

  return (
    // @ts-ignore
    <Box onClick={handleClick}>
      {text}
      <Sort type={getSortIconType(sortValue, sortType, currentSortValue)} />
    </Box>
  );
};

export const PatientsTable = ({
  data,
  handleChangeTab,
  handlePagination,
  tab,
  totalPages,
  currentPage,
  handleSorting,
  sort,
  totalActive,
  totalFinished,
}: IPatientsTable) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tableRef = useRef<HTMLTableElement | null>(null);

  const { tableYScrollPosition } = useSelector(getTableState);

  useEffect(() => {
    tableRef?.current?.scrollTo(0, tableYScrollPosition);
  }, [tableRef.current, tableYScrollPosition]);

  const showNewPage = (val: number) => {
    handlePagination(val);
  };

  const viewPatientsAccount = (id: string) => {
    dispatch({
      type: SET_TABLE_STATE,
      payload: { tableYScrollPosition: tableRef.current?.scrollTop },
    });

    navigate(`/patients/view/${id}`);
  };

  const navigateToAlarms = (id: string) => {
    navigate(`/patients/view/${id}?open-alarms`);
  };

  // const { totalActive, totalFinished } = data;
  // const totalActive = 100;
  // const totalFinished = 200;
  //
  // console.log(data);

  return (
    <div className={classes.container} ref={tableRef}>
      <Tabs
        aria-label="Patients table tabs"
        classes={{ root: classes.tabs, flexContainer: classes.flexContainer }}
        onChange={(_: React.SyntheticEvent, newValue: string) => handleChangeTab(newValue)}
        value={tab}>
        {patientsTableTabs.map(({ id, label }, index) => (
          <Tab
            classes={{ root: classes.tab }}
            key={id}
            // label={`${label} ${
            //   id === TABS.finished
            //     ? totalFinished
            //     : id === TABS.active
            //     ? totalActive
            //     : totalActive + totalFinished
            // }`}
            label={
              <span>
                {label}&nbsp;
                <span className={tab === id ? classes.counter : classes.disabledCounter}>
                  {id === TABS.finished
                    ? totalFinished
                    : id === TABS.active
                    ? totalActive
                    : totalActive + totalFinished}
                </span>
              </span>
            }
            {...a11yProps(index)}
            value={id}
          />
        ))}
      </Tabs>
      {data && data.length ? (
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell style={{ width: '15%' }} align="left">
                <SortIcon
                  handler={handleSorting}
                  text="Name"
                  sortValue={sort.value}
                  sortType={sort.type}
                  currentSortValue={SORT.NAME}
                />
              </TableCell>
              <TableCell style={{ width: '8%' }} align="left">
                <SortIcon
                  handler={handleSorting}
                  text="Bone"
                  sortValue={sort.value}
                  sortType={sort.type}
                  currentSortValue={SORT.BONE}
                />
              </TableCell>
              <TableCell style={{ width: '8%' }} align="left">
                Pain Level
              </TableCell>
              <TableCell style={{ width: '8%' }} align="left">
                <SortIcon
                  handler={handleSorting}
                  text="Distraction"
                  sortValue={sort.value}
                  sortType={sort.type}
                  currentSortValue={SORT.DISTRACTION}
                />
              </TableCell>
              <TableCell style={{ width: '8%' }} align="left">
                Clicks day before
              </TableCell>
              <TableCell style={{ width: '8%' }} align="left">
                Clicks for today
              </TableCell>
              <TableCell style={{ width: '15%' }} align="left">
                <SortIcon
                  handler={handleSorting}
                  text="Progress"
                  sortValue={sort.value}
                  sortType={sort.type}
                  currentSortValue={SORT.PROGRESS}
                />
              </TableCell>
              <TableCell style={{ width: '10%' }} align="left">
                <SortIcon
                  handler={handleSorting}
                  text="Alarms"
                  sortValue={sort.value}
                  sortType={sort.type}
                  currentSortValue={SORT.ALARMS}
                />
              </TableCell>
              <TableCell style={{ width: '10%' }} align="left">
                Status
              </TableCell>
              <TableCell style={{ width: '10%' }} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {data &&
              data.map(
                (
                  {
                    id,
                    activeNotificationCount,
                    firstName,
                    lastName,
                    currentTreatmentDay,
                    treatmentDuration,
                    leftClicksToday,
                    leftClicksYesterday,
                    leftPainLevel,
                    rightClicksToday,
                    rightClicksYesterday,
                    rightPainLevel,
                    plannedLeftClicksToday,
                    plannedLeftClicksYesterday,
                    plannedRightClicksToday,
                    plannedRightClicksYesterday,
                    bone,
                    side,
                    status,
                    totalDistraction,
                  }: IPatientsData,
                  index: number,
                ) => {
                  return (
                    <TableRow className={classes.tableRow} key={index}>
                      <TableCell align="left">
                        {firstName} {lastName}
                      </TableCell>
                      <TableCell align="left">
                        <OperationType bone={bone} side={side} />
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          {!leftPainLevel && !rightPainLevel && '-'}
                          {leftPainLevel && (
                            <>
                              L {bonesMap[bone]} - {leftPainLevel} lvl
                              <br />
                            </>
                          )}
                          {rightPainLevel && (
                            <>
                              R {bonesMap[bone]} - {rightPainLevel} lvl
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">{mmToCm(totalDistraction)} cm</TableCell>
                      <TableCell align="left">
                        {!plannedLeftClicksYesterday && !plannedRightClicksYesterday && '-'}
                        <div>
                          {plannedLeftClicksYesterday && (
                            <>
                              L {bonesMap[bone]} - {leftClicksYesterday || '-'}/
                              {plannedLeftClicksYesterday}
                              <br />
                            </>
                          )}
                          {plannedRightClicksYesterday && (
                            <>
                              R {bonesMap[bone]} - {rightClicksYesterday || '-'}/
                              {plannedRightClicksYesterday}
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {!plannedLeftClicksToday && !plannedRightClicksToday && '-'}
                        <div>
                          {plannedLeftClicksToday && (
                            <>
                              L {bonesMap[bone]} - {leftClicksToday}/{plannedLeftClicksToday}
                              <br />
                            </>
                          )}
                          {plannedRightClicksToday && (
                            <>
                              R {bonesMap[bone]} - {rightClicksToday}/{plannedRightClicksToday}
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {treatmentDuration ? (
                          <Progress progress={currentTreatmentDay} overall={treatmentDuration} />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Alarms
                          number={activeNotificationCount}
                          navigateToAlarms={() => navigateToAlarms(id)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Status className={classes.statusBadge} status={status} />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          onClick={() => viewPatientsAccount(id)}
                          startIcon={<RightChevron />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
          </TableBody>
        </Table>
      ) : (
        <NoDataPage
          message={
            tab.toLowerCase() === TABS.finished
              ? 'You don’t have any finished patients in a platform'
              : 'You don’t have any patients in a platform'
          }
          description={
            tab.toLowerCase() !== TABS.finished ? 'Please, add a patient to the list' : ''
          }
        />
      )}
      {totalPages > 1 && (
        <UsePagination count={totalPages} currentPage={currentPage} showNewPage={showNewPage} />
      )}
    </div>
  );
};
