import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentPatient } from '../../../../../../../redux/selectors/patientsSelector';
import { SET_EXERCISE_DURATION } from '../../../../../../../redux/actions';
import styles from '../../../../../../common/commonStyles.module.scss';
import classes from './ExerciseDuration.module.scss';

const validateTime = (time: { hours: number; minutes: number }) => {
  let hours = time.hours;
  let minutes = time.minutes;

  if (hours < 0) {
    hours = 0;
  } else if (hours > 8) {
    hours = 8;
  }

  if (minutes < 0) {
    minutes = 0;
  } else if (minutes > 59) {
    minutes = 59;
  }

  if (hours === 0 && minutes === 0) {
    minutes = 1;
  }

  if (hours === 8 && minutes > 0) {
    minutes = 0;
  }

  return { hours, minutes };
};

type Props = {
  initialDuration: number;
  disabled: boolean;
};

export const ExerciseDuration = ({ initialDuration, disabled }: Props) => {
  const [hours, setHours] = useState(4);
  const [minutes, setMinutes] = useState(30);
  const [touched, setTouched] = useState(false);

  const dispatch = useDispatch();

  const currentPatient = useSelector(getCurrentPatient);

  useEffect(() => {
    const assignedMinutes = initialDuration % 60;
    const assignedHours = (initialDuration - assignedMinutes) / 60;

    setMinutes(assignedMinutes);
    setHours(assignedHours);
  }, [initialDuration]);

  const setExerciseDuration = (totalMinutes: number) =>
    dispatch({
      type: SET_EXERCISE_DURATION,
      payload: {
        id: currentPatient.id,
        totalMinutes,
      },
    });

  useEffect(() => {
    if (!touched) {
      return;
    }

    const totalMinutes = hours * 60 + minutes;

    const timeoutId = setTimeout(() => setExerciseDuration(totalMinutes), 1500);

    return () => clearTimeout(timeoutId);
  }, [hours, minutes]);

  const changeTime = ({ newHours = hours, newMinutes = minutes }) => {
    setTouched(true);

    const validatedTime = validateTime({ hours: newHours, minutes: newMinutes });

    setHours(validatedTime.hours);
    setMinutes(validatedTime.minutes);
  };

  const handleHoursChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    changeTime({ newHours: +value });
  };

  const handleMinutesChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    changeTime({ newMinutes: +value });
  };

  return (
    <div className={classes.timeContainer}>
      <div className={classes.timeInput}>
        <input
          disabled={disabled}
          type="number"
          value={String(hours)}
          onChange={handleHoursChange}
          placeholder="4"
        />
        <span className={styles.text}>h</span>
      </div>
      <span className={styles.text}>:</span>
      <div className={classes.timeInput}>
        <input
          disabled={disabled}
          type="number"
          value={String(minutes)}
          onChange={handleMinutesChange}
          placeholder="30"
        />
        <span className={styles.text}>m</span>
      </div>
    </div>
  );
};
