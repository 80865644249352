import { Divider } from "@mui/material";
import { format } from "date-fns";
import { genderMap } from "../../../../../utils/constants";
import { IViewPatientInfo } from "../../../../../utils/interfaces";
import { OperationType } from "../../../../common/OperationType/OperationType";
import { Progress } from "../../../../common/Progress/Progress";
import { Status } from "../../../../common/Status/Status";
import { TextData } from "../../../../common/TextData/TextData";
import classes from "./ViewPatientInfo.module.scss";

export const ViewPatientInfo = ({
  patient,
  treatmentData
}: {
  patient: IViewPatientInfo;
  treatmentData: any;
}) => {
  const {
    country,
    dateOfBirth,
    email,
    firstName,
    gender,
    lastName,
    phone,
    preferredName,
    operationType,
    initialGapMm,
    initialHeightMm,
    nailTypeMm,
    leftFemurLengthMm,
    rightFemurLengthMm,
    leftTibiaLengthMm,
    rightTibiaLengthMm,
    targetDistractionMm,
    wingSpanMm,
    surgeryDate,
    status
  } = patient || {};
  const {
    currentDistractionMm,
    currentTreatmentDay,
    duration,
    currentHeightMm,
    totalClicks,
    finishedClicks
  } = treatmentData || {};
  return (
    <div className={classes.patientInfoContainer}>
      <div className={classes.personalInfo}>
        <h2>Personal Data</h2>
        <div className={classes.columns}>
          <div className={classes.column}>
            <TextData
              custom
              name="Full name"
              value={
                <div className={classes.fullName}>
                  {firstName} <br /> {lastName}
                </div>
              }
            />
            <TextData name="Prefered name" value={preferredName} />
            <Divider flexItem />
            <TextData name="Country" value={country} />
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.column}>
            <TextData name="Phone number" value={phone} />
            <TextData
              custom
              name="E-mail"
              value={<p className={classes.textBlock}>{email}</p>}
            />
            <TextData
              name="Date of birth"
              value={
                dateOfBirth ? format(new Date(dateOfBirth), "d MMMM yyyy") : "-"
              }
            />
            <TextData name="Gender" value={genderMap[gender]} />
          </div>
        </div>
      </div>
      <div className={classes.treatmentInfo}>
        <h2>Treatment Data</h2>
        <div className={classes.rows}>
          <div className={classes.row}>
            <TextData
              custom
              name="Operation Type"
              value={<OperationType operationType={operationType} />}
            />
            <TextData
              custom
              name="Progress"
              value={
                <Progress
                  progress={currentTreatmentDay}
                  overall={duration}
                />
              }
            />
            <TextData
              custom
              name="Status"
              value={
                <Status
                  className={classes.statusBadge}
                  status={status}
                />
              }
            />
            <TextData name="Nail type" value={nailTypeMm} />
            <TextData name="Distraction" value={currentDistractionMm} />
          </div>
          <Divider flexItem />
          <div className={classes.row}>
            <TextData name="Initial Height" value={initialHeightMm} />
            <TextData name="Current Height" value={currentHeightMm} />
            <TextData name="Target Distraction" value={targetDistractionMm} />
            <TextData name="Wingspan" value={wingSpanMm} />
            <TextData name="Initial Gap" value={initialGapMm} />
          </div>
          <Divider flexItem />
          <div className={classes.row}>
            <TextData name="Left Femur" value={leftFemurLengthMm} />
            <TextData name="Right Femur" value={rightFemurLengthMm} />
            <TextData name="Left Tibia" value={leftTibiaLengthMm} />
            <TextData name="Right Tibia" value={rightTibiaLengthMm} />
            <TextData
              custom
              name="Planned / Finished Clicks"
              value={
                <p>
                  {totalClicks ?? "-"} / {finishedClicks ?? "-"}
                </p>
              }
            />
            <TextData
              name="Date of surgery"
              value={
                surgeryDate ? format(new Date(surgeryDate), "d MMMM yyyy") : "-"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
