import { call, put, takeLatest } from "redux-saga/effects";

import { PatientsAPI } from "../../../api/patientsAPI";
import {
  RESOLVE_ALARMS_ERROR,
  RESOLVE_ALARMS_SUCCESS,
  RESOLVE_ALARMS_REQUEST
} from "../../actions";

function* workerResolveAlarm(action: {
  type: string;
  payload: {
    alarmId: number;
    isDelete: boolean;
  };
}): Generator<any> {
  try {
    const { isDelete } = action.payload;
    const data: any = yield call(PatientsAPI.resolveAlarm, action.payload);

    yield put({
      type: RESOLVE_ALARMS_SUCCESS,
      payload: { alarm: data, isDelete },
    });
  } catch (error: any) {
    yield put({
      type: RESOLVE_ALARMS_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherResolveAlarm() {
  yield takeLatest(RESOLVE_ALARMS_REQUEST, workerResolveAlarm);
}
