import { Button, IconButton } from "@mui/material";
import classes from "./ViewTreatmentTemplateControls.module.scss";
import { LeftChevron } from "../../../../../assets/icons/LeftChevron";
import { ITemplateControls } from "../../../../../utils/interfaces";
import { Edit } from "../../../../../assets/icons/Edit";
import { DeletePatient } from "../../../../../assets/icons/DeletePatient";

export const ViewTreatmentTemplateControls = ({ deleteTemplate, editTemplate, navigateBack }: ITemplateControls) => {
    return (
        <div className={classes.controlsContainer}>
          <div className={classes.navigationControls}>
            <IconButton className={classes.back} onClick={() => navigateBack()}>
              <LeftChevron />
            </IconButton>
            <h2>All Treatment Plan Templates</h2>
          </div>
    
          <div className={classes.controls}>
            <Button
              className={classes.deleteControl}
              color="error"
              onClick={() => deleteTemplate()}
              startIcon={<DeletePatient />}
              variant="outlined"
            >
              Delete plan
            </Button>
            <Button
              className={classes.editControl}
              onClick={() => editTemplate()}
              startIcon={<Edit />}
              variant="outlined"
            >
              Edit template
            </Button>
          </div>
        </div>
      );
}