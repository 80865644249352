import { call, takeLatest, put } from 'redux-saga/effects';
import {
  ASSIGN_EXERCISES,
  ASSIGN_EXERCISES_ERROR,
  ASSIGN_EXERCISES_SUCCESS,
  GET_PATIENT_EXERCISES,
} from '../../actions';
import { PatientsAPI } from '../../../api/patientsAPI';

function* assignExercises({
  type,
  payload: { id, exerciseIds },
}: {
  type: string;
  payload: {
    id: string;
    exerciseIds: number[];
  };
}): Generator<any> {
  try {
    const data = yield call(PatientsAPI.assignExercises, { id, exerciseIds });

    yield put({
      type: ASSIGN_EXERCISES_SUCCESS,
      payload: {
        assignExercisesSuccessShowModal: true,
      },
    });
    yield put({
      type: GET_PATIENT_EXERCISES,
      payload: {
        id,
      },
    });
  } catch (error: any) {
    yield put({
      type: ASSIGN_EXERCISES_ERROR,
      payload: { isError: true },
    });
  }
}

export function* watcherAssignExercises() {
  yield takeLatest(ASSIGN_EXERCISES, assignExercises);
}
