import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./CreateEditTreatmentPlanTemplate.module.scss";
import { EDIT } from "../../../utils/constants";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { Modal } from "../../common/Modal/Modal";
import { CreateEditTreatmentPlanControls } from "./components/CreateEditTreatmentPlanControls/CreateEditTreatmentPlanControls";
import { useFormik } from "formik";
import { CreateEditTreatmentPlanDetails } from "./components/CreateEditTreatmentPlanDetails/CreateEditTreatmentPlanDetails";
import { CreateEditTreatmentTimeline } from "./components/CreateEditTreatmentTimeline/CreateEditTreatmentTimeline";
import {
  CREATE_TREATMENT_TEMPLATE_REQUEST,
  GET_TREATMENT_TEMPLATE_REQUEST,
  RECALCULATE_TREATMENT_TEMPLATE_REQUEST,
  RECALCULATE_TREATMENT_TEMPLATE_SUCCESS
} from "../../../redux/actions";
import {
  defaultPeriod,
  initialTreatmentTemplateValues,
  validationSchemaTreatPlanTemp
} from "../../../utils/inputUtils";
import {
  currentTemplateSelector,
  draftTemplateSelector
} from "../../../redux/selectors/treatmentPlansSelector";
import {
  formTreatmentTemplateForCreation,
  tansformCurrentTemplatePeriods,
  transformCurrentTemplateData
} from "../../../utils/dataHandlers";
import { ICreateEditView } from "../../../utils/interfaces";
import { isIncorrectNum } from "../../../utils/commonUtils";

export const CreateEditTreatmentPlanTemplate = ({ mode }: ICreateEditView) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [periods, setPeriods] = useState([defaultPeriod]);
  const [calculatedData, setCalculatedData] = useState({
    totalClicks: null,
    duration: null,
    totalDistractionMm: null
  });

  const id = location.pathname.split("/").pop() || "";

  const currentTreatmentPlan = useSelector(currentTemplateSelector(id));

  const draftTemplate = useSelector(draftTemplateSelector);

  useEffect(() => {
    if (mode === EDIT) {
      dispatch({
        type: GET_TREATMENT_TEMPLATE_REQUEST,
        payload: { id }
      });
    }
  }, [dispatch, id, location.pathname, mode]);

  const initialValues =
    mode === EDIT && currentTreatmentPlan
      ? transformCurrentTemplateData(currentTreatmentPlan)
      : initialTreatmentTemplateValues;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchemaTreatPlanTemp,
    onSubmit: values => {
      const data = formTreatmentTemplateForCreation(values, calculatedData, periods);
      submitForm(data);
    }
  });

  useEffect(() => {
    if (draftTemplate) {
      setCalculatedData({
        totalClicks: draftTemplate.totalClicks,
        duration: draftTemplate.duration,
        totalDistractionMm: draftTemplate.totalDistractionMm
      });
      setPeriods(tansformCurrentTemplatePeriods(draftTemplate.items));
    } else if (currentTreatmentPlan) {
      setCalculatedData({
        totalClicks: currentTreatmentPlan.totalClicks,
        duration: currentTreatmentPlan.duration,
        totalDistractionMm: currentTreatmentPlan.totalDistractionMm
      });
      setPeriods(tansformCurrentTemplatePeriods(currentTreatmentPlan.items));
    }
  }, [draftTemplate, currentTreatmentPlan]);

  const submitDisabled = useMemo(() => {
    if (!formik.values) return true;

    const isIncorrectPeriod = periods.some(({ clicksPerDay, distractionMm }) => !clicksPerDay || isIncorrectNum(clicksPerDay) || !distractionMm || isIncorrectNum(distractionMm));

    return (
      !formik.values.name ||
      !formik.values.nailTypeMm ||
      !formik.values.operationType ||
      !formik.values.initialGapMm ||
      Object.keys(formik.errors).length > 0 ||
      isIncorrectPeriod
    );
  }, [formik.errors, formik.values, periods]);

  const goToTreatmentPlans = () => {
    clearData();
    navigate("/treatment-plans");
  }

  const goToTreatmentPlanView = () => {
    clearData();
    navigate(`/treatment-plans/view/${id}`);
  }

  const closePopup = () => {
    setOpen(false);
  };

  const handleBack = () => {
    if (mode === EDIT) {
      goToTreatmentPlans();
      return;
    }
    handleBackAndCancelForCreate();
  };

  const handleCancel = () => {
    if (mode === EDIT) {
      goToTreatmentPlanView();
      return;
    }
    handleBackAndCancelForCreate();
  };

  const handleBackAndCancelForCreate = () => {
    if (JSON.stringify(initialValues) === JSON.stringify(formik.values)) {
      goToTreatmentPlans();
    } else {
      setOpen(true);
    }
  };

  const submitForm = (values: any) => {
    dispatch({
      type: CREATE_TREATMENT_TEMPLATE_REQUEST,
      payload: { data: values, callback: () => clearData(), navigate, isEdit: mode === EDIT }
    });
  };

  const recalculateTemplate = (newPeriods?: any) => {
    const data = formTreatmentTemplateForCreation(
      formik.values,
      calculatedData,
      newPeriods ? newPeriods : periods,
      true
    );
    dispatch({
      type: RECALCULATE_TREATMENT_TEMPLATE_REQUEST,
      payload: { data }
    });
  };

  const clearData = () => {
    dispatch({
      type: RECALCULATE_TREATMENT_TEMPLATE_SUCCESS,
      payload: {
        draft: null
      }
    })
  }

  return (
    <FullPageContainer pageClassName={classes.createEditTemplateContainer}>
      <>
        <CreateEditTreatmentPlanControls
          navigateBack={handleBack}
          handleCancel={handleCancel}
          handleSave={formik.handleSubmit}
          submitDisabled={submitDisabled}
        />
        <CreateEditTreatmentPlanDetails
          formik={formik}
          calculatedData={calculatedData}
        />
        <CreateEditTreatmentTimeline
          formik={formik}
          periods={periods}
          recalculateTemplate={recalculateTemplate}
          setPeriods={setPeriods}
        />
        <Modal
          open={open}
          title={"Cancel changes"}
          message="Are you sure that you want to cancel creating the template? The data you've entered won't be saved."
          handleCancel={closePopup}
          handleConfirm={goToTreatmentPlans}
        />
      </>
    </FullPageContainer>
  );
};
