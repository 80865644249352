import { call, put, takeLatest } from 'redux-saga/effects';
import { PatientsAPI } from '../../../api/patientsAPI';
import {
  GET_PATIENTS_ERROR,
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_SUCCESS,
  GET_TOTAL_PATIENTS_SUCCESS,
} from '../../actions';
import { SORT, SORT_TYPE } from '../../../utils/constants';

function* workerAllPatientsData(action: {
  type: string;
  payload: {
    size: number;
    page: number;
    searchText: string;
    status: string;
    sort: {
      value: SORT;
      type: SORT_TYPE;
    };
  };
}): Generator<any> {
  try {
    const { status } = action.payload;
    const data: any = yield call(PatientsAPI.getAllPatients, action.payload);
    yield put({
      type: GET_PATIENTS_SUCCESS,
      payload: { [status]: { patients: data.content, totalPages: data.totalPages } },
    });
    yield put({
      type: GET_TOTAL_PATIENTS_SUCCESS,
      payload: { totalFinished: data.totalFinished, totalActive: data.totalActive },
    });
  } catch (error: any) {
    yield put({
      type: GET_PATIENTS_ERROR,
      payload: { code: error.response.status, message: error.message },
    });
  }
}

export function* watcherAllPatientsData() {
  yield takeLatest(GET_PATIENTS_REQUEST, workerAllPatientsData);
}
