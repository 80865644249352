import { call, put, takeLatest } from "redux-saga/effects";
import { AuthAPI } from "../../../api/authAPI";
import {
  setAccessExpiration,
  setAccessToken,
  setRefreshToken
} from "../../../utils/authUtils";
import {
  GET_ACCESS_TOKEN_REQUEST,
  GET_ACCESS_TOKEN_ERROR,
  SET_AUTHENTICATED
} from "../../actions";

function* workerGetAccessToken(action: {
  type: string;
  payload: {
    code: string;
  };
}): Generator<any> {
  try {
    const { code } = action.payload;
    const data: any = yield call(AuthAPI.getAccessToken, code);
    const { access_token, expires_in, refresh_token } = data;

    yield put({
      type: SET_AUTHENTICATED,
      payload: { isAuthenticated: true }
    });

    setAccessToken(access_token);
    setAccessExpiration(expires_in);
    setRefreshToken(refresh_token);
  } catch (error: any) {
    yield put({
      type: GET_ACCESS_TOKEN_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherGetAccessToken() {
  yield takeLatest(GET_ACCESS_TOKEN_REQUEST, workerGetAccessToken);
}
