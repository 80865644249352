import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PatientsAPI } from '../../../api/patientsAPI';
import { TreatmentPlanTemplatesAPI } from '../../../api/treatmentPlansAPI';
import { ToastType } from '../../../utils/interfaces';
import {
  EDIT_PATIENT_ERROR,
  EDIT_PATIENT_REQUEST,
  EDIT_PATIENT_SUCCESS,
  SET_TOAST,
} from '../../actions';

function* workerEditPatient(action: {
  type: string;
  payload: { patientData: any; applyPlanData: any; navigate: Function; periods: any; id: any };
}): Generator<any> {
  try {
    const { patientData, applyPlanData, navigate, periods, id } = action.payload;
    const requests = [
      call(PatientsAPI.editPatient, patientData, id),
      call(TreatmentPlanTemplatesAPI.applyTreatmentTemplate, applyPlanData),
    ];
    if (periods) requests.push(call(PatientsAPI.saveTemplate, { data: { periods, id } }));

    const result = yield all(requests);
    yield put({
      type: EDIT_PATIENT_SUCCESS,
    });
    if (result && Array.isArray(result) && result[0].errors) {
      yield put({
        type: SET_TOAST,
        payload: {
          open: true,
          message: 'Something went wrong, please copy details and contact IT support',
          type: ToastType.ERROR,
          isCopy: true,
          copyText: result[0].errors,
        },
      });
    } else {
      navigate(`/patients/view/${patientData.id}`);
      yield put({
        type: SET_TOAST,
        payload: {
          open: true,
          message: 'Patient details were successfully updated',
          type: ToastType.SUCCESS,
        },
      });
    }
  } catch (error: any) {
    if (error?.response?.data?.includes('should have unique value')) {
      yield put({
        type: SET_TOAST,
        payload: {
          open: true,
          message: 'The data is not unique. Please use another information',
          type: ToastType.ERROR,
        },
      });
      yield put({
        type: EDIT_PATIENT_ERROR,
        payload: { code: error.response.status, message: error.response.data },
      });
      return;
    }
    yield put({
      type: EDIT_PATIENT_ERROR,
      payload: { code: error.response.status, message: error.message },
    });
  }
}

export function* watcherEditPatient() {
  yield takeLatest(EDIT_PATIENT_REQUEST, workerEditPatient);
}
