import { bonesMap } from "../../../../../utils/constants";
import { ITreatmentPlan } from "../../../../../utils/interfaces";
import { TextData } from "../../../../common/TextData/TextData";
import classes from "./ViewTreatmentPlanDetails.module.scss";

export const ViewTreatmentPlanDetails = ({ template }: { template: ITreatmentPlan }) => {
  const {
    name,
    operationType,
    nailTypeMm,
    totalClicks,
    totalDistractionMm,
    duration,
    initialGapMm
  } = template || {};

  return (
    <div className={classes.treatmentPlanDetailsContainer}>
      <h2>Treatment Plan Details</h2>
      <TextData name="Plan Name" value={name} />
      <TextData name="Nail Type" value={nailTypeMm} />
      <TextData name="Initial Gap" value={initialGapMm} />
      <TextData name="Bone type" value={bonesMap[operationType]} />
      <TextData name="Total Distraction" value={totalDistractionMm} />
      <TextData custom name="Duration" value={<p>{duration}</p>} />
      <TextData custom name="Total Amount of Clicks" value={<p>{totalClicks}</p>} />
    </div>
  );
};
