import { ISvgIcon } from "../../utils/interfaces";

export const BBLogo = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="144"
    height="24"
    viewBox="0 0 144 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1797 0.8798H15.0595V1V23V23.1202H15.1797H21.7329C25.4138 23.1202 28.4064 20.2568 28.4064 16.7143C28.4064 14.384 27.1105 12.3466 25.1768 11.2262C26.4843 10.1482 27.3142 8.54869 27.3142 6.76191C27.3142 3.50864 24.5661 0.8798 21.1868 0.8798H15.1797ZM21.1868 3.73925C22.9349 3.73925 24.3433 5.09721 24.3433 6.76191C24.3433 8.4266 22.9349 9.78456 21.1868 9.78456H18.0304V3.73925H21.1868ZM21.7329 13.1678C23.7826 13.1678 25.4355 14.7603 25.4355 16.7143C25.4355 18.6683 23.7826 20.2608 21.7329 20.2608H18.0304V13.1678H21.7329Z"
      fill="#158AA4"
      stroke="#158AA4"
      strokeWidth="0.240401"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09239 6.76191C1.09239 3.57969 3.78188 1 7.09954 1H13.1067V23H6.55344C2.93418 23 0.000184059 20.1858 0.000184059 16.7143C0.000184059 14.3691 1.33916 12.3239 3.32383 11.2436C1.96268 10.1873 1.09239 8.5721 1.09239 6.76191ZM3.82291 6.76191C3.82291 5.02615 5.28991 3.61905 7.09954 3.61905H10.3762V9.90476H7.09954C5.28991 9.90476 3.82291 8.49766 3.82291 6.76191ZM2.73071 16.7143C2.73071 14.6892 4.4422 13.0476 6.55344 13.0476H10.3762V20.381H6.55344C4.4422 20.381 2.73071 18.7393 2.73071 16.7143Z"
      fill="#9090A2"
    />
    <path
      d="M41.6508 6.5L41.6508 21.0357H44.6412V23H36.6667V21.0357H39.6572L39.6572 6.5H41.6508Z"
      fill="#212529"
    />
    <path
      d="M57.7989 19.3439L49.6252 6.5H47.6316L47.6316 23H49.6252V10.3351L58.0972 23H59.7925V6.5H57.7989V19.3439Z"
      fill="#212529"
    />
    <path
      d="M108.835 18.2857C108.797 17.7496 108.835 6.5 108.835 6.5H110.829V17.8929C110.829 18.3264 110.909 18.7526 111.062 19.1458C111.214 19.5388 111.434 19.8862 111.702 20.1718C111.969 20.4571 112.277 20.6742 112.605 20.8191C112.765 20.8899 112.929 20.9433 113.095 20.9793C113.269 21.017 113.445 21.0357 113.62 21.0357C113.963 21.0357 114.308 20.9638 114.635 20.8191C114.962 20.6742 115.271 20.4571 115.538 20.1718C115.806 19.8862 116.026 19.5388 116.178 19.1458C116.238 18.9916 116.287 18.8325 116.324 18.6697C116.381 18.4174 116.411 18.1565 116.411 17.8929V6.5H118.405C118.405 6.5 118.443 17.7496 118.405 18.2857C118.366 18.8219 118.234 19.349 118.04 19.8473C117.8 20.4669 117.447 21.0299 117.003 21.5042C116.559 21.9784 116.031 22.3546 115.451 22.6112C114.87 22.8679 114.248 23 113.62 23C112.992 23 112.369 22.8679 111.789 22.6112C111.209 22.3546 110.681 21.9784 110.237 21.5042C109.792 21.0299 109.44 20.4669 109.2 19.8473C109.006 19.349 108.874 18.8219 108.835 18.2857Z"
      fill="#212529"
    />
    <path
      d="M68.8601 8.56578C68.4837 8.45326 68.0848 8.43395 67.699 8.50958C67.3131 8.5852 66.9521 8.75342 66.648 8.99933C66.3439 9.24524 66.106 9.56126 65.9555 9.91939C65.8049 10.2775 65.7463 10.6667 65.7849 11.0525C65.8234 11.4382 65.958 11.8087 66.1765 12.131C66.3951 12.4533 66.691 12.7175 67.038 12.9002C67.3849 13.083 67.7723 13.1957 68.1657 13.1957C68.8141 13.2513 69.4461 13.4423 70.0224 13.7584C70.7163 14.1391 71.3081 14.6896 71.7453 15.361C72.1824 16.0325 72.4515 16.8043 72.5286 17.608C72.6057 18.4116 72.4885 19.2224 72.1874 19.9685C71.8863 20.7146 71.4105 21.373 70.8023 21.8853C70.1941 22.3976 69.4721 22.7481 68.7004 22.9056C67.9287 23.0632 67.131 23.023 66.3781 22.7885C65.6251 22.5541 64.9401 22.1327 64.3837 21.5617L65.8219 20.2014C66.1512 20.5394 66.5498 20.782 66.9787 20.9155C67.4072 21.0489 67.8588 21.0714 68.2959 20.9822C68.7334 20.8929 69.15 20.6929 69.5069 20.3923C69.8643 20.0912 70.1512 19.6979 70.3349 19.2427C70.5187 18.7872 70.5914 18.2887 70.5438 17.7929C70.4962 17.2971 70.3306 16.8262 70.0672 16.4216C69.8041 16.0174 69.4535 15.6945 69.0526 15.4746C68.6523 15.255 68.211 15.125 67.767 15.125C67.1842 15.0725 66.6166 14.9056 66.0982 14.6326C65.4621 14.2976 64.9196 13.8132 64.5189 13.2223C64.1182 12.6314 63.8716 11.9522 63.8009 11.245C63.7302 10.5378 63.8376 9.82426 64.1136 9.16769C64.3896 8.51112 64.8257 7.93176 65.3833 7.48092C65.9408 7.03008 66.6026 6.72168 67.31 6.58304C68.0174 6.44439 68.7486 6.47978 69.4389 6.68608C70.1291 6.89237 70.757 7.2632 71.267 7.76572L69.8573 9.15468C69.5791 8.88058 69.2366 8.67831 68.8601 8.56578Z"
      fill="#212529"
    />
    <path d="M44.6412 2.96429H36.6667V1H44.6412V2.96429Z" fill="#212529" />
    <path
      d="M74.1465 6.5H83.7158V8.46429H79.9279V23H77.9343V8.46429H74.1465V6.5Z"
      fill="#212529"
    />
    <path
      d="M106.044 6.5H96.4748V8.46429H100.263V23H102.256V8.46429H106.044V6.5Z"
      fill="#212529"
    />
    <path
      d="M131.562 6.5H121.993V8.46429H125.781V23H127.774V8.46429H131.562V6.5Z"
      fill="#212529"
    />
    <path
      d="M86.1081 23H94.0825V21.0357H91.0921V8.46429H94.0825V6.5H86.1081V8.46429H89.0985V21.0357H86.1081V23Z"
      fill="#212529"
    />
    <path
      d="M134.752 6.5H143.524V8.46429H136.746V13.5714H143.524V15.5357H136.746V21.0357H143.524V23H134.752V6.5Z"
      fill="#212529"
    />
  </svg>
);
