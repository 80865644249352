import { SORT_SVG, TABS } from "./interfaces";

/* Tabs on Doctors Dashboard */
export const ACTIVE = "ACTIVE";
export const FINISHED = "FINISHED";
export const ALL = "ALL";

export const patientsTableTabs = [
  {
    id: TABS.active,
    label: "Active Patients"
  },
  {
    id: TABS.finished,
    label: "Finished Patients"
  },
  {
    id: TABS.all,
    label: "All"
  }
];

/* Tabs on Alarms drawer */
export enum ALARMS_TABS {
  ACTIVE = "ACTIVE",
  RESOLVED = "RESOLVED",
  ALL = "ALL",
}

export const alarmsTabs = [
  {
    id: ALARMS_TABS.ACTIVE,
    label: "Active"
  },
  {
    id: ALARMS_TABS.RESOLVED,
    label: "Resolved"
  },
  {
    id: ALARMS_TABS.ALL,
    label: "All"
  }
];

/* Bone Types */
export const FEMUR = "FEMUR";
export const TIBIA = "TIBIA";

/* Sides */
export const LEFT = "LEFT";
export const RIGHT = "RIGHT";
export const BOTH = "BOTH";

/* Operation Types */
export const TIBIAS = "TIBIAS";
export const FEMURS = "FEMURS";
export const RIGHT_TIBIA = "RIGHT_TIBIA";
export const LEFT_TIBIA = "LEFT_TIBIA";
export const RIGHT_FEMUR = "RIGHT_FEMUR";
export const LEFT_FEMUR = "LEFT_FEMUR";

/* Create/edit mode for patient account */
export const CREATE = "CREATE";
export const EDIT = "EDIT";

/* Patient Statuses */
export const NOT_ACTIVATED = "NOT_ACTIVATED";
export const IN_PROGRESS = "ACTIVE";
export const PREPARATION = "PREPARATION";
export const PLANNED = "PLANNED";
export const FINISHED_STATUS = "FINISHED";

/* Bones mapping */
export const bonesMap = {
  [TIBIAS]: "Tibia",
  [RIGHT_TIBIA]: "Tibia",
  [LEFT_TIBIA]: "Tibia",
  [FEMURS]: "Femur",
  [RIGHT_FEMUR]: "Femur",
  [LEFT_FEMUR]: "Femur"
}

export const genderMap = {
  F: "Female",
  M: "Male"
}

export enum SORT {
  NAME = "NAME",
  BONE = "BONE",
  DISTRACTION = "DISTRACTION",
  PROGRESS = "PROGRESS",
  ALARMS = "ALARMS",
}

export const SORT_MAPPING: Record<SORT, string> = {
  NAME: "fullName",
  BONE: "bone",
  DISTRACTION: "totalDistraction",
  PROGRESS: "currentTreatmentDay",
  ALARMS: "activeNotificationCount",
}

export enum SORT_TYPE {
  ASC = "ASC",
  DESC = "DESC",
}

export const SORT_TYPE_MAPPING: Record<SORT_TYPE, string> = {
  ASC: "asc",
  DESC: "desc",
}

export const viewBoxMapping: Record<SORT_SVG, string> = {
  [SORT_SVG.DESC]: "0 -12 18 18",
  [SORT_SVG.DEFAULT]: "6 0 18 18",
  [SORT_SVG.ASC]: "0 -2 18 18",
}

export const pathMapping: Record<SORT_SVG, string> = {
  [SORT_SVG.DESC]: "M3.375 5L0 1.625L1.0625 0.5625L3.375 2.875L5.6875 0.5625L6.75 1.625L3.375 5Z",
  [SORT_SVG.DEFAULT]: "M10 17L6.625 13.625L7.6875 12.5625L10 14.875L12.3125 12.5625L13.375 13.625L10 17ZM7.6875 7.4375L6.625 6.375L10 3L13.375 6.375L12.3125 7.4375L10 5.125L7.6875 7.4375Z",
  [SORT_SVG.ASC]: "M1.0625 4.4375L0 3.375L3.375 0L6.75 3.375L5.6875 4.4375L3.375 2.125L1.0625 4.4375Z",
}
