import { FormControl, TextField } from "@mui/material";
import { ITextInput } from "../../../../utils/interfaces";

export const TextInput = ({
  id,
  label,
  required,
  maxLength,
  inputClassName,
  helperClassName,
  formClassName,
  placeholder,
  labelClassName,
  formik,
  type,
  disabled,
}: ITextInput) => (
  <FormControl className={formClassName}>
    <p className={labelClassName}>
      {label}
      {required && <span>*</span>}
    </p>
    <TextField
      id={id}
      InputProps={{
        classes: {
          root: inputClassName
        },
        autoComplete: "off"
      }}
      FormHelperTextProps={{
        classes: {
          root: helperClassName
        }
      }}
      disabled={disabled}
      name={id}
      onBlur={() => formik.setFieldTouched(id)}
      onChange={(event) => {
        if (maxLength && event.target.value.length > maxLength) return;
        formik.handleChange(event);
      }}
      placeholder={placeholder || label}
      type={type}
      value={formik.values[id]}
      error={formik.touched[id] && Boolean(formik.errors[id])}
      helperText={formik.touched[id] && formik.errors[id]}
    />
  </FormControl>
);
