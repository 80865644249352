import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./ViewTreatmentPlanTemplate.module.scss";
import { currentTemplateSelector } from "../../../redux/selectors/treatmentPlansSelector";
import { DELETE_TEMPLATE_REQUEST, GET_TREATMENT_TEMPLATE_REQUEST } from "../../../redux/actions";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { ViewTreatmentTemplateControls } from "./components/ViewTreatmentTemplateControls/ViewTreatmentTemplateControls";
import { ViewTreatmentPlanDetails } from "./components/ViewTreatmentPlanDetails/ViewTreatmentPlanDetails";
import { ViewTreatmentTimeline } from "./components/ViewTreatmentTimeline/ViewTreatmentTimeline";
import { Modal } from "../../common/Modal/Modal";

export const ViewTreatmentPlanTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const id = location.pathname.split("/").pop() || "";

  useEffect(() => {
    dispatch({
      type: GET_TREATMENT_TEMPLATE_REQUEST,
      payload: { id }
    });
  }, [dispatch, id]);

  const currentTemplate = useSelector(currentTemplateSelector(id));

  const goToTemplates = () => navigate("/treatment-plans");

  const editTemplate = () => navigate(`/treatment-plans/edit/${id}`);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const deleteTemplate = () => {
    dispatch({
      type: DELETE_TEMPLATE_REQUEST,
      payload: { id, navigate, shouldLoadList: false }
    });
    closeModal();
  };

  return (
    <FullPageContainer pageClassName={classes.viewTemplatesContainer}>
      <>
        <ViewTreatmentTemplateControls
          deleteTemplate={openModal}
          editTemplate={editTemplate}
          navigateBack={goToTemplates}
        />
        <ViewTreatmentPlanDetails template={currentTemplate} />
        <ViewTreatmentTimeline periods={currentTemplate?.items} />
        <Modal
          deleteConfirmation
          open={open}
          title={"Delete template"}
          message={"Are you sure you want to delete this treatment plan?"}
          handleConfirm={deleteTemplate}
          handleCancel={closeModal}
        />
      </>
    </FullPageContainer>
  );
};
