export const getAccidentalClicksAmount = (
  clicksPerBar: number,
  actual: number,
  accidental: number
) => {
  const spaceForAccidental = clicksPerBar - actual;
  if (spaceForAccidental >= accidental) return accidental;
  return spaceForAccidental;
};

export const getActualBarHeight = (
  oneBarHeight: number,
  amountPerBar: number,
  actualAmount: number
) => {
  if (actualAmount <= 0) return 0;
  return oneBarHeight * (actualAmount / amountPerBar);
};

export const getYCoordinateForClicks = (
  i: number,
  y: number,
  oneBarHeight: number,
  margin: number,
  remainingClicksHeight: number
) => {
  return (
    y +
    (i - 1) * margin +
    (i - 1) * oneBarHeight +
    (remainingClicksHeight ? remainingClicksHeight + margin : 0)
  );
};
