import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

import { Alarm } from "../Alarm/Alarm";
import { IAlarm, IAlarmsListProps } from "../../../../../../../utils/interfaces";
import { alarmsSelector } from "../../../../../../../redux/selectors/alarmsSelector";
import { RESOLVE_ALARMS_REQUEST } from "../../../../../../../redux/actions";
import { ALARMS_TABS } from "../../../../../../../utils/constants";

export const AlarmsList = ({ selectedTab }: IAlarmsListProps) => {
  const dispatch = useDispatch();
  const alarms: Array<IAlarm> = useSelector(alarmsSelector);

  const onResolveAlarm = useCallback((alarmId: number) => {
    dispatch({
      type: RESOLVE_ALARMS_REQUEST,
      payload: { alarmId, isDelete: selectedTab === ALARMS_TABS.ACTIVE, }
    })
  }, [dispatch, selectedTab]);

  return (
    <>
      {Array.isArray(alarms) && [...alarms].sort(sortAlarms).map(alarm => (
        <Alarm onResolve={onResolveAlarm} key={alarm.id} alarm={alarm} />
      ))}
    </>
  );
};

const sortAlarms = (a: IAlarm, b: IAlarm) => {
  if (!a.dateTime || !b.dateTime) return 1;

  const aDate = new Date(a.dateTime).valueOf();
  const bDate = new Date(b.dateTime).valueOf();
  return isNaN(aDate) || isNaN(bDate) ? 1 : bDate - aDate;
}
