import { call, put, takeLatest } from "redux-saga/effects";

import { PatientsAPI } from "../../../api/patientsAPI";
import { ALARMS_TABS } from "../../../utils/constants";
import {
  GET_ALARMS_REQUEST,
  GET_ALARMS_ERROR,
  GET_ALARMS_SUCCESS
} from "../../actions";

function* workerAlarms(action: {
  type: string;
  payload: {
    type: ALARMS_TABS;
    patientId: string;
  };
}): Generator<any> {
  try {
    const data: any = yield call(PatientsAPI.getAlarms, action.payload);

    yield put({
      type: GET_ALARMS_SUCCESS,
      payload: { alarms: data }
    });
  } catch (error: any) {
    yield put({
      type: GET_ALARMS_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherAlarms() {
  yield takeLatest(GET_ALARMS_REQUEST, workerAlarms);
}
