import { Alert, Button, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckMark } from "../../../assets/icons/CheckMark";
import { SET_TOAST } from "../../../redux/actions";
import { toastSelector } from "../../../redux/selectors/alarmsSelector";
import classes from "./Toast.module.scss";
import { SyntheticEvent } from "react";

const alertIcons = {
  success: <CheckMark className={classes.success} />,
}

export const Toast = () => {
  const toast = useSelector(toastSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: SET_TOAST,
      payload: {
        open: false,
        message: "",
        isCopy: false,
        copyText: "",
      }
    });
  }

  const handleCopy = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    navigator.clipboard.writeText(toast.copyText)
  }

  return (
    <Snackbar
      open={toast.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={5000}
      transitionDuration={{ exit: 0, enter: 200 }}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={toast.type.toLowerCase()}
        variant="filled"
        elevation={6}
        slots={{ closeButton: () => <></> }}
        iconMapping={alertIcons}
        sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        {toast.message}
        {toast.isCopy && (
          <Button onClick={handleCopy} sx={{ backgroundColor: '#E50000', marginLeft: 2, }}>
            Copy
          </Button>
        )}
      </Alert>
    </Snackbar>
  )
}