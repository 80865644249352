import { ReactElement } from "react";
import {
  getAccidentalClicksAmount,
  getActualBarHeight,
  getYCoordinateForClicks
} from "./barUtils";

const barCount = 5;
const maxClicks = 20;
const clicksPerBar = maxClicks / barCount;
const margin = 4;
const oneBarHeight = 68;

const Rect = ({
  actualClicksHeight,
  rightClicks,
  accidentalClicksHeight,
  x,
  y,
  width,
  height,
  fill,
  stroke,
  style
}: any) => (
  <>
    <rect
      x={x}
      y={y}
      style={style}
      width={width}
      height={height > 0 ? height - margin : 0}
      rx="2.75"
      fill={fill}
      stroke={stroke}
      strokeWidth="0.5"
    />
    <rect
      x={x}
      y={y + (height - actualClicksHeight)}
      style={style}
      width={width}
      height={actualClicksHeight > 0 ? actualClicksHeight - margin : 0}
      rx="2.75"
      fill={rightClicks ? "#7897FF" : "#032596"}
      stroke={rightClicks ? "#7897FF" : "#032596"}
      strokeWidth="0.5"
    />
    <rect
      x={x}
      y={y + (height - actualClicksHeight - accidentalClicksHeight)}
      style={style}
      width={width}
      height={accidentalClicksHeight > 0 ? accidentalClicksHeight - margin : 0}
      rx="2.75"
      fill={"#FFAC66"}
    />
  </>
);

export const ClicksBar = (props: any) => {
  const {
    fill,
    x,
    y,
    width,
    payload: {
      date,
      leftPlannedClicks,
      leftActualClicks,
      leftAccidentalClicks,
      rightPlannedClicks,
      rightActualClicks,
      rightAccidentalClicks
    },
    stroke,
    rightClicks
  } = props;

  let { planned, actual, accidental } = rightClicks
    ? {
        planned: rightPlannedClicks,
        actual: rightActualClicks,
        accidental: rightAccidentalClicks
      }
    : {
        planned: leftPlannedClicks,
        actual: leftActualClicks,
        accidental: leftAccidentalClicks
      };

  const wholeBarsCount = Math.floor(planned / clicksPerBar);

  const remainingPlannedClicks = planned % clicksPerBar;

  const remainingClicksHeight =
    oneBarHeight * (remainingPlannedClicks / clicksPerBar);

  const rects: ReactElement[] = [];

  let i = wholeBarsCount;

  while (i >= 1) {
    const yCoord = getYCoordinateForClicks(
      i,
      y,
      oneBarHeight,
      margin,
      remainingClicksHeight
    );
    const actualClicks = actual >= clicksPerBar ? clicksPerBar : actual;
    const accidentalClicks = getAccidentalClicksAmount(
      clicksPerBar,
      actualClicks,
      accidental
    );
    rects.push(
      <Rect
        key={`${date}_${x}_${yCoord}_${i}`}
        actualClicksHeight={getActualBarHeight(
          oneBarHeight,
          clicksPerBar,
          actualClicks
        )}
        accidentalClicksHeight={getActualBarHeight(
          oneBarHeight,
          clicksPerBar,
          accidentalClicks
        )}
        rightClicks={rightClicks}
        x={x}
        y={yCoord}
        width={width}
        height={oneBarHeight}
        fill={fill}
        stroke={stroke}
        style={{ cursor: "pointer" }}
      />
    );
    actual = actual - clicksPerBar >= 0 ? actual - clicksPerBar : 0;
    accidental =
      accidental - accidentalClicks >= 0 ? accidental - accidentalClicks : 0;
    --i;
  }

  if (remainingPlannedClicks) {
    const actualClicks = actual > 0 ? actual : 0;
    const accidentalClicks = getAccidentalClicksAmount(
      remainingPlannedClicks,
      actualClicks,
      accidental
    );
    rects.push(
      <Rect
        key={`${date}_${x}_${y}_${i}`}
        actualClicksHeight={getActualBarHeight(
          oneBarHeight,
          clicksPerBar,
          actualClicks
        )}
        accidentalClicksHeight={getActualBarHeight(
          oneBarHeight,
          clicksPerBar,
          accidentalClicks
        )}
        rightClicks={rightClicks}
        x={x}
        y={y}
        width={width}
        height={remainingClicksHeight}
        fill={fill}
        stroke={stroke}
        style={{ cursor: "pointer" }}
      />
    );
  }

  return <>{rects}</>;
};
