import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      fontSize: "15px",
      fontWeight: 400,
      textTransform: "none"
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: "#F4FDFF",
          color: "#158AA4",
          border: "1px solid #1D9EBE",
          borderRadius: 12,
        },
        filledError: {
          backgroundColor: "#FFF2F2",
          color: "#E60000",
          border: "1px solid #E50000",
          borderRadius: 12,
        },
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "40px",
          height: "20px",
          padding: 0,
          borderRadius: "12px",

          "& .Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: "#158AA4",
              opacity: 1,
              border: 0,
            },

          },
          "& .Mui-disabled + .MuiSwitch-track": {
            opacity: 0.4,
          },

          "& .MuiSwitch-thumb": {
            backgroundColor: "#fff",
            boxSizing: "border-box",
            width: "16px",
            height: "16px",
          },

          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: "2px",
            transitionDuration: "300ms",
          },

          "& .MuiSwitch-track": {
            backgroundColor: "#D9DBE9",
            opacity: 1,
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            color: "#fff",
            borderRadius: "8px",
            height: "36px",
            boxShadow: "none"
          }
        },
        {
          props: { color: "secondary" },
          style: {
            color: "#158AA4",
            borderColor: "#158AA4",
            borderRadius: "8px",
            height: "36px",
            boxShadow: "none"
          }
        }
      ]
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#D9DBE9"
        }
      }
    },
  },
  palette: {
    primary: {
      main: "#158AA4"
    },
    secondary: {
      main: "#FFFFFF"
    }
  }
});
