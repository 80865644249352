import classes from "./Alarms.module.scss";
import { ActiveAlarm } from "../../../../../../assets/icons/ActiveAlarm";
import { InactiveAlarm } from "../../../../../../assets/icons/InactiveAlarm";
import { IAlarms } from "../../../../../../utils/interfaces";

export const Alarms = ({ number, navigateToAlarms }: IAlarms) => {
  return (
    <div className={classes.alarms}>
      {number ? <ActiveAlarm className={classes.activeAlarm} onClick={navigateToAlarms}/> : <InactiveAlarm />}{number}
    </div>
  );
};
