import services from "../services.json";
import { TABS } from "./interfaces";

const servicesObj = JSON.parse(JSON.stringify(services));

export const getServiceURL = (serviceName: string): string => {
  return servicesObj[serviceName];
};

export const getStatus = (status: string) => {
  if (status === TABS.all) return null;
  if (status === TABS.active) return "ACTIVE,NOT_ACTIVATED,PLANNED";
  return status;
}