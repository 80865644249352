import { useEffect, useMemo, useState } from 'react';
import { addDays, format, subDays } from 'date-fns';
import classes from './PeriodToggle.module.scss';
import { DoubleLeftChevron } from '../../../../../../../assets/icons/DoubleLeftChevron';
import { DoubleRightChevron } from '../../../../../../../assets/icons/DoubleRightChevron';
import { LeftChevron } from '../../../../../../../assets/icons/LeftChevron';
import { RightChevron } from '../../../../../../../assets/icons/RightChevron';
import { getIntervalFromCentralDate } from './datesUtils';
import { IconButton } from '@mui/material';

export const PeriodToggle = ({ setDates }: { setDates: Function }) => {
  const [centralDate, setCentralDate] = useState(new Date());

  const { start, end, interval } = useMemo(() => {
    return getIntervalFromCentralDate(centralDate);
  }, [centralDate]);

  useEffect(() => {
    setDates({ start, end, interval });
  }, [interval]);

  const swipeLeftOneDay = () => {
    const newCentralDate = subDays(centralDate, 1);
    setCentralDate(newCentralDate);
  };

  const swipeRightOneDay = () => {
    const newCentralDate = addDays(centralDate, 1);
    setCentralDate(newCentralDate);
  };

  const swipePeriodLeft = () => {
    const newCentralDate = subDays(centralDate, 12);
    setCentralDate(newCentralDate);
  };

  const swipePeriodRight = () => {
    const newCentralDate = addDays(centralDate, 12);
    setCentralDate(newCentralDate);
  };

  return (
    <div className={classes.interval}>
      <IconButton onClick={() => swipePeriodLeft()}>
        <DoubleLeftChevron />
      </IconButton>
      <IconButton onClick={() => swipeLeftOneDay()}>
        <LeftChevron />
      </IconButton>
      <span>
        {format(start, 'dd/MM/yyyy')} - {format(end, 'dd/MM/yyyy')}
      </span>
      <IconButton onClick={() => swipeRightOneDay()}>
        <RightChevron />
      </IconButton>
      <IconButton onClick={() => swipePeriodRight()}>
        <DoubleRightChevron />
      </IconButton>
    </div>
  );
};
