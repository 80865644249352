export type ExerciseGroup = {
  name: string;
  exercises: any[];
  backgroundColor: string;
  borderColor: string;
  id: ExerciseGroupIdByName;
};

// Drag n Drop types
export enum DnDItemType {
  ASSIGNED_EXERCISE = 'assignedExercise',
  UNASSIGNED_EXERCISE = 'unassignedExercise',
  DEFAULT_UNASSIGNED_EXERCISE = 'defaultUnassignedExercise',
}

// data from backend
export type Exercise = {
  id: number;
  maleDescription: string;
  femaleDescription: string;
  maleLink: string;
  femaleLink: string;
  malePreviewLink: string;
  femalePreviewLink: string;
  exerciseGroupDto: {
    id: number;
    name: string;
    enabled: boolean;
  };
};

export type ExerciseItem = Exercise & {
  state: DnDItemType;
};

export enum ExerciseGroupIdByName {
  PELVIC_MOVEMENTS = 1,
  HIP_FLEXORS_AND_THIGHS_FRONT,
  OUTER_THIGHS_AND_BUTTOCKS,
  BACK_OF_THIGHS,
  ADDITIONALS,
}
