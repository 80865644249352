import classes from "./CreateEditTreatmentPlanDetails.module.scss";
import { treatmentDetailsInputs } from "../../../../../utils/inputUtils";
import { SelectField } from "../../../../common/InputComponents/SelectField/SelectField";
import { TextInput } from "../../../../common/InputComponents/TextInput/TextInput";
import { Divider } from "@mui/material";
import { TextData } from "../../../../common/TextData/TextData";

export const CreateEditTreatmentPlanDetails = ({ formik, calculatedData }: any) => {
  return (
    <div className={classes.treatmentPlanDetailsContainer}>
      <h2>Treatment Plan Details</h2>
      <div className={classes.treatmentPlanDetails}>
        {treatmentDetailsInputs.map(input => {
          if (input.type === "select") {
            return (
              <SelectField
                key={input.id}
                {...input}
                formik={formik}
                selectRootClassName={classes.selectRoot}
                selectInputClassName={classes.selectInput}
                labelClassName={classes.label}
                formClassName={classes.formControl}
                type="text"
              />
            );
          }
          return (
            <TextInput
              key={input.id}
              {...input}
              formik={formik}
              required={input?.required}
              inputClassName={classes.input}
              helperClassName={classes.helper}
              labelClassName={classes.label}
              formClassName={classes.formControl}
            />
          );
        })}
      </div>
      <Divider flexItem />
      <div className={classes.calculatedData}>
        <TextData
          name="Total Distraction"
          value={calculatedData.totalDistractionMm}
        />
        <TextData
          custom
          name="Duration"
          value={<p>{calculatedData.duration || "-"}</p>}
        />
        <TextData
          custom
          name="Total Amount of Clicks"
          value={<p>{calculatedData.totalClicks || "-"}</p>}
        />
      </div>
    </div>
  );
};
