import { ISvgIcon } from "../../utils/interfaces";

export const NoResults = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.38855 31.6667C6.17561 31.6667 4.27977 30.8773 2.70105 29.2986C1.12235 27.7199 0.333008 25.8241 0.333008 23.6111C0.333008 21.3982 1.12235 19.5023 2.70105 17.9236C4.27977 16.3449 6.17561 15.5556 8.38855 15.5556C10.6015 15.5556 12.4974 16.3449 14.0761 17.9236C15.6547 19.5023 16.4441 21.3982 16.4441 23.6111C16.4441 25.8241 15.6547 27.7199 14.0761 29.2986C12.4974 30.8773 10.6015 31.6667 8.38855 31.6667ZM31.6663 30L20.8052 19.1389C20.546 19.3426 20.2543 19.5417 19.9302 19.7361C19.6061 19.9306 19.2959 20.0926 18.9996 20.2222C18.7959 19.8426 18.576 19.456 18.3399 19.0625C18.1038 18.669 17.8515 18.3056 17.583 17.9722C18.9348 17.3611 20.0645 16.4213 20.9719 15.1528C21.8793 13.8843 22.333 12.4074 22.333 10.7222C22.333 8.52776 21.5622 6.65508 20.0205 5.10417C18.4788 3.55322 16.6108 2.77775 14.4163 2.77775C12.2034 2.77775 10.3214 3.55322 8.77051 5.10417C7.21956 6.65508 6.44409 8.52776 6.44409 10.7222C6.44409 11.0926 6.47881 11.4746 6.54826 11.8681C6.6177 12.2616 6.70336 12.6065 6.80522 12.9028C6.39783 12.9583 5.94644 13.0556 5.45105 13.1945C4.95569 13.3333 4.50894 13.4954 4.1108 13.6806C3.96263 13.2454 3.85152 12.7732 3.77747 12.2639C3.70338 11.7546 3.66634 11.2407 3.66634 10.7222C3.66634 7.73149 4.71033 5.19676 6.7983 3.11804C8.88625 1.03935 11.4256 0 14.4163 0C17.3886 0 19.914 1.04399 21.9927 3.13196C24.0714 5.2199 25.1108 7.74999 25.1108 10.7222C25.1108 11.9167 24.9094 13.081 24.5066 14.2153C24.1038 15.3495 23.5506 16.3518 22.8469 17.2222L33.6663 28L31.6663 30ZM5.4858 27.5972L8.38855 24.6945L11.2497 27.5695L12.2913 26.5417L9.38855 23.6389L12.3469 20.6806L11.3191 19.6528L8.38855 22.5833L5.45801 19.6528L4.43022 20.6806L7.3608 23.6111L4.43022 26.5417L5.4858 27.5972Z"
      fill="#6E7191"
    />
  </svg>
);
