import { IconButton, Switch, TableCell, TableRow } from "@mui/material";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import { useState } from "react";
import { Delete } from "../../../../../../assets/icons/Delete";
import { ITreatmentPlanPeriod, SIDE } from "../../../../../../utils/interfaces";
import { NumericInput } from "../../../../../common/InputComponents/NumericInput/NumericInput";
import classes from "./TreatmentPlanRow.module.scss";

interface TreatmentPlanRowProps {
  period: [ITreatmentPlanPeriod, ITreatmentPlanPeriod];
  changeValue: (sortOrder: number, key: string, value: string, side?: SIDE) => void;
  openModal: (idToDelete: number) => void;
  totalAmount: number;
}

const getPeriodText = (sortOrder: number, start: Date | null, end: Date | null) => {
  return `Period ${sortOrder} (${start && isValid(start) ? format(start, "dd/MM/yyyy") : ""} - ${end && isValid(end) ? format(end, "dd/MM/yyyy") : ""})`
}

export const TreatmentPlanRow = ({
  period,
  openModal,
  totalAmount,
  changeValue,
}: TreatmentPlanRowProps) => {
  const [{
    startDate,
    endDate,
    sortOrder,
    distractionMm: leftDistractionMm,
    id,
    clicksPerDay: leftClicksPerDay,
    totalClicks: leftTotalClicks,
    duration,
    dayOfPeriod,
  }, {
    distractionMm: rightDistraction,
    clicksPerDay: rightClicksPerDay,
    totalClicks: rightTotalClicks,
  }] = period;

  const start = startDate ? new Date(startDate) : null;
  const end = endDate ? new Date(endDate) : null;
  const periodText = getPeriodText(sortOrder, start, end);
  const [isDivided, setIsDivided] = useState(leftDistractionMm !== rightDistraction || leftClicksPerDay !== rightClicksPerDay);

  const toggleDivide = () => {
    setIsDivided(prev => !prev);

    if (isDivided && (leftDistractionMm !== rightDistraction || leftClicksPerDay !== rightClicksPerDay)) {
      changeValue(sortOrder, "distractionMm", rightDistraction ? rightDistraction.toString() : "", SIDE.LEFT);
      changeValue(sortOrder, "clicksPerDay", rightClicksPerDay ? rightClicksPerDay.toString() : "", SIDE.LEFT);
    }
  }

  return (
    <TableRow className={classes.tableRow} key={id}>
      <TableCell align="left">
        <Switch
          onChange={toggleDivide}
          value={isDivided}
          checked={isDivided}
          disableRipple />
      </TableCell>
      <TableCell width="10%" align="left">{periodText}</TableCell>
      <TableCell width={250} align="left">
        <div className={classes.inputCell}>
          <NumericInput
            id={`${rightDistraction}_${id}`}
            placeholder={"Enter cm"}
            formClassName={classes.formControl}
            handleChange={(event: any) =>
              changeValue(sortOrder, "distractionMm", event.target.value, isDivided ? SIDE.RIGHT : undefined)
            }
            inputClassName={classes.input}
            value={rightDistraction}
            helperClassName={classes.helper}
            maxLength={6}
          />
          {isDivided && (
            <NumericInput
              id={`${leftDistractionMm}_${id}`}
              placeholder={"Enter cm"}
              formClassName={classes.formControl}
              handleChange={(event: any) =>
                changeValue(sortOrder, "distractionMm", event.target.value, SIDE.LEFT)
              }
              inputClassName={classes.input}
              value={leftDistractionMm}
              helperClassName={classes.helper}
              maxLength={6}
            />
          )}
        </div>
      </TableCell>
      <TableCell width={250} align="left">
        <div className={classes.inputCell}>
          <NumericInput
            id={`${rightClicksPerDay}_${id}`}
            placeholder={"Enter clicks"}
            formClassName={classes.formControl}
            helperClassName={classes.helper}
            handleChange={(event: any) =>
              changeValue(sortOrder, "clicksPerDay", event.target.value, isDivided ? SIDE.RIGHT : undefined)
            }
            inputClassName={classes.input}
            value={rightClicksPerDay}
            maxLength={2}
          />
          {isDivided && (
            <NumericInput
              id={`${leftClicksPerDay}_${id}`}
              placeholder={"Enter clicks"}
              formClassName={classes.formControl}
              helperClassName={classes.helper}
              handleChange={(event: any) =>
                changeValue(sortOrder, "clicksPerDay", event.target.value, SIDE.LEFT)
              }
              inputClassName={classes.input}
              value={leftClicksPerDay}
              maxLength={2}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="left">{getDuration(duration)}</TableCell>
      <TableCell align="left">{getClicks(rightTotalClicks ?? 0, leftTotalClicks ?? 0, isDivided) || "-"}</TableCell>
      <TableCell align="left">{dayOfPeriod ?? 0}/{duration}</TableCell>
      <TableCell align="right">
        {(start && isBefore(start, new Date())) || totalAmount <= 1
          ? null
          : (
            <IconButton onClick={() => openModal(sortOrder)}>
              <Delete />
            </IconButton>
          )}
      </TableCell>
    </TableRow>
  );
}

const getDuration = (duration: number | null) => duration ? `${duration} ${(duration ?? 1) > 1 ? "days" : "day"}` : "-";

const getClicks = (left: number, right: number, isDivided: boolean) => {
  if (left === right && !isDivided) return `${left} clicks`;

  return `${right}/${left} clicks`;
}
