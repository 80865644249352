import { eachDayOfInterval, addDays, subDays } from "date-fns";

export const getIntervalFromCentralDate = (central: Date) => {
  const start = subDays(central, 6);
  const end = addDays(central, 5);

  const interval = eachDayOfInterval({
    start,
    end
  });

  return {start, end, interval};
};
