import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_EXERCISE_PERFORMANCE_DATA,
  GET_EXERCISE_PERFORMANCE_DATA_ERROR,
  GET_EXERCISE_PERFORMANCE_DATA_SUCCESS,
} from '../../actions';
import { PatientsAPI } from '../../../api/patientsAPI';
import { format } from 'date-fns';
import { ExercisePerformanceRawDataItem } from '../../../types/exercisePerformance';

function* getExercisePerformanceData(action: {
  type: string;
  payload: { id: string; dates: any };
}): Generator<any> {
  try {
    const {
      id,
      dates: { start, end },
    } = action.payload;

    const data = yield call(PatientsAPI.getExercisePerformance, {
      id,
      start: format(new Date(start), 'yyyy-MM-dd'),
      end: format(new Date(end), 'yyyy-MM-dd'),
    });

    yield put({
      type: GET_EXERCISE_PERFORMANCE_DATA_SUCCESS,
      payload: { data } as {
        data: ExercisePerformanceRawDataItem[];
      },
    });
  } catch (error: any) {
    console.log(error);
    // yield put({
    //   type: GET_EXERCISE_PERFORMANCE_DATA_ERROR,
    //   payload: { code: error.response?.status, message: error.message },
    // });
  }
}

export function* watcherGetExercisePerformance() {
  yield takeLatest(GET_EXERCISE_PERFORMANCE_DATA, getExercisePerformanceData);
}
