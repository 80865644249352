import { pathMapping, viewBoxMapping } from "../../utils/constants";
import { ISortSvg, ISvgIcon, SORT_SVG } from "../../utils/interfaces";

export const Sort = ({ className, type = SORT_SVG.DEFAULT }: ISvgIcon & ISortSvg) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox={viewBoxMapping[type]}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={pathMapping[type]} fill="#158AA4"/>
  </svg>
);
