import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CLICKING_DATA_REQUEST } from '../../../../../redux/actions';
import { clickingDataSelector } from '../../../../../redux/selectors/clickingDataSelector';
import { processClickingData } from '../../../../../utils/dataHandlers';
import classes from './ClickingOverview.module.scss';
import { Chart } from './components/Chart/Chart';
import { Legend } from './components/Legend/Legend';
import { PeriodToggle } from './components/PeriodToggle/PeriodToggle';

const legendData = [
  [
    {
      label: 'Right leg clicks',
      color: '#7897FF',
    },
    {
      label: 'Left leg clicks',
      color: '#032596',
    },
  ],
  [
    {
      label: 'Planned Right leg clicks',
      color: '#F2F5FF',
      border: '#7897FF',
    },
    {
      label: 'Planned Left leg clicks',
      color: '#E5EBFE',
      border: '#9BB2FD',
    },
  ],
  [
    {
      label: 'Right leg pain level',
      color: '#107BFA',
    },
    {
      label: 'Left leg pain level',
      color: '#BD10FA',
    },
  ],
  [
    {
      label: 'Accidental clicks',
      color: '#FFAC66',
    },
    {
      label: 'Time spend',
      color: '#4CCFFF',
    },
  ],
];

export const ClickingOverview = ({
  patientId,
  bone,
}: {
  patientId: string;
  bone: 'TIBIA' | 'FEMUR';
}) => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState(null);
  const clickingData = useSelector(clickingDataSelector);

  useEffect(() => {
    if (dates) {
      dispatch({
        type: GET_CLICKING_DATA_REQUEST,
        payload: { patientId, dates },
      });
    }
  }, [dates]);

  const processedData = clickingData ? processClickingData(clickingData) : null;

  return (
    <div className={classes.clickingOverviewContainer}>
      <div className={classes.chartHeader}>
        <PeriodToggle setDates={setDates} />
        <Legend legendData={legendData} />
      </div>
      <Chart clickingData={processedData} chartDates={dates} patientId={patientId} bone={bone} />
    </div>
  );
};
