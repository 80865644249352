import {
    createErrorSelector,
    createLoadingSelector
  } from "../../utils/reduxUtils";
  import { GET_CLICKING_DATA } from "../actions";
  
  export const clickingDataSelector = (state: any) => state.clickingData.clickingData;
  
  export const clickingDataLoadingSelector = createLoadingSelector([GET_CLICKING_DATA]);
  export const clickingDataErrorSelector = createErrorSelector([GET_CLICKING_DATA]);
  