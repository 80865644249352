import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_ACCESS_TOKEN_REQUEST } from "../../../redux/actions";
import { isAuthenticatedSelector } from "../../../redux/selectors/authSelector";
import { getAccessToken, getCodeFromUrl, getIsExpired, LOGIN_URL } from "../../../utils/authUtils";

export const RootNavigator = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const search = location.search;
  const code = useMemo(() => getCodeFromUrl(search), [search]);

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const isExpired = getIsExpired();
  const accessToken = getAccessToken();

  useEffect(() => {
    if (accessToken && !isExpired) {
      navigate("/patients");
    } else if (!isAuthenticated && !code) {
      window.open(LOGIN_URL, "_self");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((!isAuthenticated && code)) {
      dispatch({
        type: GET_ACCESS_TOKEN_REQUEST,
        payload: { code }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/patients");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return null;
};
