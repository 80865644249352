import React from 'react';
import classes from './Exercise.module.scss';
import { ExerciseItem as TExerciseItem } from '../../../../../types/exercises';
import { Close } from '../../../../../assets/icons/Close';

export type ExerciseItemProps = {
  onRemove?: ({ id }: { id: TExerciseItem['id'] }) => void;
  onClick: () => void;
  previewSrc: string;
  isTextPreview: string | null;
} & Pick<TExerciseItem, 'id' | 'state'>;

export const ExerciseItem = ({
  id,
  isTextPreview,
  previewSrc,
  onClick,
  onRemove,
}: ExerciseItemProps) => {
  const handleCloseClick = (event: React.MouseEvent) => {
    onRemove && onRemove({ id });

    event.stopPropagation();
  };

  return (
    <div onClick={onClick} className={classes.exerciseItem}>
      <img className={isTextPreview ?? classes.textPreview} src={previewSrc} alt="3D preview" />
      {onRemove && <Close onClick={handleCloseClick} />}
    </div>
  );
};
