import { ISvgIcon } from "../../utils/interfaces";

export const CheckboxInactive = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="16" height="16" rx="4" fill="#F9FAFB" />
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="4"
      stroke="#D1D5DB"
      strokeWidth="0.5"
    />
  </svg>
);
