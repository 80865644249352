import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import {
  GET_TREATMENT_TEMPLATE_ERROR,
  GET_TREATMENT_TEMPLATE_REQUEST,
  GET_TREATMENT_TEMPLATE_SUCCESS
} from "../../actions";

function* workerGetSingleTemplate(action: {
  type: string;
  payload: {
    id: string;
  };
}): Generator<any> {
  try {
    const data: any = yield call(
      TreatmentPlanTemplatesAPI.getSingleTreatmentTemplate,
      action.payload.id
    );
    yield put({
      type: GET_TREATMENT_TEMPLATE_SUCCESS,
      payload: {
        current: {
          [data.id]: data
        }
      }
    });
  } catch (error: any) {
    yield put({
      type: GET_TREATMENT_TEMPLATE_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherGetSingleTemplate() {
  yield takeLatest(GET_TREATMENT_TEMPLATE_REQUEST, workerGetSingleTemplate);
}
