import { CircularProgress, IconButton } from "@mui/material";
import classes from "./ViewPatientControls.module.scss";
import { AlarmsMenu } from "../../../../../assets/icons/AlarmsMenu";
import { EditPatient } from "../../../../../assets/icons/EditPatient";
import { ExternalLink } from "../../../../../assets/icons/ExternalLink";
import { LeftChevron } from "../../../../../assets/icons/LeftChevron";
import { Report } from "../../../../../assets/icons/Report";
import { IViewPatientControls } from "../../../../../utils/interfaces";
import { ActionButton } from "./ActionButton/ActionButton";
import { DeletePatient } from "../../../../../assets/icons/DeletePatient";
import { Password } from "../../../../../assets/icons/Password";
import { NOT_ACTIVATED } from "../../../../../utils/constants";
import { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { ClickingDataAPI } from "../../../../../api/clickingDataAPI";
import { formatClicksForReport } from "../../../../../utils/viewPatientUtils";

const headers = [
  { label: "Patient id", key: "id" },
  { label: "Gender", key: "gender" },
  { label: "Country", key: "country" },
  { label: "Initial Gap", key: "initialGapMm" },
  { label: "Initial Height", key: "initialHeightMm" },
  { label: "Left Femur Length", key: "leftFemurLengthMm" },
  { label: "Left Tibia Length", key: "leftTibiaLengthMm" },
  { label: "Nail Type", key: "nailTypeMm" },
  { label: "Operation Type", key: "operationType" },
  { label: "Right Femur Length", key: "rightFemurLengthMm" },
  { label: "Right Tibia Length", key: "rightTibiaLengthMm" },
  { label: "Target Distraction", key: "targetDistractionMm" },
  { label: "Wingspan", key: "wingSpanMm" },
  { label: "Date", key: "date" },
  { label: "Accidental Clicks (R / L)", key: "accidentalClicks" },
  { label: "Actual Clicks (R / L)", key: "actualClicks" },
  { label: "Pain Level (R / L)", key: "painLevel" },
  { label: "Planned Clicks (R / L)", key: "plannedClicks" },
  { label: "Time Spent (R / L)", key: "timeSpent" },
];

export const ViewPatientControls = ({
  deletePatient,
  editPatient,
  externalLink,
  navigateBack,
  patientStatus,
  sendPassword,
  showAlarms,
  patient,
  activeNotificationCount,
}: IViewPatientControls) => {
  const [reportData, setReportData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const linkRef = useRef<any>(null);

  const getReportData = async () => {
    if (!patient) return

    try {
      setLoading(true);
      const result = await ClickingDataAPI.getClickingData(patient.id, patient.treatmentPlan.startDate, patient.treatmentPlan.endDate);
      const formattedReport = formatClicksForReport(result);

      const patientData = {
        id: patient.id,
        gender: patient.gender,
        country: patient.country,
      };
      const correctReportData = [patient.treatmentData, patientData, ...formattedReport];
      setReportData(correctReportData);
      if (linkRef.current) {
        linkRef.current.link?.click();
      }
    } catch (e) {
      setReportData([]);
    } finally {
      setLoading(false);
    }
  }

  const actions = [
    patientStatus === NOT_ACTIVATED
      ? {
        id: "resend-password",
        name: "Resend Pass",
        description: "Send pass",
        icon: <Password />,
        action: sendPassword,
        buttonClassName: classes.password
      }
      : {
        id: "view-report",
        name: "Report",
        description: "Excel report",
        icon: <>
          {loading
            ? <CircularProgress size={25} />
            : <div
              className={classes.linkLoadingWrapper}
              onClick={getReportData}>
              <Report />
            </div>}
          <CSVLink
            ref={linkRef}
            data={reportData}
            headers={headers}
            filename="Patient-Info.csv" />
        </>,
        action: () => { },
        buttonClassName: classes.report
      },
    {
      id: "external-link",
      name: "External Link",
      description: "Some small",
      icon: externalLink
        ? <Link
          className={classes.externalLink}
          to={externalLink}
          target="_blank"
          rel="noopener noreferrer">
          <ExternalLink />
        </Link>
        : <ExternalLink className={classes.disabledExternalLink} />,
      action: () => { },
      buttonClassName: classes.link
    },
    {
      id: "edit-patient",
      name: "Edit Patient Details",
      description: "Change the data",
      icon: <EditPatient />,
      action: editPatient,
      buttonClassName: classes.edit
    },
    {
      id: "delete-patient",
      name: "Delete patient",
      description: "Delete Account",
      icon: <DeletePatient />,
      action: deletePatient,
      buttonClassName: classes.delete
    },
    {
      id: "alarms",
      name: "Alarms",
      description: "0 alarms",
      icon: <AlarmsMenu />,
      action: showAlarms,
      buttonClassName: classes.alarms
    }
  ];

  const alarmsText = useMemo(() => {
    if (activeNotificationCount === 1) return "1 alarm";

    return `${activeNotificationCount} alarms`;
  }, [activeNotificationCount]);

  return (
    <div className={classes.controlsContainer}>
      <div className={classes.navigationControls}>
        <IconButton className={classes.back} onClick={() => navigateBack()}>
          <LeftChevron />
        </IconButton>
        <h2>All Patients</h2>
      </div>

      <div className={classes.controls}>
        {actions.map(action => (
          <ActionButton
            {...action}
            disabled={action.id === "external-link" && !externalLink}
            description={action.id === "alarms" ? alarmsText : action.description}
            key={action.id} />
        ))}
      </div>
    </div>
  );
};
