import { Divider } from "@mui/material";
import { EDIT } from "../../../../../utils/constants";
import {
  personalInfoInputs,
  treatmentInfoInputs
} from "../../../../../utils/inputUtils";
import { DateField } from "../../../../common/InputComponents/DateField/DateField";
import { SelectField } from "../../../../common/InputComponents/SelectField/SelectField";
import { TextInput } from "../../../../common/InputComponents/TextInput/TextInput";
import classes from "./PatientInfo.module.scss";

export const PatientInfo = ({ errorDate, countries, formik, setErrorDate, mode, hasTreatmentPlan }: any) => {
  const personalInfo = personalInfoInputs(countries);

  return (
    <div className={classes.patientInfoContainer}>
      <div className={classes.personalInfo}>
        <h2>Personal Data</h2>
        {personalInfo.map(input => {
          if (input.type === "select") {
            return (
              <SelectField
                key={input.id}
                {...input}
                formik={formik}
                selectRootClassName={classes.selectRoot}
                selectInputClassName={classes.selectInput}
                labelClassName={classes.label}
                formClassName={classes.formControl}
                helperClassName={classes.helper}
                type="text"
              />
            );
          }
          if (input.type === "date") {
            return (
              <DateField
                key={input.id}
                id={input.id}
                error={errorDate}
                label={input.label}
                formik={formik}
                placeholder={"DD.MM.YYYY"}
                inputClassName={classes.dateInput}
                helperClassName={classes.helper}
                labelClassName={classes.label}
                formClassName={classes.formControl}
                setError={setErrorDate}
              />
            );
          }
          return (
            <TextInput
              key={input.id}
              {...input}
              formik={formik}
              disabled={mode === EDIT && input.shouldDisable}
              required={input?.required}
              inputClassName={classes.input}
              helperClassName={classes.helper}
              labelClassName={classes.label}
              formClassName={classes.formControl}
            />
          );
        })}
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.treatmentInfo}>
        <h2>Treatment Data</h2>
        {treatmentInfoInputs.map(input => {
          if (input.type === "select") {
            return (
              <SelectField
                key={input.id}
                {...input}
                formik={formik}
                disabled={mode === EDIT && hasTreatmentPlan}
                required={false}
                selectRootClassName={classes.selectRoot}
                selectInputClassName={classes.selectInput}
                labelClassName={classes.label}
                formClassName={classes.formControl}
                type="text"
              />
            );
          }
          return (
            <TextInput
              key={input.id}
              {...input}
              formik={formik}
              required={false}
              inputClassName={classes.input}
              helperClassName={classes.helper}
              labelClassName={classes.label}
              formClassName={classes.formControl}
            />
          );
        })}
        <div className={classes.fillerContainer} />
      </div>
    </div>
  );
};
