import { call, takeLatest, put } from 'redux-saga/effects';
import { ToastType } from '../../../utils/interfaces';
import {
  ASSIGN_EXERCISES,
  ASSIGN_EXERCISES_ERROR,
  ASSIGN_EXERCISES_SUCCESS,
  GET_PATIENT_EXERCISES,
  GET_PATIENT_EXERCISES_ERROR,
  GET_PATIENT_EXERCISES_SUCCESS,
  SET_EXERCISE_DURATION,
  SET_TOAST,
} from '../../actions';
import { PatientsAPI } from '../../../api/patientsAPI';

function* setExerciseDuration({
  type,
  payload: { id, totalMinutes },
}: {
  type: string;
  payload: {
    id: string;
    totalMinutes: number;
  };
}): Generator<any> {
  try {
    const data = yield call(PatientsAPI.setExerciseDuration, { id, totalMinutes });

    // yield put({
    //   type: ASSIGN_EXERCISES_SUCCESS,
    //   payload: {
    //     assignExercisesSuccessShowModal: true,
    //   },
    // });
  } catch (error: any) {
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: error.message,
        type: ToastType.ERROR,
      },
    });
    yield put({
      type: ASSIGN_EXERCISES_ERROR,
      // payload: { isError: true },
    });
  }
}

export function* watcherSetExerciseDuration() {
  yield takeLatest(SET_EXERCISE_DURATION, setExerciseDuration);
}
