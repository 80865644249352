import { ISvgIcon } from "../../utils/interfaces";

export const DeletePatient = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 18.5C2.45 18.5 1.97933 18.3043 1.588 17.913C1.196 17.521 1 17.05 1 16.5V3.5H0V1.5H5V0.5H11V1.5H16V3.5H15V16.5C15 17.05 14.8043 17.521 14.413 17.913C14.021 18.3043 13.55 18.5 13 18.5H3ZM13 3.5H3V16.5H13V3.5ZM5 14.5H7V5.5H5V14.5ZM9 14.5H11V5.5H9V14.5Z"
      fill="#FF4C4D"
    />
  </svg>
);
