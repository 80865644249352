// data we are receiving from backend
export type ExercisePerformanceRawDataItem = {
  id: string;
  exerciseGroupDto: {
    id: number;
    name: string;
  };
  date: string;
  time00_02: number;
  time02_04: number;
  time04_06: number;
  time06_08: number;
  time08_10: number;
  time10_12: number;
  time12_14: number;
  time14_16: number;
  time16_18: number;
  time18_20: number;
  time20_22: number;
  time22_24: number;
};

export enum durationMap {
  time00_02 = '12 am',
  time02_04 = '2 am',
  time04_06 = '4 am',
  time06_08 = '6 am',
  time08_10 = '8 am',
  time10_12 = '10 am',
  time12_14 = '12 pm',
  time14_16 = '2 pm',
  time16_18 = '4 pm',
  time18_20 = '6 pm',
  time20_22 = '8 pm',
  time22_24 = '10 pm',
  time24_00 = '00 am',
}

// normalized ExercisePerformanceRawDataItem data
export type NormalizedPerfDataItem = {
  id: ExercisePerformanceRawDataItem['id'];
  exerciseGroupId: ExercisePerformanceRawDataItem['exerciseGroupDto']['id'];
  exerciseGroupName: ExercisePerformanceRawDataItem['exerciseGroupDto']['name'];
  date: ExercisePerformanceRawDataItem['date'];
  timeKey: durationMap;
  value: ExercisePerformanceRawDataItem['time00_02'];
};

// exercise time per date
export type PerfTotalTimeItem = {
  date: string;
  hours: number;
  hoursLabel: string;
  totalTime: number;
};
