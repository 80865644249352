import classes from './PrivacyPolicy.module.scss';

export const PrivacyPolicy = () => {
  return (
    <div className={classes.container}>
      <h1>Statement of Privacy Policy</h1>
      <p>BeckerBetz (hereinafter called “We”, “Our Company” or “BeckerBetz”) is committed to protecting your privacy. This Statement of Privacy Policy (“Privacy Policy”) applies to services provided under BeckerBetz’s website and mobile application (“Services”) and governs personal data collection and usage. By using the Services, you consent to the collection, use, disclosure of personal data and other descriptions in the Privacy Policy.</p>

      <h2>1. Collection of your personal information</h2>
      <p>BeckerBetz collects personally identifiable information, such as your e-mail address, name, photo, telephone number, geographical location and/or health and wellness-related data. BeckerBetz also collects anonymous demographic information, which is not unique to you, such as your age, country, birthday, preferences.</p>
      <p>There is also information about your computer or portable device hardware and software that is automatically collected by BeckerBetz. This information can include: your in-app usage information, IP address, device ID, browser type, domain names, access times, referring website addresses. This information is used by BeckerBetz for the operation of the Services, to maintain quality of the Services, and to provide general statistics regarding use of the Services.</p>
      <p>Please keep in mind that if you directly disclose your personally identifiable information or personally sensitive data through public message boards, other places provided by the Services, this information may be collected and used by others.</p>

      <h2>2. Use of your Personal Information</h2>
      <p>BeckerBetz collects and uses your personally identifiable information to identify the users, respond to users’ requests or inquiries, operate the Services, deliver the Services you have requested and operate, evaluate and improve our business, including but not limited to product development, mobile application development, communications, data analytics, accounting, auditing and research.</p>
      <p>BeckerBetz may make records of the websites and pages our customers visit when using services provided by BeckerBetz, in order to determine what BeckerBetz services are the most popular among BeckerBetz’s customers or users.</p>

      <h2>3. Disclosure of your Personal Information</h2>
      <p>BeckerBetz may share your personally identifiable information with our affiliates and other parties with your consent to help us to perform the Services, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are required to maintain the same level of protection of personally identifiable information as stated in this Privacy Policy.</p>
      <p>BeckerBetz does not sell, rent or lease its customer lists to third parties without your explicit consent.</p>
      <p>BeckerBetz does not use or disclose sensitive personal information, such as race, religion, or political affiliations, except as required by the applicable law or your explicit consent is obtained.</p>

      <h2>4. Security of your Personal Data</h2>
      <p>BeckerBetz will use its reasonable commercial endeavors to maintain in place security measures and monitoring procedures to protect your personal data and prevent unauthorized or accidental access, processing, erasure, loss or use of the data. All personnel that may access the data will be ensured complying with the requirements of data protection.</p>

      <h2>5. Data Retention Policy</h2>
      <p>BeckerBetz will retain your personal data as long as we need it for providing the Services to you and for a reasonable time after termination of the Services. Unless otherwise requested by you or required or permitted by the applicable law, we will retain your personal data for no more than 24 months following account deletion.</p>

      <h2>6. Changes to Privacy Policy</h2>
      <p>The Privacy Policy may be updated from time to time for any reason including customers’ feedback. BeckerBetz will post the updated Privacy Policy here and encourage you to periodically review this Privacy Policy.</p>

      <h2>7. Enquiry</h2>
      <p>If you have any query in relation to the Privacy Policy or you wish to revoke your consent to this Privacy Policy or access, delete or correct your personal data held by BeckerBetz, you can send your request by email. We will process your request in reasonable time.</p>

      <h2>8. Language</h2>
      <p>In the event of any inconsistencies or discrepancies between the English version and any other translated versions of this Privacy Policy, the English version shall prevail.</p>
    </div>
  )
}
