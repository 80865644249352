import { COGNITO_CODE_REGEX } from "./regexUtils";

export const LOGIN_URL = `${process.env.REACT_APP_COGNITO_URL}/login?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

export const LOGOUT_URL = `${process.env.REACT_APP_COGNITO_URL}/logout?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;

export const ACCESS_TOKEN_URL = `${process.env.REACT_APP_COGNITO_URL}/oauth2/token`;

export const TIMEOUT = 600000 * 1000;

export const setAccessToken = (token: string) => {
  try {
    sessionStorage.setItem("accessToken", token);
  } catch (error) {
    throw error;
  }
};

export const getAccessToken = () => {
  try {
    return sessionStorage.getItem("accessToken");
  } catch (error) {
    throw error;
  }
};

export const getAccessExpiration = () => {
  try {
    return sessionStorage.getItem("expiresIn");
  } catch (error) {
    throw error;
  }
};

export const setAccessExpiration = (expiresIn: number) => {
  try {
    const expiryDate = Date.now() + expiresIn * 1000;
    sessionStorage.setItem("expiresIn", expiryDate.toString());
  } catch (error) {
    throw error;
  }
};

export const getRefreshToken = () => {
  try {
    return sessionStorage.getItem("refreshToken");
  } catch (error) {
    throw error;
  }
};

export const setRefreshToken = (token: string) => {
  try {
    sessionStorage.setItem("refreshToken", token);
  } catch (error) {
    throw error;
  }
};

export const getIsExpired = () => {
  try {
    const expiresIn = sessionStorage.getItem("expiresIn");
    return Date.now() > parseInt(expiresIn ?? "", 10);
  } catch (error) {
    throw error;
  }
};

export const getCodeFromUrl = (url: string) => {
  return (
    (COGNITO_CODE_REGEX.test(url) && url.match(COGNITO_CODE_REGEX)?.[1]) || null
  );
};
