import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_TEMPLATE_REQUEST,
  GET_TREATMENT_TEMPLATES_REQUEST
} from "../../../redux/actions";
import { templatesSelector } from "../../../redux/selectors/treatmentPlansSelector";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { Modal } from "../../common/Modal/Modal";
import { NoDataPage } from "../../common/NoDataPage/NoDataPage";
import { TreatmentPlanControls } from "./components/TreatmentPlanControls/TreatmentPlanControls";
import { TreatmentPlanTable } from "./components/TreatmentPlanTable/TreatmentPlanTable";

export const TreatmentPlanPage = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const treatmentPlans = useSelector(templatesSelector);

  useEffect(() => {
    dispatch({
      type: GET_TREATMENT_TEMPLATES_REQUEST,
      payload: { searchString: searchText }
    });
  }, [dispatch, searchText]);

  const openModal = (id: number) => {
    setOpen(true);
    setIdToDelete(id);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const deleteTemplate = () => {
    dispatch({
      type: DELETE_TEMPLATE_REQUEST,
      payload: { id: idToDelete, shouldLoadList: true, searchString: searchText }
    });
    closeModal();
  };

  return (
    <FullPageContainer>
      <>
        <TreatmentPlanControls updateSearchParams={setSearchText} />
        {treatmentPlans && treatmentPlans.length ? (
          <TreatmentPlanTable
            data={treatmentPlans}
            deleteTemplate={openModal}
          />
        ) : (
          <NoDataPage
            message="No results found"
            description="Please, change the search criteria"
          />
        )}
        <Modal
          deleteConfirmation
          open={open}
          title={"Delete template"}
          message={"Are you sure you want to delete this treatment plan?"}
          handleConfirm={deleteTemplate}
          handleCancel={closeModal}
        />
      </>
    </FullPageContainer>
  );
};
