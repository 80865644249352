import { ISvgIcon } from "../../utils/interfaces";

export const Patients = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V16H0ZM18 16V13C18 12.2667 17.796 11.5623 17.388 10.887C16.9793 10.2123 16.4 9.63333 15.65 9.15C16.5 9.25 17.3 9.42067 18.05 9.662C18.8 9.904 19.5 10.2 20.15 10.55C20.75 10.8833 21.2083 11.254 21.525 11.662C21.8417 12.0707 22 12.5167 22 13V16H18ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM18 4C18 5.1 17.6083 6.04167 16.825 6.825C16.0417 7.60833 15.1 8 14 8C13.8167 8 13.5833 7.97933 13.3 7.938C13.0167 7.896 12.7833 7.85 12.6 7.8C13.05 7.26667 13.3957 6.675 13.637 6.025C13.879 5.375 14 4.7 14 4C14 3.3 13.879 2.625 13.637 1.975C13.3957 1.325 13.05 0.733333 12.6 0.2C12.8333 0.116666 13.0667 0.0623335 13.3 0.0370002C13.5333 0.0123335 13.7667 0 14 0C15.1 0 16.0417 0.391667 16.825 1.175C17.6083 1.95833 18 2.9 18 4ZM2 14H14V13.2C14 13.0167 13.9543 12.85 13.863 12.7C13.771 12.55 13.65 12.4333 13.5 12.35C12.6 11.9 11.6917 11.5623 10.775 11.337C9.85833 11.1123 8.93333 11 8 11C7.06667 11 6.14167 11.1123 5.225 11.337C4.30833 11.5623 3.4 11.9 2.5 12.35C2.35 12.4333 2.22933 12.55 2.138 12.7C2.046 12.85 2 13.0167 2 13.2V14ZM8 6C8.55 6 9.021 5.804 9.413 5.412C9.80433 5.02067 10 4.55 10 4C10 3.45 9.80433 2.97933 9.413 2.588C9.021 2.196 8.55 2 8 2C7.45 2 6.97933 2.196 6.588 2.588C6.196 2.97933 6 3.45 6 4C6 4.55 6.196 5.02067 6.588 5.412C6.97933 5.804 7.45 6 8 6Z"
      fill="#A0A3BD"
    />
  </svg>
);
