import { createErrorSelector, createLoadingSelector } from '../../utils/reduxUtils';
import { GET_PATIENTS, GET_PATIENT, CREATE_PATIENT, EDIT_PATIENT } from '../actions';
import { ExercisePerformanceRawDataItem } from '../../types/exercisePerformance';

export const patientsSelector = (tab: string) => (state: any) => state.patients?.[tab];

export const patientsPaginationSelector = (state: any) => state.patients.pagination;

export const patientsLoadingSelector = createLoadingSelector([GET_PATIENTS]);
export const patientsErrorSelector = createErrorSelector([GET_PATIENTS]);

export const currentPatientSelector = (id: string) => (state: any) => state.patients.current?.[id];

export const patientLoadingSelector = createLoadingSelector([GET_PATIENT]);
export const patientErrorSelector = createErrorSelector([GET_PATIENT]);

export const createPatientErrorSelector = createErrorSelector([CREATE_PATIENT]);
export const editPatientErrorSelector = createErrorSelector([EDIT_PATIENT]);

export const exercisesActiveStatusError = (state: any) => state.patients.exercisesActiveStatusError;
export const assignExercisesSuccessShowModal = (state: any) =>
  state.patients.assignExercisesSuccessShowModal;

export const assignExercisesErrorShowModal = (state: any) =>
  state.patients.assignExercisesErrorShowModal;

export const getCurrentPatient = (state: any) => state.patients.currentPatient;
export const getExercisePerformanceData = (state: any) =>
  state.patients.currentPatient?.exercisePerformanceData as ExercisePerformanceRawDataItem[];

export const getTableState = (state: any) => ({
  tableYScrollPosition: state.patients.tableYScrollPosition,
  tab: state.patients.tab,
});

export const getTotalPatients = (state: any) => [
  state.patients.totalActive,
  state.patients.totalFinished,
];
