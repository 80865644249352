import React from 'react';
import { useDrag } from 'react-dnd';
import classes from './Exercise.module.scss';
import { ExerciseItem as TExerciseItem } from '../../../../../types/exercises';
import { Close } from '../../../../../assets/icons/Close';

export type ExerciseItemProps = {
  onRemove?: ({ id }: { id: TExerciseItem['id'] }) => void;
  onClick: () => void;
  previewSrc: string;
  isTextPreview: string | null;
} & Pick<TExerciseItem, 'id' | 'state'>;

export const ExerciseItem = ({
  isTextPreview,
  id,
  state,
  previewSrc,
  onClick,
  onRemove,
}: ExerciseItemProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: state,
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleCloseClick = (event: React.MouseEvent) => {
    onRemove && onRemove({ id });

    event.stopPropagation();
  };

  return (
    <div
      onClick={onClick}
      ref={drag}
      className={classes.exerciseItem}
      style={{ opacity: isDragging ? 0.5 : 1 }}>
      <img className={isTextPreview ?? classes.textPreview} src={previewSrc} alt="3D preview" />
      {onRemove && <Close onClick={handleCloseClick} />}
    </div>
  );
};
