import { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import classes from "./SearchField.module.scss";
import { ClearSearch } from "../../../assets/icons/ClearSearch";
import { Search } from "../../../assets/icons/Search";
import { ISearchField } from "../../../utils/interfaces";

export const SearchField = ({
  className,
  updateSearchParams,
  placeholder
}: ISearchField) => {
  const [value, setValue] = useState("");

  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    clearTimeout(timeout);
    
    if (value === "") updateSearchParams(value);
    else {
      // eslint-disable-next-line
      timeout = setTimeout(() => {
        if (value.length >= 3) {
          updateSearchParams(value);
        }
      }, 1300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const clearField = () => {
    if (value) {
      setValue("");
    }
  };

  return (
    <TextField
      classes={{ root: className }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            className={classes.clearSearch}
            onClick={() => clearField()}
            position="end"
          >
            <ClearSearch />
          </InputAdornment>
        )
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      placeholder={placeholder}
      value={value}
    />
  );
};
