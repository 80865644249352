import { format, parse } from 'date-fns';
import {
  durationMap,
  ExercisePerformanceRawDataItem,
  NormalizedPerfDataItem,
  PerfTotalTimeItem,
} from '../types/exercisePerformance';

export function normalizeData(data: ExercisePerformanceRawDataItem[]) {
  let normalized: NormalizedPerfDataItem[] = [];

  data.forEach((entry) => {
    Object.entries(entry).forEach(([key, value]) => {
      if (key.startsWith('time')) {
        normalized.push({
          id: entry.id,
          exerciseGroupId: entry.exerciseGroupDto.id,
          exerciseGroupName: entry.exerciseGroupDto.name,
          date: entry.date,
          timeKey: durationMap[key as keyof typeof durationMap],
          value: value as number,
        });
      }
    });
  });

  normalized.sort((a, b) => a.date.localeCompare(b.date));
  normalized = normalized.map((item) => ({
    ...item,
    date: format(parse(item.date, 'yyyy-MM-dd', new Date()), 'dd/MM/yy'),
  }));

  normalized.push({
    id: normalized[0].id,
    exerciseGroupId: normalized[0].exerciseGroupId,
    exerciseGroupName: normalized[0].exerciseGroupName,
    date: normalized[0].date,
    timeKey: '00 am' as durationMap,
    value: 0,
  });

  return normalized;
}

type TotalTime = {
  date: string;
  totalTime: number;
};

export const getTotalTimePerDate = (data: NormalizedPerfDataItem[]) => {
  const totalTime = data.reduce((result: TotalTime[], item) => {
    const dateIndex = result.findIndex((resultItem) => resultItem.date === item.date);
    if (!result[dateIndex]) {
      result.push({
        date: item.date,
        totalTime: item.value,
      });
    } else {
      result[dateIndex].totalTime += item.value;
    }
    return result;
  }, []);

  const totalTimePerDateBasic: PerfTotalTimeItem[] = totalTime.map((item) => {
    const newItem: any = { ...item };
    newItem.hours = Math.floor(item.totalTime / 60);
    newItem.hoursLabel = '0 hrs';
    return newItem as PerfTotalTimeItem;
  });

  const forYLabels = Array.from({ length: 9 }, (_, i) => ({
    hoursLabel: i + ' hrs',
    hours: 0,
    date: totalTimePerDateBasic[0].date,
    totalTime: 0,
    type: 'forYaxis',
  }));

  return [...totalTimePerDateBasic, ...forYLabels];
};
