import axios from "axios";
import { ACCESS_TOKEN_URL } from "../utils/authUtils";

const axiosClient = axios.create();

export class AuthAPI {
  static get getAccessTokenServiceName() {
    return ACCESS_TOKEN_URL;
  }

  static async getAccessToken(code: string) {
    const url = AuthAPI.getAccessTokenServiceName;
    const params = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      code
    };
    const response = await axiosClient.post(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    });
    return response.data;
  }
}
