import {
  createErrorSelector,
  createLoadingSelector
} from "../../utils/reduxUtils";
import { GET_ALARMS } from "../actions";

export const alarmsSelector = (state: any) => state.patients.alarms;
export const toastSelector = (state: any) => state.toast;

export const alarmsLoadingSelector = createLoadingSelector([GET_ALARMS]);
export const alarmsErrorSelector = createErrorSelector([GET_ALARMS]);
