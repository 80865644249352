import {
  createErrorSelector,
  createLoadingSelector
} from "../../utils/reduxUtils";
import { GET_TREATMENT_TEMPLATE, GET_TREATMENT_TEMPLATES, RECALCULATE_PATIENT_TREATMENT_TEMPLATE, RECALCULATE_TREATMENT_TEMPLATE } from "../actions";

export const templatesSelector = (state: any) => state.treatmentPlans.treatmentPlans;

export const templatesLoadingSelector = createLoadingSelector([
  GET_TREATMENT_TEMPLATES
]);
export const templatesErrorSelector = createErrorSelector([GET_TREATMENT_TEMPLATES]);

export const currentTemplateSelector = (id: string) => (state: any) => state.treatmentPlans.current?.[id];

export const currentTemplateLoadingSelector = createLoadingSelector([
  GET_TREATMENT_TEMPLATE
]);
export const currentTemplateErrorSelector = createErrorSelector([GET_TREATMENT_TEMPLATE]);

export const draftTemplateSelector = (state: any):any => state.treatmentPlans.draft;

export const draftTemplateLoadingSelector = createLoadingSelector([
  RECALCULATE_TREATMENT_TEMPLATE
]);
export const draftTemplateErrorSelector = createErrorSelector([RECALCULATE_TREATMENT_TEMPLATE]);

export const patientTemplateLoadingSelector = createLoadingSelector([
  RECALCULATE_PATIENT_TREATMENT_TEMPLATE
]);
export const patientTemplateErrorSelector = createErrorSelector([RECALCULATE_PATIENT_TREATMENT_TEMPLATE]);
export const patientTemplateSelector = (state: any):any => state.treatmentPlans.patientPlan;