import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddPeriod } from "../../../../../assets/icons/AddPeriod";
import { Delete } from "../../../../../assets/icons/Delete";
import { SET_TOAST } from "../../../../../redux/actions";
import { uniqueId } from "../../../../../utils/commonUtils";
import { defaultPeriod } from "../../../../../utils/inputUtils";
import { ITreatmentPlanPeriod, ToastType } from "../../../../../utils/interfaces";
import { Modal } from "../../../../common/Modal/Modal";
import classes from "./CreateEditTreatmentTimeline.module.scss";
import { NumericInput } from "./NumericInput/NumericInput";

export const CreateEditTreatmentTimeline = ({
  formik,
  periods,
  setPeriods,
  recalculateTemplate
}: any) => {
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const dispatch = useDispatch();

  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  const setTimeoutToRecalculate = useCallback(
    (periods: any) => {
      if (timeout) clearTimeout(timeout);
      // eslint-disable-next-line
      timeout = setTimeout(() => {
        recalculateTemplate(periods);
      }, 1300);
    },
    [formik.values]
  );

  const openModal = (idToDelete: number) => {
    setOpen(true);
    setIdToDelete(idToDelete);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const deletePeriod = () => {
    closeModal();
    const newPeriods = periods
      .filter((item: ITreatmentPlanPeriod) => item.id !== idToDelete)
      .map((item: ITreatmentPlanPeriod, index: number) => {
        return { ...item, sortOrder: index + 1 };
      });
    setPeriods(newPeriods);
    recalculateTemplate(newPeriods);
    dispatch({
      type: SET_TOAST,
      payload: {
        open: true,
        message: "Period was successfully deleted",
        type: ToastType.SUCCESS,
      }
    })
  };

  const addPeriod = () => {
    if (periods?.length) {
      const sortOrder = periods[periods.length - 1].sortOrder + 1;
      const newPeriods = [
        ...periods,
        { ...defaultPeriod, sortOrder, id: uniqueId() }
      ];
      setPeriods(newPeriods);
    } else {
      setPeriods([defaultPeriod]);
    }
  };

  const changeCmForPeriod = (idToChange: number, value: string) => {
    let shouldRecalculate = false;
    const newPeriods = periods.map((item: ITreatmentPlanPeriod) => {
      if (item.id === idToChange) {
        if (item.clicksPerDay && value) shouldRecalculate = true;
        return { ...item, distractionMm: value };
      }
      return item;
    });
    setPeriods(newPeriods);
    if (shouldRecalculate) {
      setTimeoutToRecalculate(newPeriods);
    }
  };

  const changeClicksPerDay = (idToChange: number, value: string) => {
    let shouldRecalculate = false;
    const newPeriods = periods.map((item: ITreatmentPlanPeriod) => {
      if (item.id === idToChange) {
        if (item.distractionMm && value) shouldRecalculate = true;
        return { ...item, clicksPerDay: value };
      }
      return item;
    });
    setPeriods(newPeriods);
    if (shouldRecalculate) {
      setTimeoutToRecalculate(newPeriods);
    }
  };

  return (
    <div className={classes.treatmentTimelineContainer}>
      <h2>Treatment Timeline</h2>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left">Period</TableCell>
            <TableCell align="left">Cm for Period</TableCell>
            <TableCell align="left">Clicks Per Day</TableCell>
            <TableCell align="left">Duration</TableCell>
            <TableCell align="left">Amount of Clicks</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {periods &&
            periods.map(
              ({
                id,
                distractionMm,
                clicksPerDay,
                duration,
                totalClicks,
                sortOrder
              }: ITreatmentPlanPeriod) => {
                return (
                  <TableRow className={classes.tableRow} key={id}>
                    <TableCell align="left">{`Period ${sortOrder}`}</TableCell>
                    <TableCell align="left">
                      <NumericInput
                        id={`${distractionMm}_${id}`}
                        placeholder={"Enter cm"}
                        formClassName={classes.formControl}
                        handleChange={(event: any) =>
                          changeCmForPeriod(id, event.target.value)
                        }
                        inputClassName={classes.input}
                        value={distractionMm}
                        helperClassName={classes.helper}
                        maxLength={6}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <NumericInput
                        id={`${clicksPerDay}_${id}`}
                        placeholder={"Enter clicks"}
                        formClassName={classes.formControl}
                        helperClassName={classes.helper}
                        handleChange={(event: any) =>
                          changeClicksPerDay(id, event.target.value)
                        }
                        inputClassName={classes.input}
                        value={clicksPerDay}
                        maxLength={2}
                      />
                    </TableCell>
                    <TableCell align="left">{duration || "-"}</TableCell>
                    <TableCell align="left">{totalClicks || "-"}</TableCell>
                    <TableCell align="right">
                      {periods.length > 1 && (
                        <IconButton onClick={() => openModal(id)}>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <Button
          className={classes.addPeriod}
          onClick={() => addPeriod()}
          startIcon={<AddPeriod />}
          variant="text"
        >
          Add period
        </Button>
      </div>
      <Modal
        deleteConfirmation
        open={open}
        title={"Delete period"}
        message={"Are you sure that you want to delete period?"}
        handleConfirm={deletePeriod}
        handleCancel={closeModal}
      />
    </div>
  );
};
