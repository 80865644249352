import { ISvgIcon } from "../../utils/interfaces";

export const RightFemur = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="5"
    height="12"
    viewBox="0 0 5 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5048 1.85808C0.49109 0.810487 1.35605 -0.0358005 2.40309 0.000763145C3.57226 0.0415912 4.40937 1.15254 4.16989 2.29765C3.93381 3.42647 3.6856 4.78317 3.59642 5.85645C3.44886 7.63232 3.23618 10.1971 3.15185 11.2142C3.13141 11.4608 2.92103 11.6469 2.67378 11.6382C2.52125 11.6329 2.38111 11.5528 2.29907 11.4241L2.2831 11.3991C2.09799 11.1087 1.68022 11.0915 1.47193 11.3658L1.46295 11.3776C1.37135 11.4982 1.22688 11.5668 1.07553 11.5615C0.826975 11.5528 0.629025 11.3505 0.625771 11.1018L0.5048 1.85808Z"
      fill="#D9D9D9"
    />
  </svg>
);
