import {
  createErrorSelector,
  createLoadingSelector
} from "../../utils/reduxUtils";
import { GET_COUNTRIES } from "../actions";

export const countriesSelector = (state: any) => state.countries.countries;

export const countriesLoadingSelector = createLoadingSelector([GET_COUNTRIES]);
export const countriesErrorSelector = createErrorSelector([GET_COUNTRIES]);
