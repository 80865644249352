import { ISvgIcon } from "../../utils/interfaces";

export const RightTibia = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="3"
    height="12"
    viewBox="0 0 3 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.619062 1.59894C0.610158 0.918532 1.175 0.370518 1.85483 0.399992C2.52696 0.429133 3.03993 1.01149 2.98405 1.68193C2.88177 2.90894 2.72333 4.81147 2.60586 6.22967C2.47681 7.78765 2.43967 9.9566 2.42905 11.151C2.4248 11.6288 2.02663 12.0105 1.54921 11.9908C1.10357 11.9725 0.75006 11.6089 0.744224 11.1629L0.619062 1.59894Z"
      fill="#D9D9D9"
    />
  </svg>
);
