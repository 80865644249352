import { LinearProgress } from "@mui/material";
import { IProgress } from "../../../utils/interfaces";
import classes from "./Progress.module.scss";

export const Progress = ({ progress = 0, overall }: IProgress) => {
  const getValue = () => {
    if (!progress) return 0;
    return (progress * 100) / overall;
  };
  
  return (
    <>
      <span className={classes.progressInfo}>
        {progress} of {overall} days
      </span>
      <LinearProgress variant="determinate" value={getValue()} />
    </>
  );
};
