export const createLoadingSelector = (actions: any) => (state: any) =>
  actions.some((action: string) => state.api.loading[action]);

export const createErrorSelector = (actions: any) => (state: {
  api: { error: any };
}) => {
  const errorObj =
    state.api.error[actions.find((action: string) => state.api.error[action])];
  return typeof errorObj === "object" ? errorObj.message || "" : errorObj || "";
};

export const updateState = (state: object, payload: any) => {
  return {
    ...state,
    ...payload
  };
};

export function updateOneEntity(state: any, payload: any) {
  const dataKey = Object.keys(payload)[0];
  const newData = {
    [dataKey]:
      state[dataKey] || payload[dataKey]
        ? {
            ...state[dataKey],
            ...payload[dataKey]
          }
        : null
  };
  return {
    ...state,
    ...newData
  };
}
