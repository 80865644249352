import { Dialog } from '@mui/material';
import { Canvas, useLoader } from '@react-three/fiber';
// @ts-ignore
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';
import { OrbitControls, Environment } from '@react-three/drei';
import { Close } from '../../../assets/icons/Close';
import classes from './ModelModal.module.scss';
import { customUrlModifier } from '../../../utils/models';

const Model = ({ url }: { url: string }) => {
  const gltf = useLoader(GLTFLoader, url, (loader: any) => {
    loader.manager.setURLModifier(customUrlModifier);
  });

  return <primitive object={gltf.scene} position={[0, -0.3, 0]} />;
};

type ModelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  modelURL?: string;
  imageURL?: string;
};

export const ModelModal = ({ isOpen, onClose, modelURL, imageURL }: ModelModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.container }} maxWidth={false}>
      {modelURL ? (
        <Canvas camera={{ position: [0, 0, 1] }} style={{ backgroundColor: '#2A2A2A' }}>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Model url={modelURL} />
          <OrbitControls />
          <Environment preset="dawn" />
        </Canvas>
      ) : (
        <img src={imageURL} alt="exercise model" />
      )}
      <Close className={classes.closeIcon} onClick={onClose} />
    </Dialog>
  );
};
