import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import {
  RECALCULATE_TREATMENT_TEMPLATE_ERROR,
  RECALCULATE_TREATMENT_TEMPLATE_REQUEST,
  RECALCULATE_TREATMENT_TEMPLATE_SUCCESS
} from "../../actions";

function* workerRecalculateTemplate(action: {
  type: string;
  payload: {
    data: any;
  };
}): Generator<any> {
  try {
    const data: any = yield call(
      TreatmentPlanTemplatesAPI.recalculateTreatmentTemplate,
      action.payload.data
    );
    yield put({
      type: RECALCULATE_TREATMENT_TEMPLATE_SUCCESS,
      payload: {
        draft: data
      }
    });
  } catch (error: any) {
    yield put({
      type: RECALCULATE_TREATMENT_TEMPLATE_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherRecalculateTemplate() {
  yield takeLatest(RECALCULATE_TREATMENT_TEMPLATE_REQUEST, workerRecalculateTemplate);
}
