import { ISvgIcon } from "../../utils/interfaces";

export const DownChevron = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9167 1.75L6.66669 7L1.41669 1.75"
      stroke="#6B7280"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
