import { FormControl, TextField } from "@mui/material";
import { isIncorrectNum } from "../../../../../../utils/commonUtils";
import { INumericInput } from "../../../../../../utils/interfaces";

export const NumericInput = ({
  id,
  //   required,
  inputClassName,
  handleChange,
  helperClassName,
  formClassName,
  placeholder,
  value,
  maxLength,
}: INumericInput) => {
  const isError = isIncorrectNum(value);

  return (
    <FormControl className={formClassName}>
      <TextField
        id={id.toString()}
        InputProps={{
          classes: {
            root: inputClassName
          },
          autoComplete: "off",
          inputProps: {
            maxLength,
          }
        }}
        FormHelperTextProps={{
          classes: {
            root: helperClassName
          }
        }}
        //   name={id}
        onBlur={() => {}}
        onChange={event => handleChange(event)}
        placeholder={placeholder}
        value={value || ""}
        error={isError}
        helperText={isError && 'Only numeric characters allowed'}
      />
    </FormControl>
  );
};
