export const EmptyState = () => (
  <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="empty state" clipPath="url(#clip0_79_20830)">
      <path
        id="Vector"
        d="M70.1808 14.2825H9.33534C8.51446 14.283 7.72735 14.6093 7.1469 15.1897C6.56646 15.7702 6.24014 16.5573 6.23963 17.3782V73.2044C6.24014 74.0253 6.56646 74.8124 7.1469 75.3929C7.72735 75.9733 8.51446 76.2996 9.33534 76.3001H70.1808C71.0017 76.2997 71.7889 75.9734 72.3694 75.393C72.9499 74.8125 73.2763 74.0254 73.2768 73.2044V17.3782C73.2763 16.5572 72.9499 15.7701 72.3694 15.1896C71.7889 14.6092 71.0017 14.2829 70.1808 14.2825Z"
        fill="#CDCBCB"
      />
      <path
        id="Vector_2"
        d="M66.1753 56.9009H13.3415C11.6319 56.9009 10.2458 55.5148 10.2458 53.8052V4.47974C10.2458 2.77013 11.6319 1.38403 13.3415 1.38403H66.1753C67.8849 1.38403 69.271 2.77013 69.271 4.47974V53.8052C69.271 55.5148 67.8849 56.9009 66.1753 56.9009Z"
        fill="#FAFAFA"
      />
      <path
        id="Vector_3"
        d="M11.2259 54.7849V5.45985C11.2259 3.75025 12.612 2.36415 14.3216 2.36415H67.1554C67.7593 2.36415 68.3207 2.53965 68.7966 2.83854C68.2494 1.96584 67.2814 1.38403 66.1753 1.38403H13.3415C11.6319 1.38403 10.2458 2.77013 10.2458 4.47974V53.8051C10.2458 54.9115 10.8276 55.8792 11.7003 56.4268C11.4014 55.9505 11.2259 55.3891 11.2259 54.7853V54.7849Z"
        fill="url(#paint0_linear_79_20830)"
      />
      <path
        id="Vector_4"
        d="M59.6747 11.8057H19.8425V14.0757H59.6747V11.8057ZM59.6747 20.4733H19.8425V22.7433H59.6747V20.4733ZM59.6747 29.1428H19.8425V31.4128H59.6747V29.1428ZM59.6747 37.8104H19.8425V40.0807H59.6747V37.8104Z"
        fill="#CDCBCB"
      />
      <path
        id="Vector_5"
        d="M24.5892 30.4835L34.5985 40.4931H24.5892V30.4835Z"
        fill="url(#paint1_linear_79_20830)"
      />
      <path
        id="Vector_6"
        d="M73.277 41.3187V18.2886L69.2712 14.2825V41.3184H73.277V41.3187Z"
        fill="#CDCBCB"
      />
      <path
        id="Vector_7"
        d="M77.969 43.4019L73.6531 73.9536C73.4373 75.481 72.1302 76.6162 70.5878 76.6162H8.92876C7.38634 76.6162 6.0792 75.481 5.86343 73.9536L0.0310081 32.6708C-0.232082 30.8071 1.21411 29.142 3.09634 29.142H22.1379C23.6804 29.142 24.9875 30.2771 25.2033 31.8042L25.9676 37.2105C26.1834 38.7379 27.4905 39.873 29.0329 39.873H74.9043C76.7859 39.873 78.2324 41.5382 77.9693 43.4019H77.969Z"
        fill="url(#paint2_linear_79_20830)"
      />
      <path
        id="Vector_8"
        d="M62.6773 62.9888H16.8398C16.5886 62.9887 16.346 62.897 16.1575 62.7309C15.969 62.5648 15.8476 62.3356 15.8159 62.0864L15.2424 57.5458C15.2242 57.4005 15.2371 57.253 15.2802 57.1131C15.3233 56.9732 15.3957 56.8441 15.4925 56.7343C15.5893 56.6245 15.7084 56.5365 15.8418 56.4763C15.9752 56.416 16.1199 56.3848 16.2663 56.3847H63.2508C63.3972 56.3848 63.5419 56.416 63.6753 56.4763C63.8087 56.5365 63.9278 56.6245 64.0246 56.7343C64.1214 56.8441 64.1938 56.9732 64.2369 57.1131C64.28 57.253 64.2929 57.4005 64.2747 57.5458L63.7012 62.0864C63.6695 62.3356 63.5481 62.5648 63.3596 62.7309C63.1711 62.897 62.9285 62.9887 62.6773 62.9888Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_79_20830"
        x1="41.5737"
        y1="30.9543"
        x2="2.42344"
        y2="-8.19622"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_79_20830"
        x1="31.2023"
        y1="42.0991"
        x2="19.1036"
        y2="30.0008"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C2CECE" stopOpacity="0" />
        <stop offset="0.179" stopColor="#AFBCBC" stopOpacity="0.179" />
        <stop offset="1" stopColor="#5B6A6A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_79_20830"
        x1="39"
        y1="29.142"
        x2="39"
        y2="76.6162"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#EEF0F4" />
        <stop offset="0.927" stopColor="#E4E4E4" />
      </linearGradient>
      <clipPath id="clip0_79_20830">
        <rect width="78" height="78" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
