import { call, takeLatest, put } from 'redux-saga/effects';
import {
  GET_ALL_EXERCISES,
  GET_ALL_EXERCISES_SUCCESS,
  SET_TOAST,
} from '../actions';
import { ExercisesAPI } from '../../api/exercisesAPI';
import { Exercise } from '../../types/exercises';
import { ToastType } from '../../utils/interfaces';

function* getAllExercises(): Generator<any> {
  try {
    const data = yield call(ExercisesAPI.getAllExercises);

    if (Array.isArray(data) && data.length) {
      yield put({
        type: GET_ALL_EXERCISES_SUCCESS,
        payload: {
          data: data.filter((exercise: Exercise) => exercise.exerciseGroupDto.enabled),
        },
      });
    } else {
      yield put({
        type: SET_TOAST,
        payload: {
          open: true,
          message: 'Error with getting exercises data',
          type: ToastType.ERROR,
        },
      });
    }
  } catch (error: any) {
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: error.message,
        type: ToastType.ERROR,
      },
    });
  }
}

export function* watcherGetAllExercises() {
  yield takeLatest(GET_ALL_EXERCISES, getAllExercises);
}
