import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classes from './ViewPatient.module.scss';
import { FullPageContainer } from '../../common/FullPageContainer/FullPageContainer';
import { ViewPatientControls } from './components/ViewPatientControls/ViewPatientControls';
import {
  APPLY_TREATMENT_PLAN_REQUEST,
  DELETE_PATIENT_REQUEST,
  GET_ALARMS_SUCCESS,
  GET_PATIENT_REQUEST,
  SET_CURRENT_PATIENT,
} from '../../../redux/actions';
import { currentPatientSelector } from '../../../redux/selectors/patientsSelector';
import { ViewPatientInfo } from './components/ViewPatientInfo/ViewPatientInfo';
import { ClickingOverview } from './components/ClickingOverview/ClickingOverview';
import { ApplyTreatmentPlan } from '../../common/ApplyTreatmentPlan/ApplyTreatmentPlan';
import { format } from 'date-fns';
import { Modal } from '../../common/Modal/Modal';
import { Drawer, Tab, Tabs as MUITabs } from '@mui/material';
import { Alarms } from './components/Alarms/Alarms';
import { a11yProps, operationTypeToBone } from '../../../utils/commonUtils';
import { AssignExercises } from './components/AssignExercises/AssignExercises';
import { AssignExercisesModal } from './components/AssignExercisesModal/AssignExercisesModal';
import { ExerciseGroup } from '../../../types/exercises';
import { ExercisePerformanceChart } from './components/ExercisePerformanceChart/ExercisePerformanceChart';

enum Tabs {
  CLICKING_PROGRESS_OVERVIEW,
  EXERCISE_PERFORMANCE,
}

const tabs = [
  {
    id: Tabs.CLICKING_PROGRESS_OVERVIEW,
    label: 'Clicking Progress Overview',
  },
  {
    id: Tabs.EXERCISE_PERFORMANCE,
    label: 'Exercise Performance',
  },
];

export const ViewPatient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [applyTreatPlan, setApplyTreatPlan] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isOpenAlarms, setIsOpenAlarms] = useState(false);
  const [isAssignExercisesModalOpened, setAssignExercisesModalOpened] = useState(false);
  const [assignExercisesModalProps, setAssignExercisesModalProps] = useState<ExerciseGroup>();

  const [currentTab, setCurrentTab] = useState(Tabs.CLICKING_PROGRESS_OVERVIEW);

  const openAssignExerciseModal = (group: ExerciseGroup) => {
    setAssignExercisesModalOpened(true);
    setAssignExercisesModalProps(group);
  };
  const closeAssignExerciseModal = () => setAssignExercisesModalOpened(false);

  const toggleDrawer = () => setIsOpenAlarms((prev) => !prev);

  const id = location.pathname.split('/').pop() || '';

  const shouldShowAlarms = location.search.includes('open-alarms');

  const currentPatient = useSelector(currentPatientSelector(id));

  // clear current patient on unmount
  useEffect(
    () => () => {
      dispatch({
        type: SET_CURRENT_PATIENT,
        payload: { currentPatient: null },
      });
    },
    [],
  );

  useEffect(() => {
    dispatch({
      type: GET_PATIENT_REQUEST,
      payload: { id },
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (currentPatient) {
      const treatmentPlan = currentPatient.treatmentPlan;
      if (!treatmentPlan) {
        setApplyTreatPlan(true);
      } else {
        setApplyTreatPlan(false);
      }
    }
  }, [currentPatient, dispatch]);

  useEffect(() => {
    if (currentPatient && shouldShowAlarms) {
      onDrawerClose();
    }
    // eslint-disable-next-line
  }, [currentPatient, shouldShowAlarms]);

  // eslint-disable-next-line no-restricted-globals
  const goToPatients = () => navigate('/patients');

  const editPatient = () => navigate(`/patients/edit/${id}`);

  const applyTreatmentPlan = () => {
    const data = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      templateId: selectedPlan,
      patientId: id,
    };

    dispatch({
      type: APPLY_TREATMENT_PLAN_REQUEST,
      payload: { data },
    });
    setApplyTreatPlan(false);
  };

  const onCloseDelete = () => setIsDeleteOpen(false);

  const onClickDelete = () => setIsDeleteOpen(true);

  const onDeletePatient = () => {
    if (currentPatient) {
      setIsDeleteOpen(false);
      dispatch({
        type: DELETE_PATIENT_REQUEST,
        payload: { patientId: currentPatient.id, navigate },
      });
    }
  };

  const onDrawerClose = useCallback(() => {
    if (isOpenAlarms) {
      dispatch({
        type: GET_ALARMS_SUCCESS,
        payload: { alarms: [] },
      });
    }
    toggleDrawer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAlarms]);

  return (
    <FullPageContainer pageClassName={classes.viewPatientContainer}>
      <>
        <ViewPatientControls
          deletePatient={onClickDelete}
          editPatient={editPatient}
          externalLink={currentPatient?.externalId}
          navigateBack={goToPatients}
          patientStatus={currentPatient?.status}
          sendPassword={() => {}}
          showAlarms={onDrawerClose}
          patient={currentPatient}
          activeNotificationCount={currentPatient?.activeNotificationCount}
        />
        <ViewPatientInfo patient={currentPatient} treatmentData={currentPatient?.treatmentPlan} />

        <MUITabs
          classes={{
            root: classes.tabs,
            flexContainer: classes.flexContainer,
            indicator: classes.indicator,
          }}
          onChange={(_: React.SyntheticEvent, tab: Tabs) => setCurrentTab(tab)}
          value={currentTab}>
          {tabs.map(({ id, label }, index) => (
            <Tab
              classes={{ root: classes.tab }}
              key={id}
              label={label}
              {...a11yProps(index)}
              value={id}
            />
          ))}
        </MUITabs>

        {currentTab === Tabs.CLICKING_PROGRESS_OVERVIEW ? (
          <>
            {!applyTreatPlan && currentPatient?.treatmentPlan && (
              <ClickingOverview
                patientId={id}
                bone={operationTypeToBone(currentPatient.treatmentData.operationType)}
              />
            )}
            {applyTreatPlan && (
              <ApplyTreatmentPlan
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                startDate={startDate}
                setStartDate={setStartDate}
                operationType={currentPatient.operationType}
                nailTypeMm={currentPatient.nailTypeMm}
                initialGapMm={currentPatient.initialGapMm}
                applyTreatmentPlan={applyTreatmentPlan}
                isViewMode
              />
            )}
          </>
        ) : (
          <ExercisePerformanceChart />
        )}

        <AssignExercises onManageGroupPress={openAssignExerciseModal} />
        {isAssignExercisesModalOpened && (
          <AssignExercisesModal
            onClose={closeAssignExerciseModal}
            {...assignExercisesModalProps!}
          />
        )}
        <Modal
          open={isDeleteOpen}
          title={'Delete patient'}
          message={'Are you sure that you want to delete this patient?'}
          handleCancel={onCloseDelete}
          deleteConfirmation
          handleConfirm={onDeletePatient}
        />
        <Drawer
          anchor="right"
          open={isOpenAlarms}
          onClose={onDrawerClose}
          classes={{ paper: classes.alarmsDrawer }}>
          <Alarms onToggleDrawer={onDrawerClose} patientId={id} />
        </Drawer>
      </>
    </FullPageContainer>
  );
};
