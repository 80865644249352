import { ISvgIcon } from "../../utils/interfaces";

export const Add = ({ className }: ISvgIcon) => (
  <svg
    className={className}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.5 14V8H0.5V6H6.5V0H8.5V6H14.5V8H8.5V14H6.5Z" fill="white" />
  </svg>
);
