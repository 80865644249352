enum GenderEnumForUrl {
  female = 'female',
  male = 'male'
}

const genderMapForUrl: Record<GenderEnumForUrl, string> = {
  [GenderEnumForUrl.female]: 'female',
  [GenderEnumForUrl.male]: 'man',
};

export const INCORRECT_MODEL_REGEX = /Med_poses_[\w]+_[\d]+_ready/g;

export const getParamsFromModelUrl = (url: string): [string?, GenderEnumForUrl?] => {
  const parsedUrl = url.split('/');
  const exerciseId = parsedUrl[3];
  const gender = parsedUrl[4] as GenderEnumForUrl;

  return [exerciseId, gender];
}

/*
  This function exists because of our 3D models flow that we currently have. Basically, the way that
  3D models are getting populated on FE from BE is that we get the list of model URLs from BE and provide it
  to three.js library (useLoader method), which internally requests the .gltf file, which in itself contains
  a path to .bin file, which is 3D model that three.js ends up using

  For our new models the .gltf files contain incorrect path, which points to files that don't exist on our
  S3 bucket storage. One way to fix that would be to update model files, but we don't have that designer in our
  company anymore. Second way would be to rename .bin files on S3 storage side, but currently this might take
  quite a lot of time, so we postponed it (should be done in the future).

  So the decision that we see here should be temporary. This is a custom URL modifier for our custom
  loading manager that we provide to GLTFloader from three.js to intercept all outgoing requests from FE. Here we
  basically check all outgoing requests URLs and if they include "Med_poses" string, we replace the incorrect part
  with the appropriate path (gender and exercise id from the original url).
*/

export const customUrlModifier = (initialUrl: string) => {
  if (initialUrl.includes('Med_poses')) {
    const [exerciseId, gender] = getParamsFromModelUrl(initialUrl);
    
    if (!exerciseId || !gender) {
      return initialUrl;
    }

    return initialUrl.replace(INCORRECT_MODEL_REGEX, `${genderMapForUrl[gender]}_${exerciseId}`);
  }

  return initialUrl;
}