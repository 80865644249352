import { ACTIVE, FINISHED_STATUS, NOT_ACTIVATED, PLANNED, PREPARATION } from "../../../utils/constants";
import { IStatus } from "../../../utils/interfaces";
import classes from "./Status.module.scss";

export const Status = ({ status, className }: IStatus) => {
  return (
    <>
      {(status === NOT_ACTIVATED || status === PREPARATION) && (
        <span className={`${className} ${classes.preparation}`}>Preparation</span>
      )}
      {status === PLANNED && <span className={`${className} ${classes.planned}`}>Planned</span>}
      {status === ACTIVE && <span className={`${className} ${classes.active}`}>Active</span>}
      {status === FINISHED_STATUS && <span className={`${className} ${classes.finished}`}>Finished</span>}
    </>
  );
};
