import usePagination from "@mui/material/usePagination";
import classes from "./Pagination.module.scss";
import { IPagination } from "../../../utils/interfaces";

export const UsePagination = ({ count, currentPage, showNewPage }: IPagination) => {
  const showPage = (event: any, page: number) => {
    showNewPage(page);
  };

  const { items } = usePagination({
    count,
    page: currentPage,
    onChange: showPage
  });

  return (
    <nav className={classes.paginationContainer}>
      <ul className={classes.paginationList}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                className={`${classes.pageButton} ${
                  selected ? classes.selected : ""
                }`}
                {...item}
                type="button"
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                className={`${classes.nextPrevious} ${
                  type === "next" ? classes.next : classes.previous
                }`}
                {...item}
                type="button"
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};
