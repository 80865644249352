import { call, put, takeLatest } from "redux-saga/effects";
import { TreatmentPlanTemplatesAPI } from "../../../api/treatmentPlansAPI";
import { ToastType } from "../../../utils/interfaces";
import {
  DELETE_TEMPLATE_ERROR,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  GET_TREATMENT_TEMPLATES_REQUEST,
  SET_TOAST
} from "../../actions";

function* workerDeleteTemplate(action: {
  type: string;
  payload: {
    id: string;
    navigate: Function;
    shouldLoadList: boolean;
    searchString: string;
  };
}): Generator<any> {
  try {
    const { id, navigate, shouldLoadList, searchString } = action.payload;
    yield call(TreatmentPlanTemplatesAPI.deleteTreatmentTemplate, id);
    yield put({
      type: DELETE_TEMPLATE_SUCCESS
    });
    if (navigate) {
      navigate("/treatment-plans");
    }
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: "Treatment Plan Template was successfully deleted",
        type: ToastType.SUCCESS,
      }
    });
    if (shouldLoadList) {
      yield put({
        type: GET_TREATMENT_TEMPLATES_REQUEST,
        payload: { searchString }
      });
    }
  } catch (error: any) {
    yield put({
      type: DELETE_TEMPLATE_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherDeleteTemplate() {
  yield takeLatest(DELETE_TEMPLATE_REQUEST, workerDeleteTemplate);
}
